/* Responsive Menu Here
----------------------------------------*/
ul {
  margin: 0px;
  padding: 0px;
}
.react-menus {
  list-style: none;
  margin: 0;
  padding: 0;
}
.react-menus li {
  list-style: none;
}
.react-menus li > a {
  color: #c0c0c0;
}
.react-menus > li > a {
  display: block;
  position: relative;
  margin: 0;
  padding: 32px 34px 32px 34px;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
}
body.react-one-page .react-menus > li > a {
  padding: 26px 16px 26px 16px;
}

.react-menus li:hover > a,
.react-menus li.menu-active > a {
  color: #0d1e50;
}

.react-menus li ul.sub-menu li:hover > a,
.react-menus li ul.sub-menu li.menu-active > a {
  color: #0d1e50;
}

.react-menus li div.menu-active {
  position: relative;
}

.react-menus > li > ul.sub-menu {
  list-style: none;
  clear: both;
  margin: 0;
  position: absolute;
  z-index: 999;
  border-radius: 0 0 4px 4px;
}
.react-menus li ul.sub-menu {
  background: #fff;
  padding: 1px 0 10px 5px;
  box-shadow: 30px 0 30px rgba(8, 10, 41, 0.1);
  text-align: left;
}
.sub-menus li :hover {
  text-decoration: underline;
}
.react-menus li ul.sub-menu > li {
  width: 13rem;
}
.react-menus li {
  position: relative;
}
.react-menus li ul.sub-menu li a {
  display: block;
  margin: 0px 0px;
  padding: 5px 35px 5px 35px;
  text-decoration: none;
  font-weight: normal;
  background: none;
  color: #0a0a0a;
}

.react-menus > li > ul.sub-menu > li ul.sub-menu > li ul.sub-menu {
  position: absolute;
  left: 100%;
  top: 0px;
  display: none;
  list-style: none;
}

.react-menus > li.edge > ul.sub-menu {
  right: 0;
}

.react-menus li ul.sub-menu li.edge ul.sub-menu,
.react-menus > li > ul.sub-menu > li.edge ul.sub-menu > li.edge ul.sub-menu {
  left: -100% !important;
}

/* Menu Toggle Btn
----------------------------------------*/

.menu-toggle .icon-bar {
  display: block !important;
  width: 25px;
  height: 2px;
  background-color: #fff !important;
  margin: 6px 0;
  transition: all 500ms ease;
}

.menu-toggle .icon-bar:nth-child(1) {
  width: 16px;
  margin-left: auto;
}

.menu-toggle .icon-bar:nth-child(2) {
  width: 20px;
  margin-left: auto;
}

.menu-toggle .icon-bar:hover {
  background-color: #fff !important;
}
.menu-toggle:hover span.icon-bar {
  width: 25px !important;
}
.menu-toggle #menu-btn {
  float: right;
  background: #202020;
  border: 1px solid #0c0c0c;
  padding: 8px;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px;
  clear: right;
  display: none;
}
.hide-menu {
  display: none;
}

/* Responsive Menu Styles
----------------------------------------*/
/*Note: change the max-width asper your requirment and change the same in aceResponsiveMenu({resizeWidth: "991" }) function*/

@media screen and (min-width: 992px) {
  .react-main-menu nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media screen and (max-width: 991px) {
  .react-menus li ul.sub-menu li a {
    padding: 12px 40px 12px 50px;
  }
  .menu-toggle #menu-btn {
    display: block;
  }
  .react-menus .has-sub .arrow {
    width: 55px;
    height: 54px;
    display: block;
    background: #fff;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 99;
    cursor: pointer;
    border-left: 1px solid #f5f3f3;
  }

  .react-menus .has-sub .hash .arrow:after {
    content: "";
    position: absolute;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 10px solid #0d1e50;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .react-menus .has-sub .hash.menu-active .arrow:after {
    content: "";
    position: absolute;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 10px solid #0d1e50;
    border-top: 0px solid #0d1e50;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  ul[data-menu-style="vertical"],
  ul[data-menu-style="accordion"],
  ul[data-menu-style="vertical"] li ul.sub-menu {
    width: 100% !important;
  }
  .react-menus {
    position: absolute;
    width: 100%;
    box-shadow: none;
    margin-bottom: 12px;
  }
  .react-menus > li {
    border-bottom: 1px solid #f5f3f3;

    float: none;
  }
  .react-menus > li ul.sub-menu > li {
    border-top: 1px solid #f5f3f3;
    float: none;
  }

  .react-menus > li ul.sub-menu > li:last-child {
    border-bottom: none;
  }

  .react-menus li {
    position: relative;
    background: #fff;
  }
  .react-menus li a:hover {
    background: #f5f3f3 !important;
    color: #0d1e50 !important;
  }
  .react-menus li > .react-current-page {
    color: #3270fc !important;
  }
  .react-menus > li:first-child {
    border-top: none;
  }

  .react-menus li ul.sub-menu > li {
    width: 100%;
  }
  .react-menus li ul.sub-menu li ul.sub-menu li a {
    padding-left: 60px;
  }
  .react-menus li ul.sub-menu li ul.sub-menu li ul.sub-menu li a {
    padding-left: 80px;
  }
  .react-menus > li > ul.sub-menu {
    position: static;
  }
  .react-menus > li > ul.sub-menu > li ul.sub-menu {
    position: static;
  }
  .react-menus > li > ul.sub-menu > li ul.sub-menu > li ul.sub-menu {
    position: static;
  }
}
.react-sticky .topbar-area.style1 {
  display: none;
}

.desktop-div {
  background-image: url("../images/breadcrumbs/1.jpg");
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  margin-bottom: 2rem;
  height: 6rem;
}
.desktop-div h1 {
  color: white;
  font-weight: bold;
}


.modal-div {
  .media {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1rem;

    .modal-img {
      margin-right: 1rem;
      width: 150px;
    }

    .modal-body {
      flex: 1;

      h5 {
        margin-top: 0;
      }

      p {
        margin: 0.5rem 0;
        width: 10rem;
      }
      .modal-title {
        margin-bottom: 0rem;
      }
      .modal-para {
        width: 100%;
      }

      .event-info {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          display: flex;
          align-items: center;
          margin-right: 2rem;

          svg {
            margin-right: 0.5rem;
          }
        }
      }
      .form-control {
        width: 70%;
      }
      .seats-input {
        width: 35%;
      }
      .seat-info {
        display: flex;
        flex-direction: row;
      }
    }
  }
}

.rbc-event {
  background-color: #f0f0f0;
  color: #333;
  border: none;
  padding: 5px;
  border-radius: 5px;
  font-size: 0.875rem;
}

.rbc-event {
  position: relative;
  top: -24px;
  width: 6rem;
  height: 14px;
  font-size: 7px;
  text-align: center;
}

.rbc-month-view {
  .rbc-day-bg {
    &:hover {
      background-color: #f9f9f9;
    }
  }

  .rbc-date-cell {
    font-size: 0.75rem;
    padding: 2px;
  }

  .rbc-header {
    font-size: 0.875rem;
    padding: 5px;
  }

  .rbc-today {
    background-color: #e6f7ff;
  }
}
.courses-dropdown {
  display: flex;
  justify-content: center;
}

.modal-body-line p {
  line-height: 20px;
  margin: 0;
}
.modal-body-line h5 {
  line-height: 20px;
  margin: 0;
}

// footer css

#react-footer {
  background: #000;
  color: #fff;
  padding: 2rem 0;

  .footer-top {
    border-bottom: 1px solid #333;
    padding-bottom: 2rem;
  }

  .footer-logo img {
    // max-width: 150rem;
    // width: 18rem;
    margin-left: -2rem;
    margin-top: -2rem;
    width:"18rem";
    height:"10rem";
  }

  .footer-description {
    margin: 1rem 0;
    color: #ccc;
    padding-bottom: 1rem;
  }

  .footer-address {
    list-style: none;
    padding: 0;

    li {
      margin-bottom: 0.5rem;
      display: flex;
      align-items: center ;

      svg {
        margin-right: 0.5rem;
        color: #fff;
      }

      a {
        color: #fff;
        text-decoration: none;

        &:hover {
          text-decoration: underline;

        }
      }
    }
  }

  .footer-menu {
    list-style: none;
    padding: 0;

    .footer-menu li {
      margin-bottom: 0.5rem;
      a {
        color: #fff; 
        text-decoration: none;
        font-weight: 500;

        &:hover {
          text-decoration: underline;
          color: #ddd;
        }
      }
    }
  }

  .social-links {
    list-style: none;
    padding: 0;
    display: flex;
    align-items: center;
    margin-top: 1rem;

    .follow {
      margin-right: 1rem;
      color: #fff; // White color 
    }

    li {
      margin-right: 0.5rem;
      a {
        display: inline-block;
        width: 24px;
        height: 24px;
        background: #333; // Dark grey background for icons
        border-radius: 50%;
        color: #fff;
        text-align: center;
        line-height: 24px;
        font-size: 14px;
        text-decoration: none;

        &:hover {
          background: #555; // Slightly lighter grey on hover
        }
      }
    }
  }

  .footer-bottom {
    padding: 1rem 0;
    background: #111;

    .footer-bottom-left {
      p {
        margin: 0;
        color: #fff;
      }
    }
  }
  
}


.enroll-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.enroll {
  margin-bottom: 15px;
}

.enroll label {
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  color: #333;
}

.enroll input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.enroll input:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 0 2px rgba(38, 143, 255, 0.2);
}
.enroll-btn{
  width: 15rem;
  margin: 1rem;
}



// .rbc-event {
//   padding: 10px;
//   width: 100%;
//   background-color: #3270fc;
//   color: #fff !important;
//   // text-align: start !important;
//   // margin: 0px 10px;
//   font-weight: bold;
//   font-size: 12px;

// }
.rbc-event-content{
  margin-top: -10px;
}
// .rbc-off-range-bg {
//   background-color: #000;
// }

.rbc-off-range-bg:hover {
  background-color: #e6e6e6 !important;

}

.rbc-row-content {
  min-height: 110px !important;
}

.rbc-month-row {
  overflow: visible !important;
}


/* Assuming your container div has a class named 'calendar-container' */
// .calendar-container {
//   min-height: 100vh;
// }

.description-class {
  line-height: 20px;
  max-height: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  // white-space: nowrap;
}

.ell-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }


  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #aaa;
  }
  
  ::-webkit-scrollbar-track {
    background: #f0f0f0;
  }