@charset "utf-8";
/**
*
* -----------------------------------------------------------------------------
*
* Template : Echooling - Online Education React Template
* Author : reacthemes
* Author URI : https://reactheme.com/
*
* ------------------------------------------------
*
**/

/* Table Of Content
---------------------------------------------------------
01. General CSS Here 
02. Common Class CSS Here 
03. Header Section CSS Here
04. Banner Section CSS Here
05. Course Filter CSS Here
06. Slider Section CSS Here
07. Banner Two Section CSS Here
08. Service CSS Here
09. Popular Topics CSS Here
10. About Section CSS Here
11. Breadcrumbs Section CSS
12. Featured Online Section CSS Here 
13. FAQ Section CSS Here
14. Instructor Section CSS Here
15. Profile Section CSS Here 
16. Clients Section CSS Here
17. Blog Section CSS Here  
18. Campus Section CSS Here
19. Course Section CSS Here
20. Counter CSS Here
21. Upcoming Event Section CSS Here
22. Blog Page CSS Here
23. Blog Details Page CSS
24. Contact Page CSS Here 
25. Courses Section CSS Here  
26. Sidebar Section CSS Here
27. Student Satisfaction Section CSS Here  
28. High Quality CSS Here  
29. Footer CSS Here
30. 404 CSS Here 
31. Preloader CSS Here
32. ScrollUp CSS Here
33. Login CSS Here 


--------------------------------------------------------*/

@import url('http://fonts.googleapis.com/css?family=Source+Sans+Pro:ital,wght$0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');
$titlefont: 'Source Sans Pro', sans-serif;
$bodyfont: 'Source Sans Pro', sans-serif;
$blog-font: 'Source Sans Pro', sans-serif;
$transition: all 0.5s ease 0s;
$bodyColor: #53545b;
$titleColor: #00306E;
$primaryColor: #3270FC;
$secondaryColor: #00306E;
$titleColor6: #00306e;
$hoverColor: #f84e77;
$whiteColor: #ffffff;
$black-color: #000;
$heading-color: #252525;
$kidscolor: #fd4680;
$paragraphColor: #f5f5f5;
$homesix-textColor: #4d5765;
$homesix-primaryColor: #d20936;
$homeseven-primaryColor: #316ffb;


/*****************************************************
    01. General CSS Here 
****************************/


html,
body {
    font-size: 16px;
    color: #252525;
    font-family: $bodyfont;
    vertical-align: baseline;
    font-weight: 400;
    overflow-x: hidden;
    line-height: 1.7;
}

img {
    max-width: 100%;
    height: auto;
}

p {
    margin: 0 0 26px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $titlefont;
    color: $titleColor;
    margin: 0 0 26px;
    font-weight: 500;
    line-height: 1.2;
}

h1 {
    font-size: 60px;
}

h2 {
    font-size: 42px;
}

h3 {
    font-size: 30px;
}

h4 {
    font-size: 24px;
}

h5 {
    font-size: 18px;
}

h6 {
    font-size: 14px;
}

a {
    color: $primaryColor;
    transition: $transition;
    text-decoration: none !important;
    outline: none !important;
}

a:active,
a:hover {
    text-decoration: none;
    outline: 0 none;
    color: $primaryColor;
}

ul {
    list-style: outside none none;
    margin: 0;
    padding: 0;
}

.pinkColor {
    color: #ee3688;
}

.section-bg {
    background: #f5f5f5;
}

/*****************************************************
    02. Common Class CSS Here 
****************************/
.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.react-absolute {
    position: absolute;
}

.primary-color {
    color: $primaryColor  !important;
}

.secondary-color {
    color: $secondaryColor  !important;
}

.white-color {
    color: $whiteColor  !important;
}

.normal {
    font-weight: 400;
}

.z-index-1 {
    position: relative;
    z-index: 1;
}

.no-padding {
    padding: 0;
}

.mb---30 {
    margin-bottom: 30px;
}

.mb---50 {
    margin-bottom: 50px;
}

.mb---55 {
    margin-bottom: 55px;
}

.mb---70 {
    margin-bottom: 70px;
}

.mb---80 {
    margin-bottom: 80px;
}

.mb---90 {
    margin-bottom: 90px;
}

.react__title__section {
    padding-bottom: 30px;

    h2 {
        font-size: 50px;
        font-weight: 700;
        color: $titleColor;
        margin: 0px;
        line-height: 54px;
    }

    a {
        background: #3270FC;
        border-radius: 6px;
        padding: 10px 26px;
        line-height: 20px;
        display: inline-block;
        color: #fff;
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 12px;

        &:hover {
            opacity: .9;
        }
    }

    &.react__title__section2 {
        h2 {
            font-size: 44px;
            color: #0F1216;
        }
    }
}

.align-v {
    align-items: end;
}

.w-btn {
    cursor: pointer;
    font-size: 15px;
    color: #ffffff;
    background: #2a6df5;
    border: 2px solid #ebebf1;
    text-align: center;
    padding: 0 30px;
    display: inline-block;
    font-weight: 600;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.w-btn:hover {
    background-color: #ffffff;
    color: $titleColor;
}

.w-btn-2 {
    font-size: 15px;
    padding: 12px 37px;
    border: 2px solid #2a6df5;
}

.w-btn-2:hover {
    border: 2px solid rgba(15, 22, 41, 0.078);
    background-color: #ffffff;
    color: $titleColor;
}

.w-btn-3 {
    padding: 7px 28px;
    -webkit-border-radius: 22px;
    -moz-border-radius: 22px;
    border-radius: 22px;
    color: #ffffff;
    background: #2a6df5;
    border: 2px solid #2a6df5;
}

.w-btn-3:hover {
    border: 2px solid rgba(15, 22, 41, 0.078);
    background-color: #ffffff;
    color: $titleColor;
}

.w-btn-4 {
    padding: 8px 41px;
    -webkit-border-radius: 22px;
    -moz-border-radius: 22px;
    border-radius: 22px;
    background: #ffffff;
    color: $titleColor;
    border: 2px solid rgba(15, 22, 41, 0.078);
}

.w-btn-4:hover {
    border-color: #2a6df5;
    color: #ffffff;
    background: #2a6df5;
}

.w-btn-5 {
    padding: 8px 31px;
    -webkit-border-radius: 22px;
    -moz-border-radius: 22px;
    border-radius: 22px;
    background: #ffffff;
    color: $titleColor;
    border: 2px solid rgba(15, 22, 41, 0.078);
}

.w-btn-5:hover {
    border-color: #2a6df5;
    color: #ffffff;
    background: #2a6df5;
}

.w-btn-8 {
    color: $titleColor;
    background: #ffffff;
    padding: 3px 31px;
}

.w-btn-8:hover {
    border-color: #2a6df5;
    color: #ffffff;
    background: #2a6df5;
}

.w-btn-9 {
    padding: 7px 40px;
    -webkit-border-radius: 22px;
    -moz-border-radius: 22px;
    border-radius: 22px;
    color: #ffffff;
    background: #2a6df5;
    border: 2px solid #2a6df5;
}

.w-btn-9:hover {
    border: 2px solid rgba(15, 22, 41, 0.078);
    background-color: #ffffff;
    color: $titleColor;
}

.w-btn-11 {
    height: 40px;
    line-height: 36px;
    padding: 0 17px;
    background: #2a6df5;
    color: #ffffff;
    border-radius: 6px;
    border: 2px solid #2a6df5;
}

.w-btn-11:hover {
    border: 2px solid #ebebf1;
    background-color: transparent;
    color: $titleColor;
}

.w-btn-12 {
    height: 40px;
    line-height: 36px;
    padding: 0 17px;
    background: #fd4680;
    color: #ffffff;
    border-radius: 0px;
    border: 2px solid #fd4680;
}

.w-btn-12:hover {
    border: 2px solid #4917bf;
    background-color: transparent;
    color: $titleColor;
}

.w-btn-16 {
    padding: 9px 31px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    background-color: #fd4680;
    color: #ffffff !important;
    border: 2px solid #fd4680;
}

.w-btn-16:hover {
    border: 2px solid #fd4680;
    color: $titleColor  !important;
    background-color: transparent;
}

.w-btn-blue {
    padding: 10px 20px;
    border: 2px solid rgba(255, 255, 255, 0.2);
    background: #ffffff;
    color: $titleColor;
}

.w-btn-blue-header {
    height: 40px;
    padding: 0 20px;
    line-height: 40px;
    background: #ffffff;
    color: $titleColor;
    border: rgba(15, 22, 41, 0.078);
}

.w-btn-blue:hover {
    background-color: #2a6df5;
}

.w-btn-blue-2 {
    padding: 10px 20px;
    background: #ffffff;
    color: $titleColor;
    border-color: rgba(15, 22, 41, 0.078);
}

.w-btn-blue-2:hover {
    border-color: rgba(15, 22, 41, 0.078);
    color: #ffffff;
}

.react-btn {
    background: $primaryColor;
    color: $whiteColor;
    font-size: 15px;
    font-weight: 600;
    border-radius: 30px;
    display: inline-block;
    padding: 10px 38px;
    border: 2px solid $primaryColor;
    transition: $transition;

    &:hover {
        background: none;
        color: $primaryColor;
    }
}

.react-btn-border {
    background: $whiteColor;
    border: 2px solid rgba(255, 255, 255, 0.3);
    font-size: 16px;
    font-weight: 600;
    border-radius: 6px;
    display: inline-block;
    padding: 15px 35px 15px 35px;
    transition: $transition;
    color: $titleColor;
    line-height: 20.11px;

    &:hover {
        background: $primaryColor;
        color: $whiteColor;
        border-color: $primaryColor;
    }
}

.slick-slider {
    .single-slide {
        position: relative;
    }
    &:hover {
        .slick-arrow {
            opacity: 1;
        }
    }
}

.slick-arrow {
    overflow: hidden;
    cursor: pointer;
    outline: none !important;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 0;
    width: 70px;
    height: 70px;
    border: 0;
    border-radius: 50%;
    background-color: transparent;
    transition: $transition;
    opacity: 0;
    z-index: 1;
    &:before {
        font-size: 45px;
        color: rgba(255,255,255,.48);
    }
    &:hover {
        background-color: $whiteColor;
        &:before {
            color: $primaryColor;
        }
    }
}

.slick-arrow:hover {
    color: $primaryColor;
    background-color: $whiteColor;
}

.slick-arrow:before {
    font-size: 45px;
    font-family: 'ElegantIcons';
    content: '\35';
    line-height: 40px;
    display: inline-block;
}

.slick-prev {
    left: 15px;
}

.slick-prev:before {
    transition: 0.3s;
    content: '\34';
}

.slick-next {
    right: 15px;
}

.slick-next:before {
    content: '\35';
}

.slick-prev:hover:before {
    animation: toLeftFromRight 0.4s linear forwards;
}

.slick-next:hover:before {
    animation: toRightFromLeft 0.4s linear forwards;
}

.slick-dots {
    text-align: center;
}

.slick-dots > li {
    display: inline-block;
    margin: 5px;
}

.slick-dots > li button {
    cursor: pointer;
    outline: none !important;
    font-size: 0;
    width: 14px;
    height: 14px;
    border: 1px solid $primaryColor;
    border-radius: 50%;
    background: transparent;
    transition: $transition;
}
.slick-dots > li button::before {
    display: none;
}

.slick-dots > .slick-active button {
    background-color: $primaryColor;
}




/*****************************************************
    03. Header Section CSS Here  
****************************/

.react-header {
    background: $whiteColor;
    position: relative;
    z-index: 9999;
  
    

    .react-inner-menus {
        display: flex;
    }

    .react-menus>li>a {
        font-family: $bodyfont;
        font-size: larger ;
    }

    .react-menus li {
        a {
            color: #00306e;
            
           
          

            &:hover {
                color: #D2093C !important;
                text-decoration: underline !important;
            }
           
        }
        
     
.menu-active {
    color: #D2093C !important;
    text-decoration: underline !important;
    
  }
  
  

    }

   
    .react-main-menu {
        position: relative;
    }

    .container {
        max-width: 1340px;
    }

    .logo {
        padding: 14px 0 14px 0;
    }

    &.react-header-two {
        box-shadow: 0px 4px 10px rgba(0, 14, 32, 0.06);

        .searchbar-part {
            .phone-part {
                border-left: 1px solid #DFE2E5;
                padding-left: 25px;

                a {
                    font-size: 18px;
                    line-height: 24px;
                    font-weight: 600;
                    color: #0F1216;

                    svg {
                        color: #3270FC;
                    }

                    &:hover {
                        color: #3270FC;
                    }
                }
            }
        }

        .react-logins {
            border: none;
            padding-left: 20px;

            a {
                background: #3270FC;
                border-radius: 6px;
                padding: 10px 26px;
                line-height: 20px;
                display: inline-block;
                color: #fff;
                font-weight: 600;
                font-size: 16px;

                svg {
                    width: 18px;
                }

                &:hover {
                    opacity: .9;
                }
            }
        }
    }

    &.react-header-three {
        .cate-part {
            display: flex;
            align-items: center;

            svg {
                margin-right: -16px;
                margin-top: 4px;
            }

            .react-category-menu {
                margin-left: 25px;

                li {
                    position: relative;
                    cursor: pointer;
                    font-size: 15px;
                    font-weight: 600;

                    a {
                        color: #091524;

                        &:hover {
                            color: #3270FC;
                        }

                        i {
                            font-size: 22px;
                            position: relative;
                            top: 4px;
                        }
                    }

                    ul {
                        position: absolute;
                        left: -20px;
                        top: 100%;
                        width: 172px;
                        transform: scaleY(0);
                        transition: all 0.4s ease-in-out 0s;
                        transform-origin: 0 0 0;
                        opacity: 0;
                        visibility: hidden;
                        padding: 20px 20px 23px 20px;
                        background: #fff;
                        border-radius: 0 0 4px 4px;

                        li {
                            padding: 3px 0;
                        }
                    }

                    &:hover {
                        ul {
                            transform: scaleY(1);
                            opacity: 1;
                            visibility: visible;

                            li {
                                font-size: 15px;
                            }
                        }
                    }
                }
            }

            &:hover {
                color: #3270FC;

                .react-category-menu {
                    li {
                        color: #3270FC;

                        ul {
                            transform: scaleY(1);
                            opacity: 1;
                            visibility: visible;

                            li {
                                font-size: 15px;
                            }
                        }
                    }
                }
            }
        }
    }

    &.react-header-four {
        width: 100%;
        background: transparent;
        position: absolute;
        top: 0;
        left: 0;

        .react-sticky {
            background: #000;
        }

        .react-inner-menus {
            li {
                background: transparent !important;
            }

            ul {
                li {
                    a {
                        color: white;

                        &.react-current-page {
                            color: $primaryColor  !important;
                        }
                    }
                }
            }
        }

        .react-login {
            svg {
                stroke: white;
            }
        }

        .search-form {
            background: none !important;
            width: inherit;
            height: inherit;
            padding: inherit !important;

            .search-btn {
                svg {
                    stroke: white;
                    width: 18px;
                    aspect-ratio: 1;
                    transform: translateY(-4px);
                    cursor: pointer;
                }
            }

            .search-input {
                position: absolute;
                right: 0;
                top: 70px;
                background: rgb(67, 72, 82) !important;
                color: white !important;
                margin-left: 10px;
                width: 250px;
                height: 0;
                padding: 0 10px;
                transition: 500ms;
            }

            .search-input.show {
                padding: 5px 10px;
                height: 40px;
            }
        }

        .react-menus {
            li {
                a:hover,
                &.menu-active > a {
                    color: $primaryColor  !important;
                }

                ul {
                    background: #000;

                    li {
                        a {
                            &:hover {
                                color: $primaryColor  !important;
                            }
                        }
                    }
                }
            }
        }

    }

    &.react-header-five {
        .react-sticky {
            .menu-part {
                background: $whiteColor;
                box-shadow: 10px 0 20px rgba(8, 0, 41, .08);
            }

            .search-form {
                background: #f3f4fa !important;
            }
        }

        .cate-part {
            display: flex;
            align-items: center;

            svg {
                margin-right: -16px;
                margin-top: 4px;
            }

            .react-category-menu {
                margin-left: 25px;

                li {
                    position: relative;
                    cursor: pointer;
                    font-size: 15px;
                    font-weight: 600;

                    a {
                        color: #091524;

                        &:hover {
                            color: #3270FC;
                        }

                        i {
                            font-size: 22px;
                            position: relative;
                            top: 4px;
                        }
                    }

                    ul {
                        position: absolute;
                        left: -20px;
                        top: 100%;
                        width: 172px;
                        transform: scaleY(0);
                        transition: all 0.4s ease-in-out 0s;
                        transform-origin: 0 0 0;
                        opacity: 0;
                        visibility: hidden;
                        padding: 20px 20px 23px 20px;
                        background: #fff;
                        border-radius: 0 0 4px 4px;

                        li {
                            padding: 3px 0;
                        }
                    }

                    &:hover {
                        ul {
                            transform: scaleY(1);
                            opacity: 1;
                            visibility: visible;

                            li {
                                font-size: 15px;
                            }
                        }
                    }
                }
            }

            &:hover {
                color: #3270FC;

                .react-category-menu {
                    li {
                        color: #3270FC;

                        ul {
                            transform: scaleY(1);
                            opacity: 1;
                            visibility: visible;

                            li {
                                font-size: 15px;
                            }
                        }
                    }
                }
            }
        }
    }


    .react-menus.home-four {
        li {
            background: #000;
        }
    }

    .react-header-five {
        .cate-part {
            display: flex;
            align-items: center;

            .react-sticky {
                background: rgb(255, 255, 255) !important;
            }

            svg {
                margin-right: -16px;
                margin-top: 4px;
            }

            .react-category-menu {
                margin-left: 25px;

                li {
                    position: relative;
                    cursor: pointer;
                    font-size: 15px;
                    font-weight: 600;

                    a {
                        color: #091524;

                        &:hover {
                            color: #3270FC;
                        }

                        i {
                            font-size: 22px;
                            position: relative;
                            top: 4px;
                        }
                    }

                    ul {
                        position: absolute;
                        left: -20px;
                        top: 100%;
                        width: 172px;
                        transform: scaleY(0);
                        transition: all 0.4s ease-in-out 0s;
                        transform-origin: 0 0 0;
                        opacity: 0;
                        visibility: hidden;
                        padding: 20px 20px 23px 20px;
                        background: #fff;
                        border-radius: 0 0 4px 4px;

                        li {
                            padding: 3px 0;
                        }
                    }

                    &:hover {
                        ul {
                            transform: scaleY(1);
                            opacity: 1;
                            visibility: visible;

                            li {
                                font-size: 15px;
                            }
                        }
                    }
                }
            }

            &:hover {
                color: #3270FC;

                .react-category-menu {
                    li {
                        color: #3270FC;

                        ul {
                            transform: scaleY(1);
                            opacity: 1;
                            visibility: visible;

                            li {
                                font-size: 15px;
                            }
                        }
                    }
                }
            }
        }
    }


    .react-sticky {
        background: $whiteColor;
        position: fixed !important;
        width: 100%;
        -webkit-animation: stickyanimations 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation: stickyanimations 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        box-shadow: 10px 0 20px rgba(8, 0, 41, .08);
        top: 0;
        left: 0;
        right: 0;
        z-index: 999;

        .toolbar-area {
            display: none;
        }
    }

    .menu-title {
        font-size: 18px;
        font-weight: 700;
        color: $titleColor;
    }

    .searchbar-part {
        display: flex;
        align-items: center;

        .search-form {
            background: #F4F5F5;
            border-radius: 6px;
            padding: 7px 18px 9px 25px;

            [type="text"] {
                border: none;
                color: #4b5b70;
                font-size: 14px;
                outline: none;
                background: none;
            }

            button {
                border: none;
                background: none;
                padding: 0;
            }
        }

        .react-login {
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            border-left: 1px solid #DFE2E5;
            padding: 0 30px;

            a {
                color: $titleColor;

                &:hover {
                    color: $primaryColor;
                }
            }

            svg {
                color: $titleColor;
                margin-right: 3px;
                position: relative;
                top: -3px;
                width: 19px;
            }
        }
    }

    .topbar-area.style1 {
        background: #00306e;
        color: #fff;
        padding: 5px 0;
        font-size: 14px;

        .topbar-contact {
            ul {
                display: flex;

                li {
                    svg {
                        width: 18px;
                        padding-right: 2px;
                    }

                    margin-left: 30px;

                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
        }

        li {
            a {
                color: #fff;

                &:hover {
                    color: #b5b2b2;
                }
            }
        }

        ul.social-links {
            li {
                margin-right: 15px;

                a {
                    width: unset;
                    height: unset;
                    line-height: unset;
                    border: 0;

                    &:hover {
                        background: transparent;
                        color: #b5b2b2;
                    }
                }
            }
        }
    }
}

.react-sub-shadow li ul.sub-menu {
    box-shadow: 0px 30px 30px 0px rgba(10, 0, 45, 0.1);
}

.topbar-area.style7 {
    background: $homeseven-primaryColor;
    color: #fff;
    padding: 5px 0;
    font-size: 14px;

    .topbar-contact {
        ul {
            display: flex;
            justify-content: flex-end;

            li {
                svg {
                    width: 17px;
                    margin-right: 10px;
                }

                margin-left: 50px;

                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }

    li {
        a {
            color: #fff;

            &:hover {
                color: #b5b2b2;
            }
        }
    }

    ul.social-links {
        li {
            margin-right: 25px;

            a {
                width: unset;
                height: unset;
                line-height: unset;
                border: 0;

                &:hover {
                    background: transparent;
                    color: #b5b2b2;
                }
            }
        }
    }
}

@-webkit-keyframes stickyanimations {
    0% {
        -webkit-transform: translateY(-100px);
        transform: translateY(-100px);
    }

    100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
}

@keyframes stickyanimations {
    0% {
        -webkit-transform: translateY(-100px);
        transform: translateY(-100px);
    }

    100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
}

.event__video-btn--play {
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.event__video-btn--play-btn {
    display: inline-block;
    width: 90px;
    height: 90px;
    line-height: 86px;
    text-align: center;
    font-size: 25px;
    color: #ffffff;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background: #ffffff;
    color: #2a6df5;
    border-color: #ffffff;
}

.event__video-btn {
    position: relative;
}

.event__video-btn--play-btn::before {
    content: "";
    height: 120px;
    width: 120px;
    position: absolute;
    left: -15px;
    top: -15px;
    border: 1px solid rgba(255, 255, 255, 0.302);
    border-radius: 50%;
    animation: videoBtnAnim 3s linear infinite;
    display: block;
}

.event__video-btn--play-btn::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #fff;
    top: 0;
    left: 0;
    border-radius: 50%;
    transition: all 0.3s ease-in-out;
}

.event__video-btn--play-btn i {
    z-index: 2;
    position: relative;
    top: 9px;
    font-size: 35px;
}


.react__shape__abd {
    position: absolute;
    right: 51.5%;
    top: 45%;
}

.react__shape__ab {
    position: absolute;
    right: 54.5%;
    top: 28%;
    -webkit-animation: slide-top 5000ms linear infinite alternate;
    animation: slide-top 5000ms linear infinite alternate;
}

.react__shape__abc {
    position: absolute;
    right: 39%;
    top: 80%;
    -webkit-animation: slide-top 5000ms linear infinite alternate;
    animation: slide-top 5000ms linear infinite alternate;
}

/*****************************************************
    04. Banner Section CSS Here   
****************************/
.home-banner-part {
    position: relative;

    .container {
        max-width: 1430px;
    }

    .react__shape__1 {
        position: absolute;
        left: 100px;
        top: 80px;
        -webkit-animation: slide-top 5000ms linear infinite alternate;
        animation: slide-top 5000ms linear infinite alternate;
    }

    .react__shape__2 {
        position: absolute;
        left: -240px;
        bottom: 80px;
        -webkit-animation: slide-top 5500ms linear infinite alternate-reverse;
        animation: slide-top 5500ms linear infinite alternate-reverse;
    }

    .react__shape__3 {
        position: absolute;
        right: -35px;
        top: 80px;
        -webkit-animation: slide-top 5500ms linear infinite alternate-reverse;
        animation: slide-top 5500ms linear infinite alternate-reverse;
    }

    .react__shape__4 {
        position: absolute;
        right: 250px;
        bottom: 190px;
        -webkit-animation: rotate-center 6600ms ease-in-out infinite alternate both;
        animation: rotate-center 6600ms ease-in-out infinite alternate both;
    }

    .react__shape__5 {
        position: absolute;
        right: 460px;
        top: 160px;
    }

    .banner-content {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        .banner-title {
            font-size: 54px;
            margin-bottom: 15px;
            color: #091524;
            line-height: 64px;
            font-weight: 800;
            letter-spacing: -0.9px;
        }

        .banner-desc {
            font-size: 18px;
            color: #3e3e51;
        }

        .react-btn {
            padding: 12px 38px;
        }
    }

    .react__hero__card {
        position: absolute;
        top: 280px;
        right: 172px;
        width: 310px;
        background-color: #ffffff;
        border: 1px solid #ebebf1;
        box-shadow: 0px 20px 60px 0px rgba(0, 15, 56, 0.1);
        border-radius: 10px;
        -webkit-animation: slide-top 5500ms linear infinite alternate;
        animation: slide-top 5500ms linear infinite alternate;

        .react__thumb {
            position: relative;

            img {
                border-radius: 12px 12px 0 0;
            }

            .react__price {
                border-radius: 50%;
                background-color: $primaryColor;
                width: 50px;
                height: 50px;
                position: absolute;
                right: 29px;
                bottom: -23px;
                display: inline-block;
                color: $whiteColor;
                font-size: 16px;
                font-weight: 700;
                line-height: 50px;
                text-align: center;
            }
        }

        .hero__card-content {
            padding: 30px;

            .react-category {
                font-size: 14px;
                line-height: 28px;
                color: #f11e78;
                background-color: rgba(241, 30, 120, 0.059);
                border-radius: 12px;
                padding: 4px 14px;
            }

            .react-course-title {
                font-size: 20px;
                line-height: 1.5;
                margin: 14px 0 16px;
                font-weight: 800;

                a {
                    color: #0f1629;

                    &:hover {
                        color: $primaryColor;
                    }
                }
            }

            .hero__card-icon {
                justify-content: space-between;

                .hero__card-icon--1 {
                    svg {
                        border-radius: 50%;
                        background-color: #f2f6fe;
                        padding: 7px;
                        width: 32px;
                        height: 32px;
                        color: $primaryColor;
                    }

                    span {
                        font-weight: 500;
                        font-size: 15px;
                        color: #4b4c57;
                        padding-left: 7px;
                    }
                }

                .hero__card-icon--2 {
                    svg {
                        border-radius: 50%;
                        background-color: #fff9f1;
                        padding: 7px;
                        width: 32px;
                        height: 32px;
                        color: #ff9415;
                    }

                    span {
                        font-weight: 500;
                        font-size: 15px;
                        color: #4b4c57;
                        padding-left: 7px;
                    }
                }

                .react__user {
                    img {
                        width: 35px;
                        border-radius: 50%;
                    }
                }
            }
        }
    }
}

.home-five.searchbar-part {
    .search-form {
        background: $whiteColor  !important;
    }
}

.home-five.menu-part {
    width: 100%;
    position: absolute;
}

.home-five.section-title-area {
    .section-pretitle {
        color: $heading-color;
        font-size: 20px;
        font-weight: 500;
        line-height: 15px !important;
        margin-bottom: 20px;
    }

    .section-title {
        color: $heading-color;
        font-size: 50px;
        font-weight: 600;
        line-height: 60px;
    }

    @media(max-width: 1200px) {
        .section-pretitle {
            font-size: 20px;
        }

        .section-title {
            font-size: 40px;
        }
    }

    @media(max-width: 576px) {
        .section-pretitle {
            font-size: 16px;
            line-height: 0 !important;
        }

        .section-title {
            font-size: 30px;
            line-height: 40px;
        }
    }
}

.home-five.banner {
    position: relative;
    background: #f3f4fa;
    background-image: url("../images/home5/banner-bg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .contents {
        padding: 300px 0;

        .banner-pretitle {
            color: $heading-color;
            font-size: 20px;
            font-weight: 500;
        }

        .banner-title {
            color: $heading-color;
            font-size: 60px;
            font-weight: bold;
        }

        p {
            color: $heading-color;
            font-size: 20px;
            max-width: 420px;
            font-weight: 200px;
            margin-bottom: 35px;
        }

        .started-btn {
            padding: 20px 30px;
            border-radius: 5px;
            border: none;
            background: $primaryColor;
            font-size: 20px;
            transition: background 300ms;

            a {
                color: $whiteColor;
            }

            &:hover {
                background: #5387ff;
            }
        }
    }

    .hero {
        max-width: 626px;
        position: absolute;
        bottom: 0;
        right: 16%;

        img {
            max-width: 100%;
        }
    }

    @media(max-width: 1600px) {
        .hero {
            right: 5%;
        }
    }

    @media(max-width: 1200px) {
        .contents {
            padding-bottom: 80px;
        }

        .hero {
            position: relative;
            bottom: inherit;
            right: inherit;
        }
    }

    @media(max-width: 1200px) {
        .contents {
            padding: 0;
            padding-top: 150px;
            padding-bottom: 40px;
            max-width: 600px;
        }
    }

    @media(max-width: 576px) {
        .contents {
            .banner-pretitle {
                font-size: 16px;
            }

            .banner-title {
                font-size: 40px;
                max-width: 400px;
            }
        }
    }
}


.home-five.services-section {
    .service-top-contents {
        margin-bottom: 100px;

        .details {
            display: flex;
            flex-direction: column;
            padding-top: 50px;

            .top-detail {
                font-size: 19px;
                margin-bottom: 30px;
                color: #6b6b6b;

                .hightlight {
                    color: $heading-color;
                    font-weight: 500;
                }
            }

            .bottom-detail {
                font-size: 16px;
                color: #a5a5a5;
            }
        }
    }

    .service-bottom-contents {
        .service-item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding: 0 20px;

            .service-item-icon {
                margin-bottom: 30px;
            }

            .service-item-title {
                color: $heading-color;
                font-size: 22px;
                font-weight: 600;
                margin-bottom: 10px;
            }

            p {
                margin-bottom: 30px;
                color: #a5a5a5;
            }

            .learn-more {
                a {
                    color: #a5a5a5;
                }

                i {
                    color: #a5a5a5;
                    margin-left: 10px;
                    font-size: 14px;
                    visibility: hidden;
                    transform: translateX(-5px);
                    transition: transform 500ms;
                }

                &:hover {
                    a {
                        color: $primaryColor;
                    }

                    i {
                        color: $primaryColor;
                    }
                }

            }

            &:hover {
                .service-item-icon {
                    animation: shake-that .5s;
                }

                .learn-more {
                    i {
                        visibility: visible !important;
                        transform: translateX(0);
                    }
                }
            }
        }

        @media(max-width: 1200px) {
            margin-bottom: -40px;

            .service-item {
                p {
                    margin-bottom: 15px;
                }
            }

            .learn-more {
                margin-bottom: 40px;
            }
        }
    }

    @media(max-width: 1200px) {
        .service-item {
            margin-bottom: 50px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    @media(max-width: 769px) {
        .service-top-contents {
            margin-bottom: 40px;
        }

        .service-item {
            p {
                max-width: 300px;
            }
        }
    }
}

.home-five.about-section {
    background: #f4f5fb;

    .section-title-area {
        margin-bottom: 65px;
    }

    .about-cards {
        .about-card-item {
            background: $whiteColor;
            display: flex;
            justify-content: start;
            align-items: center;
            padding: 50px;
            border-radius: 5px;
            box-shadow: 5px 5px 19px -6px rgba(0, 0, 0, 0.05);
            margin-bottom: 30px !important;

            &:last-child {
                margin-bottom: 0;
            }

            .card-item-icon {
                width: 60px;
                margin-right: 30px;

                img {
                    max-width: 100%;
                }
            }

            .card-title {
                color: $heading-color;
                font-size: 22px;
                font-weight: 600;
            }

            p {
                margin-top: 10px;
                margin-bottom: 0;
            }
        }
    }

    @media(max-width: 1200px) {
        .about-hero {
            margin-bottom: 40px;
        }
    }

    @media(max-width: 576px) {
        .about-card-item {
            padding: 25px !important;
            flex-direction: column;
            align-items: unset !important;

            .card-item-icon {
                margin-bottom: 20px;
            }
        }
    }
}

.home-five.coaching-section {
    .container {
        margin-left: inherit;
    }

    position: relative;

    .coaching-hero {
        margin-right: 50px;

        img {
            max-width: 100%;
        }
    }

    .coaching-contents {
        max-width: 700px;

        p {
            margin: 0;
            margin-top: 20px;
            margin-bottom: 40px;
        }
    }

    .coaching-counter {
        display: flex;
        justify-content: start;
        align-items: center;

        .counter-item {
            margin-right: 80px;
        }

        .counter {
            color: $heading-color;
            font-size: 50px;
            font-weight: 600;
        }

        .sub-span {
            color: $heading-color;
            font-size: 50px;
            font-weight: 700px;
        }

        p {
            color: #777777;
            font-size: 20px;
            font-weight: 500;
            margin: 0;
        }
    }

    @media(max-width: 1200px) {
        max-width: 100%;

        .coaching-hero {
            width: 100%;
            margin-bottom: 40px;

            img {
                position: relative;
                width: 100%;
            }
        }
    }

    @media(max-width: 576px) {
        max-width: 100%;

        .coaching-counter {
            span {
                font-size: 35px !important;
            }

            .sub-span {
                font-size: 35px !important;
            }

            p {
                font-size: 15px !important;
            }
        }
    }

    @media(max-width: 480px) {
        .coaching-counter {
            width: 100%;
            justify-content: space-between;
            flex-wrap: wrap;

            .counter-item {
                margin: 0 !important;
                margin-left: 15px !important;
            }
        }
    }
}

.home-five.journey-section {
    .journey-card {
        border-radius: 10px;
        margin-bottom: 50px;

        &:hover {
            .journey-card-picture {
                img {
                    transform: scale(110%);
                }
            }

            .journey-contents {
                bottom: 20px;

                .card-title {
                    a {
                        color: $primaryColor;
                    }
                }
            }
        }

        position: relative;

        .journey-card-picture {
            max-width: 100%;
            overflow: hidden;
            border-radius: 10px;

            img {
                width: 100%;
                transition: transform 500ms;
            }
        }

        .journey-contents {
            width: 85%;
            padding: 40px;
            position: absolute;
            bottom: -50px;
            left: 50%;
            transform: translateX(-50%);
            background: $whiteColor;
            border-radius: 10px;
            box-shadow: 5px 5px 19px -6px rgba(0, 0, 0, 0.05);
            transition: 500ms;

            .lessons {
                color: #777777;
            }

            .card-title {
                a {
                    color: $heading-color;

                    &:hover {
                        color: #84a7f8 !important;
                    }
                }

                font-size: 21px;
                font-weight: 600;
                margin-bottom: 0 !important;
            }
        }
    }


    @media(max-width: 1400px) {
        .journey-contents {
            padding: 20px !important;

            .card-title {
                font-size: 18px !important;
            }
        }
    }

    @media(max-width: 1200px) {
        margin-bottom: -50px;

        .journey-card {
            margin-bottom: 100px;
        }
    }
}

.home-five.pricing-section {
    position: relative;

    .pricing-section-header-bg {
        z-index: -1;
        position: absolute;
        top: 0;
        width: 100%;
        height: 400px;
        background-image: url("../images/home5/pricing-bg.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    .section-title-area {
        margin-bottom: 70px !important;

        .section-title,
        .section-pretitle {
            color: $whiteColor;
        }
    }

    .price-card {
        background: white;
        box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.075);
        padding: 50px 0;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .price-plan {
            position: relative;
            width: 100%;
            padding-bottom: 60px;
            border-bottom: 2px solid #f3f4fa;
            text-align: center;

            .contents {
                padding: 0 40px;
            }

            .plan-type {
                color: $heading-color;
                font-size: 20px;
                font-weight: 500;
            }

            .price {
                color: $heading-color;
                font-size: 70px;
                font-weight: 600;
                line-height: 70px;
            }

            .validity {
                color: #777777;
            }

            .popular-plan {
                position: absolute;
                bottom: -25px;
                left: 50%;
                transform: translateX(-50%);
                background: $primaryColor;
                width: 75%;
                padding: 5px 0;
                border-radius: 50px;
                display: grid;
                place-items: center;
                color: $whiteColor;
                font-size: 22px;
                font-weight: 500;
            }
        }

        .price-details {
            padding: 0 50px;
            padding-top: 75px;
            width: 100%;

            .item {
                display: flex;
                justify-content: space-between;
                margin-bottom: 30px;

                .course {
                    color: $heading-color;
                    font-size: 20px;
                    font-weight: 500;
                }

                .value {
                    color: #777777;
                    font-size: 20px;
                }
            }

            .price-btn {
                width: 100%;
                padding: 10px 0;
                border-radius: 50px;
                border: none;
                background: #f3f4fa;
                font-size: 20px;
                margin-top: 15px;
                transition: background 300ms;

                a {
                    color: $heading-color;
                }

                &:hover {
                    background: $primaryColor;

                    a {
                        color: $whiteColor;
                    }

                }
            }
        }
    }

    @media(max-width: 1200px) {
        margin-top: 60px !important;
        margin-bottom: -30px;

        .price-card {
            margin-bottom: 30px;
        }
    }
}

.home-five.trending-section-five {


    .trending-card {
        position: relative;

        .card-image {
            overflow: hidden;

            &:hover {
                img {
                    transform: scale(110%);
                }
            }

            img {
                width: 100%;
                transition: transform 300ms;
            }
        }

        .price-tag {
            position: absolute;
            top: 200px;
            right: 30px;
            background: $primaryColor;
            width: 80px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            aspect-ratio: 1;
            padding: 7px;
            border-radius: 5px;

            .dollars {
                color: $whiteColor;
                font-size: 25px;
                font-weight: 500;
            }

            .cents {
                color: $whiteColor;
                font-size: 16px;
            }
        }

        .contents {
            padding: 50px 30px;
            padding-top: 30px;

            .status {
                margin-bottom: 10px;

                span {
                    color: #777777;
                    font-size: 16px;

                    i {
                        color: #777777;
                        font-size: 14px;
                        margin-right: 7px;
                    }
                }

                .students {
                    margin-right: 40px;
                }
            }

            .card-title {
                a {
                    color: $heading-color;

                    &:hover {
                        color: $primaryColor;
                    }
                }

                font-size: 22px;
                font-weight: 600;
            }
        }
    }

    .btn-area {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .all-course-btn {
            background: $primaryColor;
            transition: background 300ms;

            a {
                color: $whiteColor  !important;
                font-size: 20px;
                font-weight: 500;
            }

            i {
                color: $whiteColor;
                margin-left: 5px;
                font-size: 20px;
            }

            padding: 15px 45px;
            border-radius: 5px;
            border: none;

            &:hover {
                background: #4d82ff;
            }
        }
    }

    @media(max-width: 1400px) {
        .trending-card {
            .price-tag {
                top: 150px;
            }

            .card-title {
                font-size: 20px !important;
            }
        }
    }

    @media(max-width: 1200px) {
        .trending-card {
            .contents {
                padding: 20px 10px;
            }

            .price-tag {
                top: 140px !important;
                width: 50px;
                padding: 5px 30px !important;

                .dollars {
                    font-size: 18px;
                    margin-bottom: -6px;
                }

                .cents {
                    font-size: 14px;
                }

                top: 150px;
            }

            .card-title {
                font-size: 16px !important;
            }
        }
    }

    @media(max-width: 1400px) {
        .trending-card {
            .price-tag {
                top: 150px;
            }
        }
    }
}


.home-five.instructor-section {
    position: relative;

    .instructor-side {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transform: translateY(20px) !important;

        .instructor-dp {
            max-width: 180px;

            img {
                width: 100%;
            }
        }

        &.left-side {
            transform: translateX(-100px);
        }

        &.right-side {
            transform: translateX(100px);
        }

        .instructor-image-2 {
            transform: translateY(-30px) translateX(100px);
        }

        .instructor-image-4 {
            transform: translateY(-30px) translateX(-100px);
        }
    }

    .contents {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .section-title-area {
            max-width: 500px;
            margin-bottom: 40px;
        }

        p {
            max-width: 450px;
            margin-bottom: 40px;
        }

        .free-lesson-btn {
            background: $primaryColor;
            transition: background 300ms;

            a {
                color: $whiteColor  !important;
                font-size: 20px;
                font-weight: 500;
            }

            i {
                color: $whiteColor;
                margin-left: 5px;
                font-size: 20px;
            }

            padding: 15px 45px;
            border-radius: 5px;
            border: none;

            &:hover {
                background: #4d82ff;
            }
        }
    }

    @media(max-width: 1200px) {
        .left-side {
            margin-bottom: 30px;

            .instructor-image-1 {
                transform: translateX(-50px);
            }
        }

        .right-side {
            display: none;
        }
    }

    @media(max-width: 430px) {
        .instructor-side {
            .instructor-dp {
                max-width: 120px !important;
            }
        }

        .left-side {
            margin-bottom: 20px;

            .instructor-image-2 {
                transform: translateY(-34px) translateX(50px) !important;
            }
        }
    }
}

.home-five.testimonials-section {
    position: relative;
    background: #f3f4fabd;
    background-image: url("../images/home5/banner-bg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    .testimonials-card {
        background: $whiteColor;
        max-width: 100%;
        padding: 0;
        padding-left: 150px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .quote-image {
            position: absolute;
            left: 50px;
            top: 50px;
            width: 100px;

            img {
                max-width: 100%;
            }
        }

        .contents {
            padding-right: 50px;

            .identity {
                display: flex;
                flex-direction: column;
            }

            .name {
                color: $heading-color;
                font-size: 22px;
                font-weight: 600;
                padding-bottom: 10px;
            }

            .status {
                color: $heading-color;
                font-size: 16px;
                font-weight: 600;
                margin-bottom: 15px;
            }

            p {
                color: #777777;
            }
        }

        .card-image {
            width: 100%;

            img {
                width: 100%;
            }
        }

        .testimonials-rating {
            i {
                color: $heading-color;
            }

            .blank-star {
                color: #e5e5e5;
            }
        }
    }

    .testimonials-slider-navigation {
        width: 90%;
        position: absolute;
        top: 60%;
        left: 50%;
        display: flex;
        justify-content: space-between;
        transform: translate(-50%);
        z-index: 1;

    }

    .testimonials-slider-btn {
        width: 50px;
        aspect-ratio: 1;
        border-radius: 50%;
        display: grid;
        place-items: center;
        border: 1px solid black;
        position: relative;

        &:hover {
            i {
                color: $primaryColor  !important;
            }
        }
    }

    @media(max-width: 1660px) {
        .testimonials-slider-navigation {
            top: auto;
            bottom: 120px;
            width: 150px;
        }
    }

    @media(max-width: 576px) {
        .testimonials-slider-navigation {
            bottom: 60px !important;
        }
    }


    @media(max-width: 1660px) {
        padding-bottom: 200px !important;

        .testimonials-slider-navigation {
            top: auto;
            bottom: 120px;
            width: 150px;
        }
    }

    @media(max-width: 1400px) {
        .card-image {
            width: 100%;

            img {
                width: inherit;
            }
        }
    }

    @media(max-width: 1200px) {
        .card-image {
            width: 100%;

            img {
                width: unset !important;
                max-width: unset;
            }
        }
    }

    @media(max-width: 992px) {
        .testimonials-card {
            flex-direction: column !important;
            padding-left: 0;

            .quote-image {
                display: none;
            }

            .contents {
                padding: 20px;
            }

            .card-image {
                img {
                    width: 100% !important;
                }
            }
        }
    }

    @media(max-width: 576px) {
        padding-bottom: 140px !important;

        .testimonials-slider-navigation {
            bottom: 60px !important;
        }
    }
}

.home-five.blog-section {
    position: relative;
    margin-bottom: 120px;

    .blog-top-area {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .blog-card {
        position: relative;
        overflow: hidden !important;

        .card-image {
            overflow: hidden;

            &:hover {
                img {
                    transform: scale(110%);
                }
            }

            img {
                width: 100%;
                transition: transform 300ms;
            }
        }

        .time-tag {
            position: absolute;
            top: 260px;
            right: 30px;
            background: $primaryColor;
            width: 80px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            aspect-ratio: 1;
            padding: 7px;
            border-radius: 5px;

            .day {
                color: $whiteColor;
                font-size: 25px;
                font-weight: 600;
                line-height: 20px;
            }

            .month {
                color: $whiteColor;
                font-size: 15px;
            }
        }

        .contents {
            padding-top: 30px;

            .author {
                margin-bottom: 10px;
                color: #777777;
                font-size: 16px;

                i {
                    color: #777777;
                    font-size: 14px;
                    margin-right: 7px;
                }
            }

            .card-title {
                a {
                    color: $heading-color;
                }

                font-size: 22px;
                font-weight: 600;
            }
        }
    }

    .blog-slider-navigation {
        display: flex;

        .blog-slider-btn {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            display: grid;
            place-items: center;
            border: 1px solid black;
            position: relative;

            &:hover {
                i {
                    color: $primaryColor  !important;
                }
            }
        }

        .blog-slider-next-btn {
            margin-left: 20px;
        }
    }

    @media(max-width: 992px) {
        margin-bottom: 0 !important;

        .contents {
            padding: 0 !important;
            padding-top: 30px !important;
        }

        .time-tag {
            .day {
                font-size: 18px !important;
                line-height: 0;
            }

            .month {
                font-size: 16px !important;
            }
        }
    }

    @media(max-width: 460px) {
        .blog-top-area {
            margin-bottom: 30px;
            flex-direction: column !important;

            .section-title-area {
                margin-bottom: 30px;
            }
        }

        .time-tag {
            .day {
                font-size: 18px !important;
                line-height: 0;
            }

            .month {
                font-size: 16px !important;
            }
        }
    }
}

.home-five {
    @media(max-width: 576px) {
        button {
            padding: 10px 20px !important;
            font-size: 16px !important;

            i {
                font-size: 14px !important;
            }
        }
    }
}

.home-five.footer-top-cta {
    background-image: unset !important;
    background: $primaryColor  !important;
    padding: 75px 100px !important;

    h4 {
        font-weight: 500 !important;
    }

    h3 {
        font-weight: 600 !important;
    }

    a {
        color: $whiteColor;
        border: 1px solid $whiteColor  !important;
    }

    @media(max-width: 1400px) {
        padding: 50px 80px !important;
    }

    @media(max-width: 769px) {
        padding: 50px 30px !important;
    }

    @media(max-width: 400px) {
        padding: 50px 20px !important;
    }
}


.react-menus-six {
    li {
        a {
            color: $homesix-textColor  !important;
        }
    }
}


.section-title-area-six {
    .section-pretitle {
        color: $titleColor;
        font-size: 20px;
        font-weight: 600;
        line-height: 15px !important;
        margin-bottom: 20px;
        // @media(max-width: 1200px){
        //     margin-bottom: 20px !important;
        // }
    }

    .section-title {
        color: $titleColor;
        font-size: 50px;
        font-weight: 700;
        line-height: 60px;
    }

    @media(max-width: 1200px) {
        .section-pretitle {
            font-size: 20px;
        }

        .section-title {
            font-size: 40px;
        }
    }

    @media(max-width: 576px) {
        .section-pretitle {
            font-size: 16px;
            line-height: 0 !important;
        }

        .section-title {
            font-size: 30px;
            line-height: 40px;
        }
    }
}

.home-six-btn {
    position: relative;
    background: white;
    border: none;
    padding: 15px 45px;
    border-radius: 0;
    overflow: hidden;
    z-index: 1;

    a {
        color: $homesix-textColor;
        position: relative;
        z-index: 1;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 8px;
        height: 102%;
        background: #d20936;
        transition: width 500ms;
        z-index: 0;
    }

    &:hover {
        background: #ffffff;

        a {
            color: $whiteColor;
        }

        i {
            color: $whiteColor;
        }

        &::before {
            width: 94%;
        }
    }
}

.react-slider-part-six {
    .slider-content {
        .content-part {
            .slider-pretitle {
                background: none;
                padding: 0;
            }

            .slider-title {
                margin-bottom: 50px;
            }

            .event__video-btn--play-six {
                right: 55%;
                top: 93%;

                .event__video-btn--play-btn-six {
                    width: 60px;
                    height: 60px;
                    line-height: 0;
                    position: relative !important;

                    .video-icon {
                        width: 20px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        z-index: 1;

                        img {
                            max-width: 100%;
                        }
                    }

                    em {
                        margin-left: 55px !important;
                        font-weight: 500 !important;
                    }

                    &::before {
                        top: -50% !important;
                        left: -50% !important;
                        transform: translate(-50%, -50%);
                        animation: videoBtnAnim6 3s linear infinite !important;
                    }
                }
            }
        }
    }

    .owl-nav [class*="owl-"]::before {
        font-size: 70px !important;
        font-weight: 100 !important;

        &:hover {
            &::before {
                color: red !important;
            }
        }
    }
}

@media(max-width: 1300px) {
    .event__video-btn--play-six {
        right: 50% !important;
    }
}

@media(max-width: 1200px) {
    .event__video-btn--play-six {
        right: 35% !important;
    }
}

@media(max-width: 992px) {
    .event__video-btn--play-six {
        display: block !important;
        right: 50% !important;
    }
}

@media(max-width: 588px) {
    .event__video-btn--play-six {
        right: unset;
        left: 45% !important;

        em {
            display: none !important;
        }
    }
}

@media(max-width: 588px) {
    .event__video-btn--play-six {
        left: 50% !important;
    }
}

@media(max-width: 396px) {
    .event__video-btn--play-six {
        left: 55% !important;
    }
}




.about-section-six {
    .fat-paragraph {
        color: #4d5765;
        font-weight: 600;
    }

    .plain-paragraph {
        color: #4d5765;
        margin-bottom: 60px;
    }

    .home-six-btn {
        border: 1px solid $paragraphColor;

        &:hover::before {
            width: 100%;
        }
    }

    @media(max-width: 1200px) {
        .section-title-area-six {
            margin-bottom: 40px;
        }
    }

    .about-features-wrapper {
        position: relative;
        height: 160px;
        width: 100%;

        .about-features-six {
            position: absolute;
            top: -10px;
            left: 0;
            max-width: 100%;
            background: $whiteColor;
            z-index: 20;
            padding: 40px 60px;
            box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.055);
            transform: translateY(140px);

            .about-feature-item {
                .feature-icon {
                    margin-bottom: 25px;
                    transition: transform 500ms;
                }

                .feature-title {
                    color: $titleColor;
                    font-size: 22px;
                    font-weight: 600;
                }

                &:hover {
                    .feature-icon {
                        transform: translateY(-10px);
                    }
                }
            }
        }

        @media(max-width: 1200px) {
            margin-top: 132px;
            height: unset !important;

            .about-features-six {
                position: relative !important;
                transform: translate(0) !important;
            }

            .about-feature-item {
                padding-bottom: 60px !important;
            }

            padding-bottom: -60px !important;
        }

        @media(max-width: 576px) {
            margin-top: 82px;
        }
    }

}

.video_tour-section-six {
    background-image: url("../images/home6/video-bg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 270px 0;
    position: relative;
    .contents{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    }
    .tour__video-btn--play-six {
        position: relative;
        left: unset;
        transform: unset !important;
        margin-bottom: 40px;
        img{
            position: relative;
            z-index: 1;
        }
        a {
            &::before {
                background: #9f9891;
                border: none;
                z-index: -1;
            }
        }
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(44, 44, 44, 0.425);
    }

    .contents {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        z-index: 2;
        position: relative;

        .video-play {
            transform: translateX(0);
            position: relative;
            left: 0;
            right: 0;
            margin-bottom: 40px;

            a {
                &::after {
                    display: none !important;
                }
            }
        }

        .video-title {
            color: $whiteColor;
            font-size: 50px;
            font-weight: 600;
        }

        p {
            color: $whiteColor;
            font-size: 22px;
            max-width: 620px;
        }
    }

    @media(max-width: 992px) {
        .video-title {
            font-size: 30px !important;
        }

        p {
            font-size: 16px !important;
        }
    }
}


.academics-section-six {

    .view-all {
        color: #999999;

        i {
            font-size: 14px;
            margin-left: 15px;
        }

        &:hover {
            color: $homesix-primaryColor;

            i {
                color: $homesix-primaryColor;
            }
        }
    }

    .academics-cards-area {
        padding-bottom: 70px;
    }

    .academics-card {
        .card-image {
            position: relative;
            margin-bottom: 25px;
            overflow: hidden;

            img {
                width: 100%;
                transition: transform 500ms;
            }

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.712);
                opacity: 0;
                transition: 500ms;
            }
        }

        .card-title {
            transition: transform 500ms;

            a {
                color: $titleColor6;
                font-size: 26px;
                font-weight: 600;

                &:hover {
                    color: $homesix-primaryColor;
                }
            }
        }

        .learn-more {
            color: #4d5765;
            font-size: 14px;
            transition: transform 500ms;

            i {
                margin-left: 10px;
            }

            &:hover {
                color: $homesix-primaryColor;

                i {
                    color: $homesix-primaryColor;
                }
            }
        }

        &:hover {
            .card-image {
                img {
                    transform: scale(110%);
                }
            }
        }
    }

    .academics-card-2 {
        padding: 50px;
        border: 1px solid $paragraphColor;

        .card-icon {
            margin-bottom: 40px;
        }

        .card-title {
            a {
                color: $titleColor6;
                font-size: 22px;
                font-weight: 600;

                &:hover {
                    color: $homesix-primaryColor;
                }
            }
        }

        p {
            margin-bottom: 30px;
        }

        .learn-more {
            color: #4d5765;

            i {
                margin-left: 10px;
            }

            &:hover {
                color: $homesix-primaryColor;

                i {
                    color: $homesix-primaryColor;
                }
            }
        }

        &:hover {
            .card-icon {
                animation: flip 1s;
            }
        }
    }

    @media(max-width: 1200px) {
        margin-bottom: -25px;

        .academics-card {
            margin-bottom: 40px;
        }

        .academics-top-contents {
            margin-bottom: 40px !important;
        }

        .academics-card-2 {
            margin-bottom: 25px;
            padding: 30px;
            text-align: center;
        }
    }

    @media(max-width: 769px) {
        .academics-cards-area {
            margin-bottom: -60px;
        }

        .academics-card {
            margin-bottom: 60px;
        }
    }

    @media(max-width: 576px) {
        .academics-top-contents {
            margin-bottom: 40px !important;
        }

        .academics-card-2 {
            padding: 30px;
        }
    }
}


.campus-section-six {
    .campus-picture {
        width: 100%;
        height: 100%;

        img {
            width: 100%;
        }
    }

    .contents {
        width: 100%;
        height: 36.6vw;
        overflow: hidden;
        background: $secondaryColor;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 95px;
        padding-right: 30px;

        .campus-title {
            color: $whiteColor;
            font-size: 50px;
            font-weight: 600;
        }

        p {
            color: $paragraphColor;
            font-weight: 300;
            max-width: 550px;
        }

        .campus-dropdown {
            .campus-dropdown-item {
                .dropdown-icon {
                    width: 40px;
                    aspect-ratio: 1;
                    display: grid;
                    place-items: center;
                    border: 2px solid $whiteColor;
                    border-radius: 50%;
                    margin-right: 20px;
                    cursor: pointer;
                    transition: 300ms;

                    i {
                        color: $whiteColor;
                        transition: transform 300ms;
                    }

                    &:hover {
                        background: #d20936;
                        border: 2px solid #d20936;
                    }
                }

                .showed {
                    margin-bottom: 15px;
                }

                .title {
                    color: $whiteColor;
                    font-size: 16px;
                    cursor: pointer;
                }

                .hidden-content {
                    height: 0;
                    visibility: hidden;
                    opacity: 0;
                    transform: translateY(-10px);
                    overflow: hidden;
                    transition: 300ms;
                    margin-bottom: 20px;

                    p {
                        max-width: 450px;
                    }
                }

                &.active {
                    .dropdown-icon {
                        background: #d20936;
                        border: 2px solid #d20936;

                        i {
                            transform: rotate(90deg);
                        }
                    }

                    .hidden-content {
                        height: 100px;
                        visibility: visible;
                        opacity: 1;
                        transform: translateY(0);
                    }
                }
            }
        }

        .home-six-btn {
            max-width: 200px;
        }

        .campus-btn {
            max-width: 170px;

            &:hover {
                color: $whiteColor;
            }
        }
    }

    @media(max-width: 1200px) {
        .contents {
            height: unset;
            padding-top: 50px;
            padding-bottom: 50px;
        }
    }
}


.courses-section-six {
    .coursesSlider-6 {
        padding: 20px;
    }

    .slider-area {
        padding: -20px;
    }

    .section-title-area-six {
        margin-bottom: 50px;
    }

    .course-card {
        position: relative;
        box-shadow: 0px 5px 20px rgba(5, 5, 5, 0.05);

        .card-image {
            img {
                width: 100%;
            }
        }

        .price-tag {
            position: absolute;
            top: 200px;
            right: 20px;
            background: $homesix-primaryColor;
            color: $whiteColor;
            font-weight: 600;
            width: 50px;
            aspect-ratio: 1;
            border-radius: 50%;
            display: grid;
            place-items: center;
        }

        .card-inner {
            padding: 0 40px;
        }

        .top-info {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-top: 30px;
            margin-bottom: 20px;

            .subject,
            .lessons {
                color: $homesix-textColor;
            }
        }

        .card-title {
            a {
                color: $titleColor;
                font-size: 20px;
                font-weight: 600;
            }

            &:hover {
                a {
                    color: $homesix-primaryColor;
                }

                i {
                    color: $homesix-primaryColor;
                }
            }
        }

        .status {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 20px 0;
            border-bottom: 1px solid #d9d9d9;

            .students,
            .hours {
                color: $homesix-textColor;

                i {
                    margin-right: 15px;
                }
            }
        }

        .bottom-content {
            display: flex;
            justify-content: space-between;
        }

        .courses-rating-six {
            padding: 30px 0;
        }
    }

    .courses-rating-six {
        i {
            color: #ff912c;
        }

        .blank-star {
            color: #e5e5e5;
        }
    }

    .learn-details {

        a,
        i {
            color: $homesix-textColor;
        }

        i {
            margin-left: 5px;
            font-size: 13px;
        }

        &:hover {
            a {
                color: $homesix-primaryColor;
            }

            i {
                color: $homesix-primaryColor;
            }
        }
    }

    .btn-area {
        width: 100%;
        text-align: center;
        margin-top: 50px;

        .browse-course-btn {
            border: 1px solid $paragraphColor;

            i {
                margin-left: 10px;
                font-size: 13px;
                color: $homesix-textColor;
            }

            &:hover {
                &::before {
                    width: 100%;
                }

                i {
                    position: relative;
                    color: $whiteColor;
                    z-index: 1;
                }
            }
        }
    }

    @media(max-width: 769px) {
        .btn-area {
            margin-top: 20px;
        }
    }
}


.events-section-six {
    background-image: url("../images/home6/events/events-bg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    .section-title-area-six {
        .section-title {
            color: $whiteColor;
        }
    }

    .event-card {
        .event-card-picture {
            transition: transform 300ms;
            overflow: hidden;

            img {
                width: 100% !important;
            }
        }

        &:hover {
            .event-card-picture {
                transform: scale(110%);
            }
        }
    }

    .contents {
        .top-content {
            margin-top: 25px;
            display: flex;
            align-items: center;

            .date {
                display: flex;
                flex-direction: column;
                margin-right: 30px;

                .day {
                    color: $whiteColor;
                    font-size: 30px;
                    font-weight: 600;
                    line-height: 25px;
                }

                .month {
                    color: $whiteColor;
                    font-size: 18px;
                    font-weight: 500;
                }
            }

            .card-title {
                a {
                    color: $whiteColor;
                    font-size: 18px;
                    font-weight: 500;

                    &:hover {
                        color: $homesix-primaryColor;
                    }
                }
            }
        }

        .bottom-content {
            margin-top: 15px;
            position: relative;
            display: flex;
            align-items: center;

            &::before {
                content: '';
                position: absolute;
                top: 11px;
                left: 0;
                width: 30px;
                height: 4px;
                background: $homesix-primaryColor;
            }

            .event-time,
            .event-location {
                color: $whiteColor;
                font-size: 14px;
                font-weight: 300;

                i {
                    margin-right: 5px;
                }
            }

            .event-time {
                margin-right: 45px;
                padding-left: 60px;
            }
        }
    }

    @media(max-width: 1200px) {
        .event-card {
            padding-bottom: 60px;
        }

        padding-bottom: 60px !important;
    }

    @media(max-width: 992px) {
        .event-card {
            padding-bottom: 40px;
        }

        padding-bottom: 30px !important;
    }

    @media(max-width: 392px) {
        .bottom-content {

            .event-time,
            .event-location {
                font-size: 11px !important;
            }
        }
    }
}



.counter-section-six {
    .counter-item {
        display: flex;
        flex-direction: column;
        align-items: center;

        .counter-icon {
            margin-bottom: 15px;
        }

        .count {
            span {
                font-size: 40px;
                line-height: 50px;
                font-weight: 600;
                color: $titleColor;
            }
        }

        p {
            font-size: 14px;
            color: #888f98;
        }
    }

    @media(max-width: 1200px) {
        margin-bottom: -60px;

        .counter-item {
            margin-bottom: 60px;
        }
    }

    @media(max-width: 769px) {
        margin-bottom: -60px;

        .counter-item {
            margin-bottom: 40px;
        }
    }
}


.blog-section-six {
    .all-news {
        color: #999999;

        i {
            margin-left: 10px;
            font-size: 14px;
        }

        &:hover {
            color: $titleColor;
        }
    }

    .maximized-part {
        .blog-picture {
            margin-bottom: 20px;
            overflow: hidden;

            img {
                width: 100%;
                transition: transform 300ms;

                &:hover {
                    transform: scale(110%);
                }
            }
        }

        .blog-date {
            color: $titleColor6;
            font-size: 16px;
            margin-bottom: 7px;
        }

        .blog-title {
            a {
                color: $titleColor6;
                font-size: 22px;
                font-weight: 600;
                line-height: 40px;

                &:hover {
                    color: $homesix-primaryColor;
                }
            }

            margin-bottom: 15px;
            max-width: 550px;
        }

        .read-more {
            color: $homesix-textColor;
            font-size: 16px;

            i {
                font-size: 12px;
                margin-left: 5px;
            }

            &:hover {
                color: $homesix-primaryColor;

                i {
                    color: $homesix-primaryColor;
                }
            }
        }
    }

    .minimized-part {
        .blog-item {
            display: flex;
            justify-content: start;
            margin-bottom: 20px;

            .blog-picture {
                width: 200px;
                margin-right: 30px;
                overflow: hidden;

                img {
                    max-width: 100%;
                    transition: transform 300ms;

                    &:hover {
                        transform: scale(110%);
                    }
                }
            }

            .content {
                .blog-date {
                    color: $titleColor6;
                    font-size: 16px;
                    margin-bottom: 10px;
                }

                .blog-title {
                    a {
                        color: $titleColor6;
                        font-size: 18px;
                        font-weight: 600;
                        line-height: 25px;

                        &:hover {
                            color: $homesix-primaryColor;
                        }
                    }

                    margin-bottom: 10px;
                    max-width: 450px;
                }

                .read-more {
                    color: $homesix-textColor;
                    font-size: 16px;
                    margin-bottom: 0 !important;

                    i {
                        font-size: 12px;
                        margin-left: 5px;
                    }
                }
            }
        }
    }

    .section-title {
        width: 100%;
    }

    @media(max-width: 769px) {
        .maximized-part {
            margin-bottom: 40px;

            .blog-picture {
                margin-bottom: 20px;

                img {
                    max-width: 100%;
                }
            }

            .blog-title {
                margin-bottom: 20px;

                a {
                    font-size: 23px;
                    min-width: 320px;
                }
            }

            .blog-date {
                margin-bottom: 20px;
            }

            .read-more {
                font-size: 16px;
            }
        }

        .minimized-part {
            .content {
                .blog-picture {
                    margin-bottom: 20px;

                    img {
                        max-width: 100%;
                    }
                }

                .blog-title {
                    a {
                        font-size: 17px !important;
                    }
                }

                .blog-date {
                    margin-bottom: 20px;
                }

                .read-more {
                    font-size: 16px;
                }
            }
        }
    }

    @media(max-width: 358px) {
        .section-title {
            font-size: 30px;
        }
    }

    .news-form-area {
        background: $paragraphColor;
        padding: 50px 45px;
    }

    .news-form {
        .heading {
            color: $titleColor6;
            font-size: 22px;
            font-weight: 600;
            margin-bottom: 35px;
        }

        .input-area {
            display: flex;
            flex-direction: column;

            input {
                margin-bottom: 30px;
                padding: 10px;
                font-size: 14px;
                background: $whiteColor;
                border: none;
                outline: $secondaryColor;

                &::placeholder {
                    color: #999999;
                }
            }
        }

        .news-signup-btn {
            margin-top: 25px;
            color: $whiteColor;
            font-size: 16px;
            padding: 10px 0;
            width: 100%;
            background: $secondaryColor;
            border: none;
            margin: 0 auto;
            transition: background 300ms;

            &:hover {
                background: #1451a1;
            }
        }
    }

    @media(max-width: 1200px) {
        .blog-top-contents {
            margin-bottom: 60px !important;
        }

        .news-form-area {
            margin-top: 50px;
        }
    }

    @media(max-width: 769px) {
        .blog-top-contents {
            margin-bottom: 30px !important;
        }

        .news-form-area {
            margin-top: 30px;
        }
    }

    @media(max-width: 480px) {
        .news-form-area {
            padding: 30px 15px !important;
        }
    }
}

.testimonials-section-six {
    position: relative;

    .testimonialsSlider6 .testimonials-card {
        background: transparent !important;
        border: 1px solid #ececec !important;
        transition: background 500ms;

        .name {
            color: $titleColor6  !important;
        }
    }

    .testimonialsSlider6 .swiper-slide-active .testimonials-card {
        background: $whiteColor  !important;
        border: 1px solid transparent !important;
    }

    .testimonials-card:hover {
        background: $whiteColor  !important;
        border: 1px solid transparent !important;
    }

    .testimonials-slider-navigation {
        width: 83%;
        position: absolute;
        top: 60%;
        left: 50%;
        display: flex;
        justify-content: space-between;
        transform: translate(-50%);
        z-index: 1;

    }

    .testimonials-slider-btn {
        position: relative;

        i {
            font-size: 35px;
        }

        &:hover {
            i {
                color: $primaryColor  !important;
            }
        }
    }

    .testimonials-slider-navigation {
        i {
            &:hover {
                color: $homesix-primaryColor  !important;
            }
        }
    }

    @media(max-width: 1660px) {
        .testimonials-slider-navigation {
            top: auto;
            bottom: 114px;
            width: 150px;
        }
    }

    @media(max-width: 768px) {
        .testimonials-card {
            max-width: 100% !important;
        }
    }

    @media(max-width: 576px) {
        padding-bottom: 80px !important;

        .testimonials-slider-navigation {
            width: 100px;

            i {
                font-size: 20px;
            }

            bottom: 54px !important;
        }
    }
}


.mfp-fade.mfp-bg {
    opacity: 0;
    -webkit-transition: all 0.15s ease-out;
    -moz-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
}

.mfp-fade.mfp-bg.mfp-ready {
    opacity: 0.8;
}

.mfp-fade.mfp-bg.mfp-removing {
    opacity: 0;
}

.mfp-fade.mfp-wrap .mfp-content {
    opacity: 0;

    -webkit-transition: all 0.15s ease-out;
    -moz-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
}

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
    opacity: 1;
}

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
    opacity: 0;
}

.gallery-secton-six {
    .item img {
        width: 100%;
    }

    .owl-nav {
        display: none;
    }
}

.mfp-image-holder {
    background: rgba(0, 0, 0, 0.308);
    backdrop-filter: blur(5px);
}

.hidden {
    overflow: hidden;
    display: none;
    visibility: hidden;
}

.btn-gallery img {
    z-index: 10;
    height: auto;
    cursor: zoom-in;
}

.btn-gallery {
    li {
        position: relative;
    }

    a {
        i {
            display: none;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 100;
            color: rgb(51, 51, 51);
            font-size: 20px;
        }

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 80%;
            height: 80%;
            background: rgba(221, 221, 221, 0.719);
            backdrop-filter: blur(2px);
            visibility: hidden;
            transform: translate(-50%, -50%) scale(80%);
            opacity: 0;
            transition: 400ms;
        }

        &:hover {
            i {
                display: block;
            }

            &::before {
                visibility: visible;
                opacity: 1;
                transform: translate(-50%, -50%) scale(100%);

            }
        }
    }
}

.overview-section-six {
    .overview-navigation {
        position: relative;
        background: $paragraphColor;
        padding: 50px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .navigation-btn {
            cursor: pointer;
            padding-bottom: 20px;
            margin-bottom: 20px;
            border-bottom: 1px solid #d8d8d6;
            color: $titleColor6;
            font-size: 22px;
            font-weight: 600;

            &:last-child {
                border: none;
                padding-bottom: 0;
                margin-bottom: 0;
            }

            &::before {
                content: '';
                position: absolute;
                top: 0px;
                right: -65px;
                transform: rotate(45deg);
                width: 35px;
                height: 35px;
                background: $paragraphColor;
                display: none;
            }

            &.active {
                position: relative;
                color: $homesix-primaryColor;

                &::before {
                    display: block;
                }
            }
        }
    }

    .item {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        visibility: visible;
        opacity: 1;
        transform: translateX(0);
        transition: 500ms;
    }

    .hide {
        visibility: hidden;
        opacity: 0;
        transform: translateX(-100px);
        position: absolute;

        a {
            display: none !important;
        }

        transition: none;

    }

    .content-picture {
        max-width: 410px;
        margin-right: 40px;
        display: flex;
        justify-content: center;

        img {
            width: 100%;
        }
    }

    .content {
        .title {
            color: $titleColor6;
            font-size: 30px;
            font-weight: 600;
            margin-bottom: 30px;
        }

        p {
            max-width: 425px;
            color: #4d5765;

            &.oneline {
                margin-top: 40px;
                margin-bottom: 50px;
            }
        }

        .read-more {
            color: #4d5765;

            i {
                color: #4d5765;
                font-size: 13px;
                margin-left: 5px;
            }

            &:hover {
                color: $titleColor6;

                i {
                    color: $titleColor6;
                }
            }
        }
    }

    @media(max-width: 1400px) {
        .overview-navigation {
            .navigation-btn {
                font-size: 18px;

                &::before {
                    width: 25px;
                    height: 25px;
                    right: -60px;
                }
            }
        }
    }

    @media(max-width: 1200px) {
        .overview-navigation {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 10px 30px !important;
            margin-bottom: 40px !important;

            .navigation-btn {
                margin: 0;
                padding: 0;
                padding-right: 50px;
                border-bottom: none;
                border-right: 1px solid #d8d8d6;
                font-size: 18px;

                &::before {
                    width: 25px;
                    height: 25px;
                    top: 45px;
                    right: unset;
                    left: 30%;
                }
            }
        }

        .overview-contents {
            margin-top: 40px;

            .item {
                justify-content: unset !important;
            }
        }
    }

    @media(max-width: 992px) {
        .overview-navigation {
            padding: 15px !important;

            .navigation-btn {
                border: none;
                font-size: 14px;
            }
        }
    }

    @media(max-width: 769px) {
        .overview-navigation {
            flex-wrap: wrap;
            justify-content: center;

            .navigation-btn {
                font-size: 16px;
                padding: 0 20px;

                &::before {
                    display: none !important;
                }
            }
        }

        .overview-contents {
            .item {
                display: flex;
                flex-direction: column;

                .content-picture {
                    margin-bottom: 30px;
                    width: 100%;
                    max-width: 100%;
                    margin-right: 0;
                }
            }
        }
    }
}

.topbar-area-seven {
    @media(max-width: 992px) {
        display: none;
    }
}

.react-header-seven {
    .react-main-menu {
        padding: 25px 0;
    }

    .react-menus-seven {
        li {
            a {
                color: $heading-color;

                &:hover {
                    color: $homeseven-primaryColor  !important;
                }
            }
        }

        @media(max-width: 1250px) {
            li {
                a {
                    padding: 32px 30px 32px 30px;
                }
            }
        }

        @media(max-width: 1090px) {
            li {
                a {
                    padding: 32px 17px 32px 17px;
                }
            }
        }
    }

    .searchbar-part {
        .search-form {
            background: none;
            border: 1px solid #e5e5e5;
            border-radius: 50px;

            svg {
                stroke: #9b9b9b;
            }
        }

        .react-login {
            margin-right: 30px;
            border: none;

            a {
                color: $heading-color;

                svg {
                    margin-right: 20px;
                }
            }

            &:hover {
                a {
                    color: $homeseven-primaryColor;
                }
            }

            @media(max-width: 1200px) {
                padding: 0;

                a {
                    font-size: 13px;

                    svg {
                        margin-right: 5px;
                    }
                }
            }

            @media(max-width: 1150px) {
                padding: 0;
            }
        }
    }

    .react-logins {
        border: none;
        padding-left: 20px;

        a {
            background: #3270FC;
            border-radius: 6px;
            padding: 10px 26px;
            line-height: 20px;
            display: inline-block;
            color: #fff;
            font-weight: 600;
            font-size: 16px;

            svg {
                width: 18px;
            }

            &:hover {
                opacity: .9;
            }
        }
    }
}

.home-seven-btn {
    background: $homeseven-primaryColor;
    border: none;
    padding: 15px 40px;
    border-radius: 5px;
    transition: background 300ms;

    a {
        color: $whiteColor;
        font-size: 20px;
    }

    &:hover {
        background: #4a80ff !important;
    }
}

.home-sliders.home7 {
    margin-bottom: 105px;

    @media(max-width: 576px) {
        margin-bottom: 50px;
    }

    .single-slide {
        position: relative;
        padding: 20px 0;

        .slider-content {
            position: relative;
            transform: none;
            z-index: 1;

            .content-part {
                .slider-title {
                    font-size: 80px;
                    width: 700px;
                    color: $heading-color;

                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        right: 65px;
                        width: 100%;
                        height: 90px;
                        background: $whiteColor;
                        z-index: -1;
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        top: 70px;
                        right: 170px;
                        width: 70%;
                        height: 100px;
                        background: $whiteColor;
                        z-index: -1;
                    }
                }

                .slider-btn {
                    right: 40% !important;

                    .home-seven-btn {
                        background: $homeseven-primaryColor;
                        border: none;
                        padding: 20px 40px;
                        border-radius: 50px;

                        a {
                            color: $whiteColor;
                            font-size: 20px;
                        }
                    }
                }

                .event__video-btn--play-six {
                    top: 55%;
                    right: 25%;
                }

                .event__video-btn--play-btn {
                    background: transparent;
                    border: 1px solid #e2e2e2;

                    &::before {
                        background: rgb(241, 241, 241);
                        top: -53% !important;
                        left: -53% !important;
                    }

                    .video-icon {
                        max-width: 15px;
                    }

                    em {
                        color: #999999;
                        font-size: 20px;
                        font-weight: 700;
                        margin-top: 15px;
                    }
                }
            }

            .slide-info {
                margin-top: 100px;
                font-size: 40px;
                color: $heading-color;
                font-weight: 300;
                position: relative;
                max-width: 200px;

                &::before {
                    content: '';
                    position: absolute;
                    top: 35px;
                    right: -0px;
                    width: 80px;
                    height: 1px;
                    background: $heading-color;
                }
            }
        }

        .slider-hero {
            padding: 370px 0;

            .hero-picture {
                position: absolute;
                top: 0;
                right: 0;
            }
        }
    }

    .owl-nav {
        width: 150px;
        top: 72%;
        left: unset !important;
        right: 63%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        button {
            position: relative;
            width: 50px;
            height: 50px;
            left: unset !important;
            right: unset !important;
            display: grid;
            place-items: center;
            aspect-ratio: 1;
            background: $whiteColor  !important;
            border-radius: 50%;

            &::before {
                color: $heading-color;
                font-size: 40px !important;
                font-weight: 100 !important;
            }
        }
    }

    .owl-prev {
        &::after {
            content: '';
            position: absolute;
            top: 12px;
            left: 146%;
            width: 2px;
            height: 30px;
            background: $heading-color;
        }
    }

    @media(max-width: 1666px) {
        .event__video-btn--play-btn {
            em {
                display: none;
            }
        }

        .owl-nav {
            button {
                box-shadow: 5px 5px 12px -6px rgba(0, 0, 0, 0.29);
            }
        }
    }


    @media(max-width: 1400px) {
        .event__video-btn--play-six {
            top: 90% !important;
            right: 0% !important;
        }

        .owl-nav {
            top: 75%;
            left: 50% !important;
            right: unset !important;
            transform: translateX(-50%) !important;
        }

        .slide-info {
            display: none;
        }
    }

    @media(max-width: 1200px) {
        .content-part {
            padding: 20px;
            background: white;

            .slider-title {
                line-height: 73px;

                &::before {
                    right: 50px !important;
                }

                &::after {
                    right: 150px !important;
                }
            }
        }

        .slider-content {
            position: absolute !important;
            top: 50% !important;
            left: 50% !important;
            transform: translate(-50%, -50%) !important;
        }

        .event__video-btn--play-six {
            top: 83% !important;
        }

        .slider-content {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, 50%);
        }

        .event__video-btn--play-six {
            top: 83% !important;
        }

        .content-part {
            .slider-title {
                &::before {
                    display: none;
                }

                &::after {
                    display: none;
                }
            }
        }
    }

    @media(max-width: 992px) {
        .react-slider-row-seven {
            flex-direction: column-reverse;
        }

        .slider-content {
            position: relative !important;
            top: inherit !important;
            left: inherit !important;
            transform: translate(0) !important;

            .content-part {
                padding: 0;
            }
        }

        .owl-nav {
            top: auto;
            bottom: 20px;
        }

        .slider-hero {
            padding: 0 !important;
            margin-bottom: 40px;

            .hero-picture {
                position: relative !important;
            }
        }

        .navigation-spacer {
            width: 100%;
            height: 100px;
        }

        .owl-prev {
            &::after {
                left: 151%;
            }
        }
    }

    @media(max-width: 768px) {
        .content-part {
            .slider-title {
                font-size: 50px !important;
                line-height: 1.2;
                max-width: 400px;
            }
        }

        .slider-hero {
            margin-bottom: 25px;
        }
    }

    @media(max-width: 588px) {
        .event__video-btn--play-six {
            top: 83% !important;
            left: unset !important;
        }

        .content-part {
            .slider-title {
                font-size: 40px !important;
                max-width: 320px;
            }
        }
    }

    @media(max-width: 350px) {
        .content-part {
            .slider-title {
                font-size: 35px !important;
                max-width: 320px;
            }
        }
    }
}


.section-title-area-seven {
    .section-pretitle {
        color: $heading-color;
        font-size: 20px;
        font-weight: 600;
        line-height: 15px !important;
        margin-bottom: 20px;
        position: relative;
        padding-left: 60px;
        margin-bottom: 40px;

        &::before {
            content: '';
            position: absolute;
            top: 7px;
            left: 0;
            width: 40px;
            height: 2px;
            background: $homeseven-primaryColor;
        }
    }

    .section-title {
        color: $heading-color;
        font-size: 50px;
        font-weight: 700;
        line-height: 60px;
        transform: translateY(-10px);
    }

    @media(max-width: 1200px) {
        .section-pretitle {
            font-size: 20px;
        }

        .section-title {
            font-size: 40px;
        }
    }

    @media(max-width: 576px) {
        margin-bottom: 30px !important;

        .section-pretitle {
            font-size: 16px;
            line-height: 0 !important;
        }

        .section-title {
            font-size: 30px;
            line-height: 40px;
            transform: translateY(-20px);
        }
    }
}

.about-section-seven {
    margin-bottom: -25px;

    .section-title-area-seven {
        margin-bottom: 80px;
    }

    .about-card {
        background: $whiteColor;
        border: 1px solid #f0f0f0;
        border-radius: 10px;
        padding: 40px;
        display: flex;
        margin-bottom: 25px;

        &:hover {
            .card-icon {
                animation: flip 1s ease;
            }
        }

        .card-icon {
            width: 260px;
            aspect-ratio: 1;
            margin-right: 30px;

            img {
                width: 100%;
            }
        }

        .content {
            .card-title {
                color: $heading-color;
                font-size: 22px;
                font-weight: 600;
            }

            p {
                color: #777777;
                margin-bottom: 0;
            }
        }
    }

    @media(max-width: 1200px) {
        .about-card {
            .card-icon {
                max-width: 100px !important;
                display: flex;
                align-items: center;
            }
        }
    }

    @media(max-width: 576px) {
        .about-card {
            flex-direction: column;
            align-items: center;
            text-align: center;

            p {
                max-width: 400px;
            }

            .card-icon {
                margin-right: 0;
                margin-bottom: 20px;
            }
        }
    }
}

.qna-section-seven {
    .section-title-area-seven {
        margin-bottom: 40px;

        .section-pretitle {
            position: relative;
            padding-left: 60px;

            &::before {
                content: '';
                position: absolute;
                top: 7px;
                left: 0;
                width: 40px;
                height: 2px;
                background: $homeseven-primaryColor;
            }
        }
    }

    .qna-dropdown-item {
        margin-bottom: 20px;

        .question {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            .question-title {
                color: $heading-color;
                font-size: 20px;
                font-weight: 600;
                cursor: pointer;
            }

            .dropdown-icon {
                background: #ffd6d6;
                width: 20px;
                height: 20px;
                display: grid;
                place-items: center;
                border-radius: 3px;
                margin-right: 30px;

                &::before {
                    content: "+";
                    color: #ff4444;
                    transform: translateY(-7px);
                    font-size: 19px;
                    font-weight: 600;
                    cursor: pointer;
                }
            }
        }

        .answer {
            height: 0;
            overflow: hidden;
            transition: height 300ms;

            p {
                margin-bottom: 0;
                color: #777777;
                max-width: 550px;
            }
        }

        &.show {
            .dropdown-icon {
                background: #e2f8f9;

                &::before {
                    content: "-";
                    font-size: 30px;
                    color: #3ccbde;
                    transform: translateY(-18px);
                }
            }

            .question-title {
                color: $homeseven-primaryColor;
            }

            .answer {
                height: 60px;
            }
        }
    }

    @media(max-width: 1400px) {
        .qna-picture {
            margin-right: 40px;
        }
    }

    @media(max-width: 1200px) {
        .qna-picture {
            margin-right: 0;
            margin-bottom: 40px;

            img {
                width: 100%;
            }
        }
    }
}

.courses-section-seven {
    .filters {
        margin-bottom: 70px;
    }

    .filter-group {
        width: 100%;
        display: flex;
        justify-content: center;

        li {
            padding: 10px 25px;
            background: $whiteColor;
            color: #777777;
            border-radius: 4px;
            margin-right: 40px;
            cursor: pointer;
            transition: background 300ms;

            &:last-child {
                margin-right: 0;
            }

            &.active {
                background: $homeseven-primaryColor;
                color: $whiteColor;
            }
        }
    }

    .content {
        margin-bottom: -40px;
    }

    .single-content {
        width: 33.3333%;
        display: flex;
        justify-content: center;
    }

    .courses-card {
        max-width: 400px;
        position: relative;
        background: $whiteColor;
        border-radius: 10px;
        margin-bottom: 40px;

        .card-image {
            img {
                width: 100%;
            }
        }

        .card-tag {
            position: absolute;
            background: $homeseven-primaryColor;
            color: $whiteColor;
            padding: 5px 20px;
            border-radius: 5px;
            top: 55%;
            right: 30px;
        }

        .card-contents {
            padding: 30px;
        }

        .card-value {
            margin-bottom: 20px;
        }

        .courses-rating-seven {
            .star {
                span {
                    margin-left: 20px;
                    color: #777777;
                }
            }
        }

        .price {
            color: $homeseven-primaryColor;
            font-weight: 600;
        }

        .card-title {
            a {
                color: $heading-color;
                font-size: 22px;
                font-weight: 600;

                &:hover {
                    color: $homeseven-primaryColor;
                }
            }
        }
    }

    .courses-rating-seven {
        i {
            color: #ff912c;
        }

        .blank-star {
            color: #e5e5e5;
        }
    }

    @media(max-width: 1400px) {
        .courses-card {
            max-width: 350px;
            margin-bottom: 22px;

            .card-tag {
                top: 45%;
            }

            .card-contents {
                .card-title {
                    a {
                        font-size: 20px;
                    }
                }
            }
        }
    }

    @media(max-width: 1200px) {
        .courses-card {
            max-width: 290px;
            margin-bottom: 23px;

            .card-contents {
                .card-title {
                    a {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    @media(max-width: 992px) {
        .single-content {
            width: 100% !important;
        }

        .courses-card {
            max-width: 100%;
            width: 100%;
            margin-bottom: 40px;

            .card-tag {
                top: unset;
                bottom: 20%;
            }

            .card-contents {
                .card-title {
                    a {
                        font-size: 16px;
                    }
                }
            }
        }

        .filter-group {
            li {
                font-size: 16px;
                padding: 7px 10px;
            }
        }
    }

    @media(max-width: 769px) {
        .filters {
            margin-bottom: 40px;
        }

        .filter-group {
            flex-wrap: wrap;
            margin-bottom: -15px;

            li {
                font-size: 16px;
                padding: 7px 10px;
                margin-right: 15px;
                margin-bottom: 15px;
            }
        }

        .card-tag {
            bottom: 40% !important;
        }
    }
}


.counter-section-seven {
    .counter-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .count {
            border: 1px solid #f1f1f1;
            border-radius: 50%;
            width: 150px;
            aspect-ratio: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;
        }

        span {
            color: $heading-color;
            font-size: 40px;
            font-weight: 600;
        }

        .counter-title {
            text-align: center;
            color: #777777;
            font-size: 18px;
            font-weight: 500;
        }
    }

    @media(max-width: 1200px) {
        margin-bottom: -40px;

        .counter-item {
            margin-bottom: 40px;
        }
    }
}


.overview-section-seven {
    position: relative;
    margin-bottom: -40px;

    .contents {
        max-width: 550px;

        .section-title {
            margin-bottom: 40px;
        }

        p {
            color: #777777;
            margin-bottom: 70px;
        }
    }

    .overview-pictures {
        .image-row {
            column-count: 2;
            column-gap: 40px;
        }

        .image-item {
            width: 100%;
            height: auto;
            margin-bottom: 40px;
        }
    }

    @media(max-width: 1200px) {
        .contents {
            margin-bottom: 50px;
        }

        .overview-pictures {
            .image-item {
                img {
                    width: 100%;
                }
            }
        }
    }

    @media(max-width: 769px) {
        .overview-pictures {
            .image-row {
                column-gap: 20px;
            }

            .image-item {
                margin-bottom: 20px;
            }
        }
    }

    @media(max-width: 576px) {
        margin-bottom: -20px !important;
    }
}

.testimonials-section-six.home-seven {
    .testimonials-slider-navigation {
        i {
            &:hover {
                color: $homeseven-primaryColor  !important;
            }
        }
    }
}


.blog-section-seven {
    .blog-picture {
        margin-bottom: 20px;
        overflow: hidden;

        img {
            width: 100%;
            transition: transform 300ms;

            &:hover {
                transform: scale(110%);
            }
        }
    }

    .tag {
        position: absolute;
        top: 20px;
        right: 20px;
        background: $homeseven-primaryColor;
        padding: 5px 15px;
        color: $whiteColor;
        font-size: 18px;
        font-weight: 500;
        border-radius: 3px;
    }

    .blog-date {
        color: $titleColor6;
        font-size: 16px;
        margin-bottom: 7px;
    }

    .blog-title {
        a {
            color: $titleColor6;
            font-size: 22px;
            font-weight: 600;
            line-height: 40px;

            &:hover {
                color: $homeseven-primaryColor;
            }
        }

        margin-bottom: 15px;
        max-width: 550px;
    }

    .read-more {
        color: $homesix-textColor;
        font-size: 16px;

        i {
            font-size: 12px;
            margin-left: 5px;
        }

        &:hover {
            color: $homesix-primaryColor;

            i {
                color: $homesix-primaryColor;
            }
        }
    }
}



.hero4__area {
    overflow: hidden;
    position: relative;
    background: url(../images/hero/01.png) no-repeat center top;
    background-size: cover;

    .container.hero4__width {
        max-width: 1200px;

        .row {
            align-items: center;
        }
    }

    .hero4__content span {
        font-size: 24px;
        font-weight: 700;
        color: #fd4680;
    }

    .hero4__content h1 {
        font-size: 70px;
        line-height: 74px;
        font-weight: 700;
        color: #ffffff;
        padding-bottom: 0;
        margin: 0 0 10px;
    }

    .event__video-btn--plays {
        position: relative;

        .event__videos {
            position: relative;
            top: 7px;
        }

        i {
            width: 50px;
            height: 50px;
            line-height: 50px;
            color: #fff;
            border: 2px solid rgba(255, 255, 255, 0.2);
            display: inline-block;
            text-align: center;
            border-radius: 50px;
            font-size: 28px;
            transition: all .5s ease;

            &:hover {
                border-color: $primaryColor;
            }
        }

        em {
            color: $whiteColor;
            line-height: 25px;
            font-weight: 600;
            font-size: 16px;
            text-align: left;
            margin-left: 10px;
            font-style: normal;
            position: relative;
            top: -9px;
            transition: all .5s ease;
        }

        .event__videos {
            &:hover {
                color: $primaryColor;

                i {
                    border-color: $primaryColor;
                    color: $primaryColor;
                }

                em {
                    color: $primaryColor;
                }
            }
        }
    }

    .hero4__content p {
        font-size: 18px;
        line-height: 28px;
        font-weight: 400;
        color: #ffffff;
        padding-bottom: 16px;
    }

    .hero4__content .hero4-btn {
        font-size: 16px;
        font-weight: 600;
        border-radius: 6px;
        background: #fff;
        padding: 14px 35px 14px 35px;
        color: #0F1216;
        display: inline-block;
        margin-top: 0px;
        line-height: 20px;
        margin-right: 25px;

        &:hover {
            background: $primaryColor;
            color: #fff;
        }
    }

    .hero4__image {
        padding-top: 90px;
        padding-left: 56px;
    }

    .hero4__image-1 {
        position: relative;
        z-index: 1;
    }

    .hero4__image-2 {
        position: absolute;
        top: 50%;
        right: 17.4%;
        -webkit-animation: hero3-image 3s linear infinite alternate;
        -moz-animation: hero3-image 3s linear infinite alternate;
        -o-animation: hero3-image 3s linear infinite alternate;
        animation: hero3-image 3s linear infinite alternate;
    }

    .hero4__image-course {
        border-radius: 50%;
        position: absolute;
        box-shadow: 0px 30px 40px 0px rgba(122, 0, 39, 0.2);
        background-color: #fd467f;
        top: 69.5%;
        right: 71.5%;
        width: 150px;
        height: 150px;
        -webkit-animation: hero3-image 3s linear infinite alternate;
        -moz-animation: hero3-image 3s linear infinite alternate;
        -o-animation: hero3-image 3s linear infinite alternate;
        animation: hero3-image 3s linear infinite alternate;
    }

    .hero4__shape img {
        position: absolute;
        z-index: 1;
    }

    .hero4__shape-1 {
        bottom: 60%;
        left: 13%;
        -webkit-animation: hero-leaf 3s linear infinite alternate;
        -moz-animation: hero-leaf 3s linear infinite alternate;
        -o-animation: hero-leaf 3s linear infinite alternate;
        animation: hero-leaf 3s linear infinite alternate;
    }

    .hero4__shape-2 {
        top: 50%;
        left: 0%;
        -webkit-animation: hero-leaf-2 3s linear infinite alternate;
        -moz-animation: hero-leaf-2 3s linear infinite alternate;
        -o-animation: hero-leaf-2 3s linear infinite alternate;
        animation: hero-leaf-2 3s linear infinite alternate;
    }

    .hero4__shape-3 {
        top: 53%;
        left: 54%;
        -webkit-animation: hero-leaf-2 3s linear infinite alternate;
        -moz-animation: hero-leaf-2 3s linear infinite alternate;
        -o-animation: hero-leaf-2 3s linear infinite alternate;
        animation: hero-leaf-2 3s linear infinite alternate;
    }

    .hero4__shape-4 {
        top: 38%;
        right: 13%;
        -webkit-animation: hero-leaf-2 3s linear infinite alternate;
        -moz-animation: hero-leaf-2 3s linear infinite alternate;
        -o-animation: hero-leaf-2 3s linear infinite alternate;
        animation: hero-leaf-2 3s linear infinite alternate;
    }

    .hero4__shape-5 {
        top: 76%;
        right: 12%;
        -webkit-animation: hero-leaf-2 3s linear infinite alternate;
        -moz-animation: hero-leaf-2 3s linear infinite alternate;
        -o-animation: hero-leaf-2 3s linear infinite alternate;
        animation: hero-leaf-2 3s linear infinite alternate;
    }

    @keyframes hero-leaf {
        0% {
            transform: translateY(-50px);
        }

        100% {
            transform: translateY(0px);
        }
    }

    @keyframes hero-leaf-2 {
        0% {
            transform: translateY(0px);
        }

        100% {
            transform: translateY(-30px);
        }
    }

    @keyframes hero3-image {
        0% {
            transform: translateY(-20px);
        }

        100% {
            transform: translateY(0px);
        }
    }

    @keyframes hero-leaf-4 {
        0% {
            transform: translateX(-30px);
        }

        100% {
            transform: translateX(0px);
        }
    }
    @media(max-width: 360px){
        .hero4__title{
            font-size: 35px !important;
            line-height: 40px !important;
        }
        .event__video-btn--plays{
            em{
                display: none;
            }
        }
    }
}


/*****************************************************
    05. Course Filter CSS Here 
****************************/

.react-course-filter {
    background: #F5F5F5;

    &.related__course {
        background: none;
        padding-top: 90px;

        h3 {
            font-weight: 700;
            color: #0F1216;
            font-size: 20px;
            padding-bottom: 10px;
        }

        .single-studies .inner-course {
            box-shadow: 0px 1px 2px rgba(15, 18, 22, 0.1);
            border-radius: 6px;
            background: #fff;
        }
    }

    .react-filter {
        display: flex;
        align-items: center;
        justify-content: end;
        flex-wrap: wrap;
        margin: -8px 0;
        padding-bottom: 65px;
        .react-tabs__tab {
            margin: 4px 8px;
            button {
                border: none;
                color: #5B5F64;
                padding: 4px 30px 6px;
                border-radius: 6px;
                font-weight: 400;
                transition: $transition;
                background: $whiteColor;
                box-shadow: 0px 1px 2px rgba(0, 14, 32, 0.1);
                outline: none;
                &:hover {
                    background: #3270FC;
                    color: $whiteColor;
                }
            }
            &.react-tabs__tab--selected {
                button {
                    background: #3270FC;
                    color: $whiteColor;
                }
            }
        }
        
    }

    .react__title__section {
        h2 {
            font-size: 44px;
            line-height: 56px;
            color: #0F1216;
        }

        h6 {
            font-size: 18px;
            color: #5B5F64;
            line-height: 22px;
            font-weight: 400;
            margin-top: 15px;
            display: block;
        }
    }

    .single-studies {
        margin-bottom: 25px;

        .inner-course {
            background: $whiteColor;
            border-radius: 10px;

            .case-img {
                position: relative;

                a {
                    font-size: 14px;
                    line-height: 17px;
                    font-weight: 600;
                    background: $primaryColor;
                    display: inline-block;
                    padding: 4px 9px 7px;
                    color: #fff;
                    border-radius: 4px;
                    position: absolute;
                    right: 22px;
                    top: 20px;
                    img {
                        margin-right: 5px;
                    }
                }
            }

            .case-content {
                padding: 30px 38px 20px 35px;

                .meta-course {
                    li {}
                }

                .case-title {
                    margin: 6px 0 30px;
                    font-size: 20px;
                    font-weight: 600;

                    a {
                        color: #0F1216;

                        &:hover {
                            color: #3270FC;
                        }
                    }
                }

                .react__user {
                    font-size: 16px;
                    color: #5B5F64;
                    font-weight: 400;

                    img {
                        margin-right: 12px;
                        height: 30px;
                    }
                }

                .react-ratings {
                    border-top: 1px solid #eee;
                    margin-top: 40px;
                    padding-top: 14px;
                    justify-content: space-between;

                    li:last-child {
                        padding-right: 0;
                    }

                    li.price {
                        color: #0F1216;
                        font-size: 20px;
                        font-weight: 600;
                    }
                }

                ul {
                    display: flex;

                    li {
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        color: #5B5F64;
                        font-weight: 400;
                        padding-right: 25px;

                        svg {
                            margin-right: 10px;
                            width: 20px;

                            &.feather-star {
                                color: #FFA439;
                            }
                        }
                    }
                }
            }
        }
    }
}

.owl-controls {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    width: 1170px;

    .owl-dots {
        position: absolute;
        bottom: 0;
        left: 0;

        .owl-dot {
            display: inline-block;
            margin-right: 10px;

            span {
                display: inline-block;
                height: 15px;
                width: 15px;
                background: transparent;
                border: 2px solid rgba(255, 255, 255, .2);
                border-radius: 100%;
                transition: $transition;
            }

            &.active {
                span {
                    background: $whiteColor;
                }
            }

            &:last-child {
                border-right: none;
            }
        }
    }

    .owl-nav {
        display: none;
    }
}


/*****************************************************
    06. Slider Section CSS Here  
****************************/
.home-sliders {
    position: relative;

    .slider-content {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        .slider-title {
            font-size: 70px;
            margin-bottom: 15px;
            color: $whiteColor;
            line-height: 74px;
            font-weight: 700;
            display: block;
            margin-top: 20px;
        }

        .slider-pretitle {
            padding: 3px 15px 8px;
            font-size: 26px;
            color: $whiteColor;
            margin-bottom: 22px;
            font-weight: 400;
            line-height: 32px;
            background: #D2093C;
        }

        .slider-desc {
            font-size: 18px;
            color: $whiteColor;
        }

        .slider-btn {
            margin-top: 32px;

            .react-btn-border {
                background: none;
                color: $whiteColor;

                &:hover {
                    background: #D2093C;
                    color: $whiteColor;
                    border-color: #D2093C;
                }
            }
        }
    }

    .event__video-btn--play-btn {
        color: #00306e;
    }

    .owl-nav {
        position: absolute; 
        display: block;
        top: 43%;
        right: unset;
        width: 100%;
        opacity: 1;
        transition: $transition;

        [class*="owl-"] {
            position: absolute;
            left: 20px;
            display: inline-block;
            font-size: 0;
            width: 70px;
            height: 70px;
            line-height: 70px;
            color: $whiteColor;
            background: transparent;
            border: 2px solid rgba(245, 245, 245, .08);
            border-radius: 100%;
            transition: $transition;

            &:before {
                content: "\35";
                font-family: ElegantIcons;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                font-size: 45px;
                font-weight: 400;
                color: rgba(255, 255, 255, .48);
                line-height: 28px;
            }

            &.owl-next {
                left: auto;
                right: 70px;

                span {
                    font-size: 0;
                }
            }

            &.owl-prev {
                left: 70px;
                right: auto;

                &:before {
                    content: '\34';
                }

                span {
                    font-size: 0;
                }
            }

            &:hover {
                color: $secondaryColor;
                background: $whiteColor;
                border-color: $whiteColor;

                &:before {
                    color: $primaryColor;
                }
            }
        }
    }

    .event__video-btn--play {
        right: 28%;
        left: auto;

        em {
            position: absolute;
            width: 250px;
            color: $whiteColor;
            margin-left: 50px;
            line-height: 25px;
            font-weight: 700;
            font-size: 20px;
            text-align: left;
            margin-top: 20px;
            font-style: normal;
        }
    }


    .ellipse {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 100;

        img {
            width: 100%;
        }
    }

    @media(max-width: 360px) {
        .slider-title {
            font-size: 27px !important;
            line-height: 32px !important;
        }
    }
}

.home_four-section {
    .section-title {
        position: absolute;
        top: 70px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 120px;
        color: #eef2f8;
        font-weight: 700;
    }

    .sub-title {
        text-align: center;
        color: #252525;
        font-size: 43px;
        font-weight: 600;
        transform: translateY(-33px);
        margin-top: 70px;
        margin-bottom: 45px;
    }

    @media(max-width: 1200px) {
        .section-title {
            font-size: 80px;
        }

        .sub-title {
            font-size: 30px;
            transform: translateY(-56px) !important;
        }
    }

    @media(max-width: 574px) {
        .section-title {
            font-size: 50px;
        }

        .sub-title {
            font-size: 20px;
            transform: translateY(-33px) !important;
        }
    }
}

.social-links.home-four-social-links {
    position: absolute !important;
    top: 50%;
    left: -170px;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    z-index: 2;

    a {
        line-height: 47px;
    }

    span {
        color: rgb(163, 163, 163);
        font-size: 14px;

        &::before {}
    }

    a {
        border: none !important;
    }

    @media(max-width:1680px) {
        left: 0;
        flex-direction: row !important;
        top: 400px;
    }
}

.home-four-slider-part {
    position: relative;

    .slider-content {
        margin-top: 40px;
    }

    .hero {
        margin-top: 100px;
    }
}

.home-four-sliders {
    .single-slide {
        position: relative;
        padding: 400px 0;
        background: #000;
        background-image: url("../images/home4/backdrop.png") !important;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        @media(max-width: 1200px) {
            padding: 100px 0px;
        }
    }

    .slider-content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media(max-width: 1200px) {
            position: relative;
            flex-direction: column !important;
            transform: inherit;
            width: 100%;

            .hero {
                margin-top: 150px !important;
            }

            .hero.hero2 {
                max-width: 600px;
                transform: translateY(0);
            }

        }
    }

    .hero {
        max-width: 600px;
        bottom: 40px;
        right: 15%;

        img {
            width: 100%;
        }
    }

    .hero.hero2 {
        max-width: 700px;
        transform: translateY(30px);
    }

    .ellipse {
        position: absolute;
        bottom: -1px;
        left: 50%;
        transform: translateX(-50%);

        i {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, 50%);
            animation: upDown 2s infinite ease-in-out;
            cursor: pointer;
        }

        i::before {
            content: '\22' !important;
            font-size: 28px;
            color: #252525;
        }
    }

    .arrow_down .slider-home-four {
        background: #000 !important;
    }

    .content-part {
        position: relative;

        .slider-pretitle {
            background: none;
            font-weight: 200;
            font-size: 30px;
            letter-spacing: 10px;
            padding: 0 !important;
        }

        .slider-title {
            font-weight: 600;
            margin-bottom: 30px;
        }

        p {
            color: $paragraphColor  !important;
            font-size: 14px;
            max-width: 400px;
            line-height: 30px !important;
        }

        .slider-btn {
            background: $primaryColor;
            color: $whiteColor;
            font-size: 16px;
            padding: 15px 40px;
            border: none;
            border-radius: 50px;
            transition: background 500ms;

            &:hover {
                background: #4d82ff;
            }
        }
    }

    .owl-nav {
        position: absolute;
        right: 0;
        width: 300px;
        transform: rotate(90deg);

        .owl-prev {
            margin-left: -100px !important;

            &::before {
                content: '\23' !important;
                font-size: 25px;
                color: #999999;
            }

            &:hover {
                background: none !important;

                &::before {
                    color: #999999;
                }
            }
        }

        .owl-next {
            margin-right: -100px !important;

            &::before {
                content: '\24' !important;
                font-size: 25px;
                color: #999999;
            }

            &:hover {
                background: none !important;

                &::before {
                    color: #999999;
                }
            }
        }

        &::before {
            content: '';
            position: absolute;
            top: 33px;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 40px;
            height: 1px;
            background: #999999;
        }

        @media(max-width: 1630px) {
            right: -50px;
        }

        @media(max-width: 1490px) {
            right: -100px;
        }

        @media(max-width: 1250px) {
            display: none;
        }
    }

    @media(max-width: 1400px) {
        .content-part {
            .slider-title {
                font-size: 50px;
            }

            .slider-pretitle {
                font-size: 20px !important;
            }
        }
    }

    @media(max-width: 1200px) {
        .content-part {
            .slider-title {
                font-size: 70px !important;
            }
        }
    }

    @media(max-width: 991px) {
        .content-part {
            .slider-title {
                font-size: 60px !important;
            }
        }
    }

    @media(max-width: 769px) {
        .content-part {
            .slider-title {
                font-size: 45px !important;
            }

            .slider-pretitle {
                font-size: 17px !important;
            }
        }
    }

    @media(max-width: 510px) {
        .content-part {
            .slider-title {
                font-size: 30px !important;
            }

            .slider-pretitle {
                font-size: 15px !important;
            }
        }
    }
}


.home_four-section {
    position: relative;
}

.languegeSlider {
    overflow: hidden !important;
}


.langueges-section {
    background: #f5f5f5;

    .lang-item {
        img {
            width: 1005;
        }
    }

    @media(max-width: 991px) {
        padding: 50px 0 !important;

        .lang-item {
            max-width: 60px;
        }
    }

    @media(max-width: 550px) {
        padding: 40px 0 !important;

        .lang-item {
            max-width: 50px;
        }
    }
}


.catagories-section {
    position: relative;

    .catagory-item {
        position: relative;
        margin-bottom: 35px;

        .catagory-picture {
            display: flex;
            justify-content: center;
            align-items: center;
            background: #f5f5f5;
            padding: 45px 90px;
            margin-bottom: 10px;
            max-width: 100%;
            aspect-ratio: 1;

            img {
                max-width: 100%;
                aspect-ratio: 1;
            }
        }

        .content {
            position: relative;
            display: flex;
            align-items: center;

            .item-title {
                position: relative;
                font-size: 21px;
                font-weight: 600;
                margin-right: 60px;

                a {
                    color: #252525;

                    &:hover {
                        color: $primaryColor  !important;
                    }
                }

                &::before {
                    content: '';
                    position: absolute;
                    top: 20px;
                    right: -40px;
                    width: 25px;
                    height: 1px;
                    background: #3772ff;
                }
            }

            .info {
                font-size: 13px;
                color: #999999;
                line-height: 0 !important;
                transform: translateY(3px);
            }
        }
    }

    @media(max-width: 769px) {
        .catagory-item {
            max-width: 100%;
        }
    }
}

.course-section {
    background: #262930;

    .course-inner {
        display: flex;
        justify-content: start;
        align-items: center;

        .course-hero {
            position: relative;

            .tutor-picture {
                max-width: 600px;
                width: 100%;

                img {
                    width: 100%;
                    height: auto;
                }
            }

            .phone-picture {
                max-width: 320px;
                position: absolute;
                top: 100px;
                right: -160px;

                img {
                    width: 100%;
                }
            }
        }

        .course-contents {
            margin: 0 auto;
            padding: 100px 0;

            .course-contents-inner {
                display: flex;
                flex-direction: column;
                justify-content: start;
            }

            .course-sub-title {
                color: #fff;
                font-size: 15px;
                font-weight: 300;
                margin-bottom: 10px;
            }

            .course-section-title {
                color: #fff;
                font-size: 45px;
                font-weight: 600;
                max-width: 600px;
                line-height: 60px;
                margin-bottom: 60px;
            }

            .course-item {
                display: flex;
                align-items: center;
                margin-bottom: 40px;

                &:last-child {
                    margin-bottom: 0;
                }

                .course-item-icon {
                    width: 80px;
                    margin-right: 40px;

                    img {
                        max-width: 100%;
                    }
                }

                .course-item-title {
                    color: #fff;
                    font-size: 20px;
                }

                p {
                    color: #999999;
                    font-size: 15px;
                    max-width: 500px;
                }
            }
        }
    }

    @media(max-width: 1650px) {
        .phone-picture {
            max-width: 300px !important;
            right: -40px !important;
        }
    }

    @media(max-width: 1420px) {
        .course-inner {
            .course-hero {
                .tutor-picture {
                    max-width: 500px;
                }

                .phone-picture {
                    max-width: 260px !important;
                    right: 0px !important;
                }
            }

            .course-contents {
                padding: 50px 50px !important;

                .section-title {
                    font-size: 30px;
                    max-width: 400px;
                    line-height: inherit;
                }

                .course-item-icon {
                    max-width: 60px;
                    margin-right: 25px !important;
                }

                .course-item {
                    margin-bottom: 25px;

                    .course-item-title {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    @media(max-width: 1130px) {
        .course-inner {
            .course-contents {
                padding: 20px !important;
            }
        }
    }

    @media(max-width: 975px) {
        .course-inner {
            flex-direction: column;

            .tutor-picture {
                max-width: 100vw !important;
                width: 100vw !important;
            }

            .course-contents {
                padding: 50px !important;
            }
        }
    }

    @media(max-width: 450px) {
        .course-inner {
            flex-direction: column;

            .tutor-picture {
                max-width: 100vw !important;
                width: 100vw !important;
            }

            .phone-picture {
                width: 170px !important;
            }

            .course-contents {
                .section-title {
                    font-size: 25px !important;
                    margin-bottom: 50px;
                    max-width: 320px !important;
                }

                .course-item {
                    flex-direction: column !important;
                    align-items: flex-start;

                    .course-item-icon {
                        margin-bottom: 15px;
                    }
                }
            }
        }
    }
}

.trending-section {
    position: relative;
    margin-bottom: -139px !important;

    @media(max-width: 1690px) {
        padding-bottom: 230px !important;
    }

    @media(max-width: 1200px) {
        padding-bottom: 215px !important;
    }

    @media(max-width: 580px) {
        padding-bottom: 232px !important;
    }

    .slider-navigation {
        margin-top: 30px;
    }


    .trending-card {
        position: relative;
        box-shadow: 0px 0px 30px 0px rgba(50, 112, 252, 0.1);
        margin-bottom: 40px;
        overflow: hidden !important;

        .trending-card-picture {
            transition: 300ms;
            width: 100%;

            img {
                width: 100%;
            }
        }

        .time {
            position: absolute;
            bottom: 130px;
            right: 20px;
            background: white;
            box-shadow: 0px 0px 30px 0px rgba(50, 112, 252, 0.15);
            display: grid;
            place-items: center;
            width: 70px;
            aspect-ratio: 1;
            border-radius: 50%;
            font-weight: 600;
        }

        .content {
            display: flex;
            flex-direction: column;
            padding: 40px;

            .card-title {
                font-size: 26px;
                font-weight: 600;

                a {
                    color: #252525;

                    &:hover {
                        color: $primaryColor;
                    }
                }
            }

            .card-info {
                color: #3270fc;
                font-size: 20px;
                font-weight: 600;
            }
        }

        &:hover {
            .trending-card-picture {
                transform: scale(110%);
            }
        }
    }

    @media(max-width: 1200px) {
        .trending-card {
            .time {
                width: 50px;
                font-size: 14px;
            }

            .content {
                padding: 40px;

                .card-title {
                    font-size: 22px;
                }

                .card-info {
                    font-size: 16px;
                }
            }
        }

    }

    @media(max-width: 992px) {
        .trending-card {
            .time {
                width: 50px;
                font-size: 14px;
            }

            .content {
                padding: 40px;

                .card-title {
                    font-size: 22px;
                }

                .card-info {
                    font-size: 16px;
                }
            }
        }
    }
}



.instructor-section {
    .instructor-inner {
        background: #f5f5f5;
        padding: 70px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .content {
            .instructor-title {
                font-size: 56px;
                color: rgb(37, 37, 37);
                font-weight: 600;
                line-height: 1.161;

                color: #252525;
                font-size: 50px;
            }

            .instructor-info {
                color: rgb(119, 119, 119);
                font-size: 16px;
                max-width: 600px;
            }
        }

        .instructor-btn {
            background-color: $primaryColor;
            padding: 15px 30px;
            border-radius: 100px;
            color: white;
            font-size: 18px;
            border: none;
            transition: background 500ms;

            &:hover {
                background: #4d82ff;
            }
        }
    }

    @media(max-width: 1200px) {
        .instructor-inner {
            .content {
                .instructor-title {
                    font-size: 35px;
                }

                .instructor-info {
                    font-size: 15px;
                    max-width: 500px;
                }
            }

            .instructor-btn {
                padding: 10px 20px;
                font-size: 15px;
            }
        }
    }

    @media(max-width: 992px) {
        .instructor-inner {
            flex-direction: column;
            align-items: flex-start !important;
        }
    }

    @media(max-width: 530px) {
        .instructor-inner {
            padding: 20px;

            .instructor-btn {
                padding: 7px 14px;
                font-size: 13px;
            }
        }
    }
}

.slider-prev-btn {
    position: absolute;
    top: 55%;
    left: 110px;
    color: #999999;
    font-size: 16px;
    font-weight: 400;
    transform: rotate(270deg);

    i {
        color: #999999;
        margin-right: 10px;
    }

    &::before {
        content: '';
        position: absolute;
        right: -60px;
        top: 13px;
        width: 30px;
        height: 1px;
        background: #999999;
    }
}

.slider-next-btn {
    position: absolute;
    top: 50%;
    right: 110px;
    color: #999999;
    font-size: 16px;
    font-weight: 400;
    transform: rotate(270deg);

    i {
        color: #999999;
        margin-left: 10px;
    }

    &::before {
        content: '';
        position: absolute;
        left: -60px;
        top: 13px;
        width: 30px;
        height: 1px;
        background: #999999;
    }
}


@media(max-width: 1690px) {
    .slider-navigation {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 300px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .slider-next-btn,
    .slider-prev-btn {
        position: relative;
        transform: rotate(0deg);
    }

    .slider-next-btn {
        transform: translateX(100px);
    }

    .slider-prev-btn {
        transform: translateX(-100px);
    }
}

.students-section {
    background: #262930 !important;

    .students-inner {

        h1,
        p,
        span,
        .detail {
            color: white;
        }

        display: flex;
        justify-content: space-between;
        align-items: center;

        .contents {
            h1 {
                font-size: 56px;
                font-weight: 600;
                line-height: 50px;
            }

            p {
                font-size: 22px;
                line-height: 20px;
            }

            .detail {
                font-size: 14px;
                max-width: 400px;
                margin-bottom: 40px;
            }

            .followers {
                display: flex;

                .item {
                    font-size: 50px;
                    font-weight: 600;

                    p {
                        font-size: 18px;
                        font-weight: normal;
                        max-width: 200px;
                    }
                }

                .joined {
                    margin-right: 50px;
                }
            }
        }
    }

    @media(max-width: 1200px) {
        .students-inner {
            .contents {
                h1 {
                    font-size: 40px;
                }

                p {
                    font-size: 16px;
                }

                .detail {
                    font-size: 12px;
                }

                .followers {
                    .item {
                        font-size: 30px;

                        p {
                            font-size: 13px;
                        }
                    }
                }
            }
        }

        .map-image {
            max-width: 400px;
        }
    }

    @media(max-width: 769px) {
        .students-inner {
            .contents {
                margin-bottom: 40px;
            }

            flex-direction: column;
        }
    }

    @media(max-width: 530px) {
        .contents {
            .detail {
                max-width: 300px !important;
            }
        }
    }

}

.testimonials-style1 {
    @media(max-width: 1690px) {
        padding-bottom: 240px !important;
    }

    .slider-navigation {
        margin-top: 70px;
    }

    .testimonials-card {
        position: relative;
        max-width: 405px;
        padding: 50px;
        border: 1px solid #f5f5f5;
        background: white;

        .profile {
            display: flex;
            align-items: center;
            margin-bottom: 30px;

            .dp {
                margin-right: 20px;
            }

            .identity {
                .name {
                    color: #252525;
                    font-size: 24px;
                    font-weight: 600;
                    line-height: 35px;
                }

                .status {
                    color: #777777;
                    font-size: 16px;
                }
            }
        }

        .contents {
            p {
                color: #777777;
                font-size: 16px;
                margin-bottom: 20px;
            }
        }

        .testimonials-rating {
            i {
                color: #ff9000;

                &.blank-star {
                    color: #e5e5e5;
                }
            }
        }
    }

    @media(max-width: 1200px) {
        .testimonials-card {
            padding: 30px;

            .profile {
                display: flex;
                align-items: center;
                margin-bottom: 20px;

                .dp {
                    margin-right: 20px;
                }

                .identity {
                    .name {
                        color: #252525;
                        font-size: 18px;
                        font-weight: 600;
                        line-height: 30px;
                    }

                    .status {
                        color: #777777;
                        font-size: 14px;
                    }
                }
            }

            .contents {
                p {
                    color: #777777;
                    font-size: 14px;
                    margin-bottom: 20px;
                }
            }

            .testimonials-rating {
                i {
                    color: #ff9000;
                    font-size: 14px;
                }
            }
        }
    }

    @media(max-width: 576px) {
        padding-bottom: 120px !important;
    }
}


.events-section {
    background: #f5f5f5;

    .events-section-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 70px;

        .events-title {
            color: #252525;
            font-size: 56px;
            font-weight: 600;
        }

        .events-view-all {
            a {
                color: #777777;
                font-size: 20px;
                font-weight: 500;
            }

            i {
                color: #999999;
                font-size: 10px;
                margin-left: 5px;
            }
        }
    }

    .event-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 50px;
        margin-bottom: 50px;
        border-bottom: 1px solid #e5e5e5;

        .date {
            display: flex;
            flex-direction: column;

            .day {
                color: #252525;
                font-size: 65px;
                font-weight: 600;
                line-height: 30px;
                margin-bottom: 30px;
            }

            .date-span {
                color: #7f7f7f;
            }
        }

        .event-info {
            .event-title {
                color: #252525;
                font-size: 26px;
                font-weight: 600;
            }

            .event-location {
                color: #7f7f7f;
            }
        }

        .event-btn {
            background: $primaryColor;
            padding: 10px 40px;
            font-size: 20px;
            border-radius: 50px;
            border: none;
            transition: background 500ms;

            &:hover {
                background: #4d82ff;
            }

            a {
                color: white;
            }
        }

        &:last-child {
            margin-bottom: 0;
            border: none;
        }
    }

    @media(max-width: 1200px) {
        .events-section-title {
            .events-title {
                font-size: 35px;
            }
        }

        .date {
            .day {
                font-size: 40px !important;
                margin-bottom: 20px !important;
            }
        }

        .event-title {
            font-size: 18px !important;
        }

        .event-btn {
            padding: 5px 15px;
        }
    }

    @media(max-width: 768px) {
        .event-item {
            flex-direction: column;
        }

        .date {
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;

            .day {
                font-size: 65px;
                line-height: 20px;
                margin-bottom: 10px !important;
            }

            .date-span {
                color: #7f7f7f;
            }
        }

        .event-info {
            .event-title {
                font-size: 26px;
                margin-bottom: 20px;
            }

            .event-location {
                margin-bottom: 20px;
            }
        }

        .event-btn {
            background: $primaryColor;
            color: $whiteColor;
            padding: 10px 40px;
            font-size: 20px;
            border-radius: 50px;
            border: none;
        }

        &:last-child {
            margin-bottom: 0;
            border: none;
        }

    }
}


.blog-section {
    .maximized-part {
        .blog-picture {
            margin-bottom: 20px;
            overflow: hidden;

            img {
                max-width: 100%;
                transition: transform 300ms;

                &:hover {
                    transform: scale(110%);
                }
            }
        }

        .blog-date {
            color: #777777;
            font-size: 16px;
            margin-bottom: 7px;
        }

        .blog-title {
            a {
                color: #252525;
                font-size: 30px;
                font-weight: 600;
                line-height: 40px;

                &:hover {
                    color: $primaryColor;
                }
            }

            margin-bottom: 15px;
            max-width: 550px;
        }

        .read-more {
            color: #777777;
            font-size: 16px;

            i {
                font-size: 12px;
                margin-left: 5px;
            }
        }
    }

    .minimized-part {
        .blog-item {
            display: flex;
            justify-content: start;
            align-items: center;
            margin-bottom: 20px;

            .blog-picture {
                max-width: 230px;
                margin-right: 39px;
                overflow: hidden;

                img {
                    max-width: 100%;
                    transition: transform 300ms;

                    &:hover {
                        transform: scale(110%);
                    }
                }
            }

            .content {
                .blog-date {
                    color: #777777;
                    font-size: 16px;
                    margin-bottom: 10px;
                }

                .blog-title {
                    a {
                        color: #252525;
                        font-size: 24px;
                        font-weight: 600;
                        line-height: 35px;

                        &:hover {
                            color: $primaryColor;
                        }
                    }

                    margin-bottom: 10px;
                    max-width: 450px;
                }

                .read-more {
                    color: #777777;
                    font-size: 16px;
                    margin-bottom: 0 !important;

                    i {
                        font-size: 12px;
                        margin-left: 5px;
                    }
                }
            }
        }
    }

    .section-title {
        @media(max-width: 1725px){
            font-size: 100px;
        }
        @media(max-width: 1444px){
            font-size: 80px;
        }
        @media(max-width: 1200px){
            font-size: 70px;
        }
        @media(max-width: 992px){
            font-size: 50px;
        }
    }

    @media(max-width: 769px) {
        .maximized-part {
            margin-bottom: 40px;

            .blog-picture {
                margin-bottom: 20px;

                img {
                    max-width: 100%;
                }
            }

            .blog-title {
                margin-bottom: 20px;

                a {
                    font-size: 23px;
                    min-width: 320px;
                }
            }

            .blog-date {
                margin-bottom: 20px;
            }

            .read-more {
                font-size: 16px;
            }
        }

        .minimized-part {
            .content {
                .blog-picture {
                    margin-bottom: 20px;

                    img {
                        max-width: 100%;
                    }
                }

                .blog-title {
                    a {
                        font-size: 17px !important;
                    }
                }

                .blog-date {
                    margin-bottom: 20px;
                }

                .read-more {
                    font-size: 16px;
                }
            }
        }
    }

    @media(max-width: 358px) {
        .section-title {
            font-size: 30px;
        }
    }
}


.my-element {
    animation: bounce;
    /* referring directly to the animation's $keyframe declaration */
    animation-duration: 8s;
    /* don't forget to set a duration! */
}


.category__area {
    padding: 115px 0 125px 0;

    .container.category__width {
        max-width: 1030px;
        position: relative;

        &:before {
            content: "";
            position: absolute;
            width: 300px;
            height: 300px;
            bottom: -21px;
            left: 0;
            right: 0;
            background: #eef4fe;
            border-radius: 50%;
            margin: 0 auto;
        }

        .row {
            justify-content: center;
        }
    }

    .category__top-wrapper {
        padding-bottom: 38px;
    }

    .category__top-wrapper-1 {
        font-size: 16px;
        font-weight: 600;
        color: #3563cc;
    }

    .category__top-wrapper-2 {
        font-size: 40px;
        font-weight: 800;
        line-height: 50px;
        color: #020334;
    }

    .category__wrapper {
        max-width: 100%;
        padding: 8px 22px 8px 22px;
        border-radius: 6px;
        background-color: white;
        box-shadow: 0px 30px 40px 0px rgba(0, 16, 35, 0.12);
        display: flex;
        align-items: center;
    }

    .category__wrapper img {
        display: inline-block;
        width: 34px;
        height: 34px;
    }

    .category__wrapper img:not(:last-child) {
        margin-right: 10px;
    }

    .category__wrapper h4 {
        display: inline-block;
        font-size: 16px;
        font-weight: 800;
        line-height: 50px;
        vertical-align: sub;
        margin: 0;

        a {
            color: $titleColor;
        }
    }

    .category__wrapper {
        &:hover {
            a {
                color: $primaryColor;
            }
        }

        &.category__wrapper2 {
            img {
                height: 36px;
            }
        }

        &.category__wrapper6 {
            img {
                height: 29px;
            }
        }
    }

    .category__inner {
        position: relative;
        z-index: 1;
        transition: $transition;
        cursor: pointer;
        border-left: 3px solid $whiteColor;
        border-radius: 6px;

        &:hover {
            transform: translateY(-2px);
            border-color: $primaryColor;
        }
    }
}

.react-logo-area {
    &.brand__area {
        padding: 0 0 120px;

        .brand__width {
            background: #f6f6fb;
            max-width: 1170px;
            margin: 0 auto;
            padding: 45px 0 70px;
            border-radius: 12px;
        }
    }

    .brand__title {
        padding: 5px;
        display: inline-block;
        font-size: 24px;
        font-weight: 800;
        line-height: 50px;
        color: #020334;
    }

    .brand__slider {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;

        .brand__item {
            padding: 0 40px;
        }
    }
}


/*****************************************************
    07. Banner Two Section CSS Here 
****************************/

.hero3__area {
    overflow: hidden;
    background: url(../images/banner2/bg2.png) no-repeat center top;
    padding-bottom: 180px;
    padding-top: 160px;
    background-size: cover;

    .about__content {
        max-width: 640px;
        margin: 0 auto;
        padding-top: 30px;

        ul {
            margin: 0;
            padding: 0;
            display: flex;

            li {
                display: flex;
                align-items: center;
                border-top: 1px solid #EDEDED;
                padding: 30px 28px;

                &:first-child {
                    border-top: none;
                }

                .icon {
                    margin-right: 15px;

                    img {
                        margin-top: -50px;
                    }
                }

                .text {
                    flex: 1;

                    h4 {
                        margin: 0 0 14px;
                        font-weight: 700;
                        font-size: 40px;
                        line-height: 50px;
                    }

                    p {
                        margin: 0;
                        font-size: 16px;
                        line-height: 22px;
                        color: #5B5F64;
                    }
                }

                a {
                    border-radius: 50%;
                    width: 44px;
                    height: 44px;
                    border: 2px solid #EDEDED;
                    text-align: center;
                    line-height: 36px;

                    svg {
                        width: 18px;
                        color: #00306E;
                    }

                    &:hover {
                        background: $primaryColor;
                        color: $whiteColor;

                        svg {
                            color: $whiteColor;
                        }
                    }
                }
            }
        }
    }

    .hero3__content {
        text-align: center;

        .hero3__title {
            font-size: 70px;
            line-height: 74px;
            font-weight: 600;
            color: #0F1216;
            margin: 0;

            em {
                color: #3270FC;
                font-style: normal;
            }
        }

        .search-form {
            background: #fff;
            border-radius: 0px;
            padding: 14px 18px 16px 25px;
            box-shadow: 0px 20px 20px rgba(0, 14, 32, 0.1);
            max-width: 570px;
            margin: 40px auto 25px;
            position: relative;
            border-left: 3px solid #3270FC;

            [type="text"] {
                border: none;
                color: #4b5b70;
                font-size: 14px;
                outline: none;
                background: none;
                width: 100%;
            }

            button {
                border: none;
                background: none;
                padding: 0;
                position: absolute;
                right: 20px;
            }
        }

        .hero3__paragraph {
            font-size: 16px;
            line-height: 20.11px;
            color: #00306E;

            a {
                color: $primaryColor;

                svg {
                    width: 18px;
                }
            }
        }
    }
}

.p-relative {
    position: relative;
}

.hero3__image {
    margin-left: 0;
    position: absolute;
    right: -10%;
    top: 8%;

    .hero3__image-1 {
        border-radius: 50%;
    }
}

.hero3__image-2 {
    position: absolute;
    top: 56%;
    right: -22%;
    -webkit-animation: hero3-image 3s linear infinite alternate;
    -moz-animation: hero3-image 3s linear infinite alternate;
    -o-animation: hero3-image 3s linear infinite alternate;
    animation: hero3-image 3s linear infinite alternate;
}

.hero3__shape img {
    position: absolute;
    z-index: 1;
}

.hero3__shape-1 {
    top: 32%;
    left: 2%;
    -webkit-animation: hero-leaf 3s linear infinite alternate;
    -moz-animation: hero-leaf 3s linear infinite alternate;
    -o-animation: hero-leaf 3s linear infinite alternate;
    animation: hero-leaf 3s linear infinite alternate;
}

.hero3__shape-2 {
    top: 44%;
    left: 4.5%;
    -webkit-animation: hero-leaf-2 3s linear infinite alternate;
    -moz-animation: hero-leaf-2 3s linear infinite alternate;
    -o-animation: hero-leaf-2 3s linear infinite alternate;
    animation: hero-leaf-2 3s linear infinite alternate;
}

.hero3__shape-3 {
    top: 57%;
    left: 72%;
    -webkit-animation: hero-leaf-4 3s linear infinite alternate;
    -moz-animation: hero-leaf-4 3s linear infinite alternate;
    -o-animation: hero-leaf-4 3s linear infinite alternate;
    animation: hero-leaf-4 3s linear infinite alternate;
}

.hero3__shape-4 {
    top: 14.5%;
    right: 19%;
    -webkit-animation: hero-leaf-4 3s linear infinite alternate;
    -moz-animation: hero-leaf-4 3s linear infinite alternate;
    -o-animation: hero-leaf-4 3s linear infinite alternate;
    animation: hero-leaf-4 3s linear infinite alternate;
}

.hero3__shape-5 {
    top: 69%;
    right: 9%;
    -webkit-animation: hero-leaf 3s linear infinite alternate;
    -moz-animation: hero-leaf 3s linear infinite alternate;
    -o-animation: hero-leaf 3s linear infinite alternate;
    animation: hero-leaf 3s linear infinite alternate;
}

.hero3__shape-6 {
    top: 30%;
    right: 0%;
    -webkit-animation: hero-leaf-2 3s linear infinite alternate;
    -moz-animation: hero-leaf-2 3s linear infinite alternate;
    -o-animation: hero-leaf-2 3s linear infinite alternate;
    animation: hero-leaf-2 3s linear infinite alternate;
}


@keyframes hero-leaf {
    0% {
        transform: translateY(-50px);
    }

    100% {
        transform: translateY(0px);
    }
}

@keyframes hero-leaf-2 {
    0% {
        transform: translateY(0px);
    }

    100% {
        transform: translateY(-30px);
    }
}

@keyframes hero3-image {
    0% {
        transform: translateY(-20px);
    }

    100% {
        transform: translateY(0px);
    }
}

@keyframes hero-leaf-4 {
    0% {
        transform: translateX(-30px);
    }

    100% {
        transform: translateX(0px);
    }
}

@keyframes upDown {
    0% {
        transform: translate(-50%, -50%);
    }

    50% {
        transform: translate(-50%, -30%);
    }

    100% {
        transform: translate(-50%, -50%);
    }
}


/*****************************************************
    08. Service CSS Here 
****************************/

.react_populars_topics {
    .container {
        max-width: 1200px;
    }

    .item__inner {
        border: 2px solid rgba(15, 22, 41, 0.078);
        border-radius: 6px;
        padding: 40px 35px 35px 35px;
        position: relative;
        transition: $transition;
        margin-bottom: 30px;
        text-align: center;

        &:before {
            position: absolute;
            content: "";
            left: 0px;
            right: 0;
            top: -3px;
            border-radius: 6px 6px 0 0;
            height: 8px;
            width: 100%;
            z-index: 1;
            border-top: 3px solid #3270FC;
            transition: $transition;
            opacity: 0;
        }

        h3 {
            font-size: 20px;
            font-weight: 600;
            line-height: 26px;
            margin: 25px 0 0px;

            a {
                color: $titleColor;

                &:hover {
                    color: $primaryColor;
                }
            }
        }

        p {
            font-size: 16px;
            color: #5B5F64;
            margin: 0;
            line-height: 20px;
        }

        &:hover {
            box-shadow: 0px 30px 50px 0px rgba(0, 15, 56, 0.1), inset 0px 3px 0px 0px rgba(42, 109, 245, 0.004);
            border-color: $whiteColor;

            &:before {
                opacity: 1;
            }
        }
    }

    &.react_populars_topics {
        .item__inner {
            text-align: left;
        }
    }

    &.react_populars_topics2 {
        .item__inner {
            border: none;
            border-radius: 10px;
            padding: 40px 35px 35px 35px;
            position: relative;
            transition: $transition;
            margin-bottom: 30px;
            text-align: left;
            box-shadow: 0px 30px 60px rgba(15, 18, 22, 0.1);

            &:before {
                opacity: 0;
            }

            h3 {
                font-weight: 700;
                margin: 25px 0 14px;

                a {
                    color: #0F1216;
                    font-size: 20px;
                    font-weight: 700;

                    &:hover {
                        color: $primaryColor;
                    }
                }
            }

            p {
                font-size: 16px;
                color: #5B5F64;
                margin: 0;
                line-height: 24px;
            }

            a {
                font-size: 14px;
                font-weight: 600;
                color: #0F1216;
                display: inline-block;
                margin-top: 16px;

                svg {
                    width: 18px;
                }

                &:hover {
                    color: $primaryColor;
                }
            }

            &:hover {
                box-shadow: 0px 30px 50px 0px rgba(0, 15, 56, 0.1), inset 0px 3px 0px 0px rgba(42, 109, 245, 0.004);
                border-color: $whiteColor;

                &:before {
                    opacity: 0;
                }
            }
        }
    }

    &.react_populars_topics_about {
        .react__title__section-all {
            background: #F2F4F9;
            max-width: 1350px;
            margin: 0 auto;
            padding-top: 100px;
            padding-bottom: 200px;
            border-radius: 10px;
        }

        .container {
            margin-top: -200px;
        }

        &.react_populars_topics.react_populars_topics2 .item__inner {
            background: #fff;
        }
    }
}

.react__title__section-all {
    h2 {
        font-size: 44px;
        font-weight: 700;
        color: #0F1216;
        margin: 0 0 10px;
        line-height: 54px;
    }

    h6 {
        font-size: 16px;
        line-height: 20px;
        color: #D2093C;
        font-weight: 600;
        margin: 0 0 12px;
    }
}

/*****************************************************
    09. Popular Topics CSS Here 
****************************/

.react_popular_topics {
    .item__inner {
        position: relative;
        transition: $transition;
        margin-bottom: 30px;

        .icon {
            img {
                width: 100%;
            }
        }

        &:before {
            position: absolute;
            content: "";
            left: 0;
            right: 0;
            top: 0;
            border-radius: 6px 6px 0 0;
            height: 100%;
            width: 100%;
            z-index: 1;
            background: linear-gradient(180deg, rgba(15, 18, 22, 0) 46.67%, rgba(15, 18, 22, 0.9) 91%);
            transition: all 0.5s ease 0s;
            bottom: 0;
        }

        .react-content {
            position: absolute;
            left: 20px;
            right: 20px;
            bottom: 20px;
            z-index: 2;

            h3 {
                font-size: 22px;
                color: $whiteColor;
                line-height: 27.65px;
                margin: 0;
                font-weight: 700;

                a {
                    color: $whiteColor;

                    &:hover {
                        color: #b5b2b2;
                    }
                }
            }
        }

        a.r__link {
            font-size: 14px;
            color: $whiteColor;
            line-height: 17.6px;
            margin: 0;
            font-weight: 700;

            svg {
                width: 18px;
            }

            &:hover {
                color: #b5b2b2;
            }
        }

        &:hover {
            &:before {
                background: rgba(15, 18, 22, 0.8);
            }

            a.r__link {
                color: #b5b2b2;
            }
        }
    }
}


/*****************************************************
    10. About Section CSS Here 
****************************/

.about__area {
    h2 {
        font-size: 50px;
        font-weight: 700;
        line-height: 54px;

        em {
            color: #D2093C;
            font-style: normal;
        }
    }

    &.about__area_one {
        .about__content {
            padding-top: 60px;
            padding-left: 60px;
        }

        .about__paragraph {
            font-size: 20px;
            color: #777777;
            line-height: 30px;
        }

        p.about__paragraph2 {
            border-bottom: 1px solid #eaeaea;
            padding-bottom: 44px;
            margin-bottom: 38px;
        }

        p {
            font-size: 16px;
            line-height: 26px;
            color: #777;

            a {
                color: #D2093C;
                text-decoration: underline !important;
                font-weight: 700;

                svg {
                    width: 18px;
                }

                &:hover {
                    opacity: .9;
                }
            }
        }

        ul {
            display: flex;
            margin-top: 40px;

            li {
                .more-about {
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: 600;
                    border-radius: 6px;
                    border: 2px solid rgba(0, 48, 110, 0.10);
                    padding: 14px 35px 14px 35px;
                    color: #00306E;
                    display: inline-block;
                    line-height: 20px;

                    svg {
                        width: 18px;
                    }

                    &:hover {
                        background: #D2093C;
                        color: $whiteColor;
                        border-color: #D2093C;
                    }
                }

                &.last-li {
                    background: url(../images/about/comment.png) no-repeat left top;
                    padding-left: 72px;
                    margin-left: 30px;

                    em {
                        display: block;
                        font-size: 14px;
                        color: #999;
                        line-height: 15px;
                        margin-top: 5px;
                        font-style: normal;
                    }

                    a {
                        font-size: 18px;
                        color: #00306e;
                        font-weight: 700;

                        &:hover {
                            color: #D2093C;
                        }
                    }
                }
            }
        }
    }
}

.about2__area {
    h2 {
        color: #0F1216;
        font-size: 44px;
        line-height: 56px;

        em {
            color: #3270FC;
        }
    }

    .about__image {
        position: relative;
    }

    .react__shape__1 {
        position: absolute;
        left: -8%;
        top: 57%;
        -webkit-animation: slide-top 5000ms linear infinite alternate;
        animation: slide-top 5000ms linear infinite alternate;
        z-index: 1;
    }

    .about__content {
        padding-left: 70px;

        .about__paragraph {
            font-size: 18px;
            line-height: 28px;
            color: #5B5F64;
        }
    }

    .react__shape__2 {
        position: absolute;
        right: 60px;
        bottom: -30px;
        z-index: -1;
    }

    .react__shape__11 {
        position: absolute;
        left: -22px;
        top: -23px;
        z-index: -1;
    }

    .react__shape__33 {
        position: absolute;
        right: -13px;
        top: 60px;
        -webkit-animation: slide-top 5000ms linear infinite alternate-reverse;
        animation: slide-top 5000ms linear infinite alternate-reverse;
        z-index: 2;
    }

    .about__btn {
        a {
            font-size: 16px;
            font-weight: 600;
            border-radius: 6px;
            border: 2px solid rgba(0, 48, 110, 0.1);
            padding: 12px 35px 12px 35px;
            color: #00306E;
            display: inline-block;
            margin-top: 30px;
            line-height: 20px;

            svg {
                width: 18px;
            }

            &:hover {
                background: #3270FC;
                color: #fff;
            }
        }
    }

    ul {
        margin: 0;
        padding: 0;
        overflow: hidden;

        li {
            font-size: 16px;
            color: #0F1216;
            font-weight: 600;
            padding-bottom: 15px;

            i {
                margin-right: 10px;
                position: relative;
                background: rgba(210, 9, 60, 0.1);
                padding: 8px;
                color: $primaryColor;
                border-radius: 50%;
                display: inline-block;
                margin-left: 5px;
            }
        }
    }

    &.about2__area {
        .about__content {
            padding-left: 0;

            h6 {
                font-size: 16px;
                line-height: 20px;
                color: #D2093C;
                font-weight: 600;
                margin: 0 0 12px;
            }

            .about__btn {
                a {
                    font-size: 16px;
                    font-weight: 600;
                    border-radius: 6px;
                    border: none;
                    padding: 16px 35px 16px 35px;
                    color: #fff;
                    display: inline-block;
                    margin-top: 30px;
                    line-height: 20px;
                    background: #3270FC;

                    &:hover {
                        opacity: .9;
                        color: #fff;
                    }
                }
            }
        }
    }

    &.about3__area {
        .about__content {
            padding-top: 85px;
        }
    }
}


/*****************************************************
    11. Breadcrumbs Section CSS 
****************************/

// .react-breadcrumbs {
//     .breadcrumbs-wrap {
//         position: relative;
//         overflow: hidden;

//         .container {
//             position: relative;
//         }

//         .breadcrumbs-inner {
//             width: 100%;
//             position: absolute;
//             top: 50%;
//             transform: translateY(-50%);

//             .breadcrumbs-text {
//                 text-align: center;

//                 .breadcrumbs-title {
//                     width: 100%;
//                     font-size: 60px;
//                     text-transform: capitalize;
//                     color: $whiteColor;
//                     font-weight: 700;
//                     line-height: 1;
//                     margin: 0 0 15px;
//                 }

//                 .back-nav {
//                     ul {
//                         li {
//                             display: inline;
//                             color: #fff;
//                             font-size: 16px;
//                             font-weight: 400;
//                             opacity: .8;

//                             a {
//                                 color: #fff;

//                                 &:hover {
//                                     opacity: 0.7;
//                                 }
//                             }

//                             &:after {
//                                 content: "";
//                                 margin: 0 6px 0 12px;
//                                 background: $whiteColor;
//                                 width: 7px;
//                                 height: 7px;
//                                 border-radius: 50%;
//                                 display: inline-block;
//                             }

//                             &:last-child {
//                                 &:after {
//                                     content: unset;
//                                     margin: 0;
//                                 }
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }

//     &.react-breadcrumbs-single {
//         .container {
//             max-width: 1200px;

//             .single-cate {
//                 background: $whiteColor;
//                 color: $titleColor;
//                 display: inline-block;
//                 padding: 4px 18px;
//                 border-radius: 30px;
//                 font-size: 13px;
//                 font-weight: 600;
//                 margin-bottom: 20px;
//             }
//         }

//         .react-breadcrumbs .breadcrumbs-wrap .breadcrumbs-inner {
//             top: 61%;
//         }

//         .breadcrumbs-wrap .breadcrumbs-inner .breadcrumbs-text {
//             text-align: left;
//         }
//     }

//     &.react-breadcrumbs-blog-single {
//         .container {
//             max-width: 1200px;
//         }

//         .breadcrumbs-wrap .breadcrumbs-inner .breadcrumbs-text {
//             text-align: left;

//             span {
//                 font-size: 16px;
//                 color: $whiteColor;
//                 margin-bottom: 15px;
//                 display: block;
//             }

//             .back-nav ul li {
//                 font-size: 14px;
//                 color: $whiteColor;
//                 margin-right: 30px;
//                 opacity: 1;

//                 svg {
//                     width: 17px;
//                     height: 17px;
//                     margin-right: 4px;
//                     position: relative;
//                     top: -2px;
//                 }

//                 &:after {
//                     display: none;
//                 }
//             }
//         }
//     }

//     &.single-page-breadcrumbs {
//         .breadcrumbs-wrap .breadcrumbs-inner .breadcrumbs-text {
//             max-width: 800px;
//         }
//     }
// }


//=================== my css=======================

.react-breadcrumbs {
    .breadcrumbs-wrap {
        position: relative;
        overflow: hidden;

        img {
            width: 100%;
            height: 7rem;
            display: block; // Ensure images are block elements
        }

        .container {
            position: relative;
        }

        .breadcrumbs-inner {
            width: 100%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            .breadcrumbs-text {
                text-align: center;

                .breadcrumbs-title {
                    width: 100%;
                    font-size: 60px;
                    text-transform: capitalize;
                    color: $whiteColor;
                    font-weight: 700;
                    line-height: 1;
                    margin: 9px 0 9px;
                }

                .back-nav {
                    ul {
                        li {
                            display: inline;
                            color: #fff;
                            font-size: 16px;
                            font-weight: 400;
                            opacity: 0.8;
                            background-color: red;

                            a {
                                color: #fff;

                                &:hover {
                                    opacity: 0.7;
                                }
                            }

                            &:after {
                                content: "";
                                margin: 0 6px 0 12px;
                                background: $whiteColor;
                                width: 7px;
                                height: 7px;
                                border-radius: 50%;
                                display: inline-block;
                            }

                            &:last-child {
                                &:after {
                                    content: unset;
                                    margin: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.react-breadcrumbs-single {
        .container {
            max-width: 1200px;

            .single-cate {
                background: $whiteColor;
                color: $titleColor;
                display: inline-block;
                padding: 4px 18px;
                border-radius: 30px;
                font-size: 13px;
                font-weight: 600;
                margin-bottom: 20px;
            }
        }

        .react-breadcrumbs .breadcrumbs-wrap .breadcrumbs-inner {
            top: 61%;
        }

        .breadcrumbs-wrap .breadcrumbs-inner .breadcrumbs-text {
            text-align: left;
        }
    }

    &.react-breadcrumbs-blog-single {
        .container {
            max-width: 1200px;
        }

        .breadcrumbs-wrap .breadcrumbs-inner .breadcrumbs-text {
            text-align: left;

            span {
                font-size: 16px;
                color: $whiteColor;
                margin-bottom: 15px;
                display: block;
            }

            .back-nav ul li {
                font-size: 14px;
                color: $whiteColor;
                margin-right: 30px;
                opacity: 1;

                svg {
                    width: 17px;
                    height: 17px;
                    margin-right: 4px;
                    position: relative;
                    top: -2px;
                }

                &:after {
                    display: none;
                }
            }
        }
    }

    &.single-page-breadcrumbs {
        .breadcrumbs-wrap .breadcrumbs-inner .breadcrumbs-text {
            max-width: 800px;
        }
    }
}


/*****************************************************
    12. Featured Online Section CSS Here  
****************************/

.react_featured_online {
    background: #F5F5F5;

    .container {
        max-width: 1200px;

        .border-btns {
            font-size: 16px;
            font-weight: 600;
            border-radius: 6px;
            border: 2px solid rgba(0, 48, 110, 0.1);
            padding: 12px 35px 12px 35px;
            color: #00306E;
            display: inline-block;
            margin-top: 30px;
            line-height: 20px;

            svg {
                width: 18px;
            }

            &:hover {
                background: #3270FC;
                color: #fff;
            }
        }
    }

    .item__inner {
        display: flex;
        background: #fff;
        box-shadow: 0px 1px 2px rgba(15, 18, 22, 0.1);
        border-radius: 6px;
        padding: 35px 30px;
        align-items: center;
        margin-bottom: 23px;

        .icon {
            flex: 0 0 45%;

            img {
                height: 130px;
            }
        }

        .react-content {
            flex: 0 0 55%;

            h5 {
                margin: 0;
                color: #D2093C;
                font-size: 20px;
                font-weight: 600;
            }

            h3 {
                margin: 10px 0 18px;
                color: #D2093C;
                font-size: 20px;
                font-weight: 600;

                a {
                    color: #0F1216;

                    &:hover {
                        color: #3270FC;
                    }
                }
            }

            ul {
                margin: 0;
                padding: 0;
                list-style: none;
                display: flex;
                align-items: center;

                li {
                    color: #5B5F64;
                    font-size: 16px;
                    line-height: 20px;
                    padding-right: 30px;

                    svg {
                        width: 18px;
                        position: relative;
                        top: -2px;
                        margin-right: 2px;
                    }

                    &:last-child {
                        padding-right: 0;
                    }
                }
            }
        }

        &:hover {
            box-shadow: 0px 30px 30px rgba(15, 18, 22, 0.1);
        }
    }
}

/*****************************************************
    13. FAQ Section CSS Here  
****************************/

.accordion {
    .accordion-item {
        border: none;
        margin-bottom: 10px;
        box-shadow: 0px 20px 40px rgba(15, 18, 22, 0.1);
        border-radius: 6px;
        .accordion-button {
            padding: 20px 37px;
            border-radius: 6px;
            background: unset;
            border: none;
            box-shadow: unset;
            &:focus {
                box-shadow: none;
            }
            &:after {
                display: none;
            }
            .card-link {
                width: 100%;
                font-size: 18px;
                font-weight: 600;
                color: #020334;
                border-radius: 0;
                box-shadow: none;
                &:after {
                    display: inline-block;
                    font-style: normal;
                    font-variant: normal;
                    text-rendering: auto;
                    position: absolute;
                    right: 28px;
                    top: 16px;
                    font-family: ElegantIcons;
                    font-size: 22px;
                    color: #091524;
                    background-image: none;
                    font-weight: 300;
                    content: "\33";
                }
                &[aria-disabled="true"] {
                    color: $primaryColor;
                    &:after {
                        content: "\32";
                        color: $primaryColor;
                    }
                }
                &:hover{
                    color: $primaryColor;
                    &:after {
                        color: $primaryColor;
                    }
                }
            }
        }


        .accordion-body {
            padding-top: 0;

            p {
                font-size: 16px;
                font-weight: 400;
                color: #5B5F64;
                margin: 0;
                padding: 0 15px 24px;
            }
        }
    }
}
.accordion__area {
    position: relative;

    .accordion__wrapper-1 {
        h2 {
            font-size: 44px;
            font-weight: 700;
            color: #0F1216;
            margin: 0 0 10px;
            line-height: 54px;
        }

        h6 {
            font-size: 16px;
            line-height: 20px;
            color: #D2093C;
            font-weight: 600;
            margin: 0;
        }

        p {
            font-size: 16px;
            color: #5B5F64;
            line-height: 26px;
        }

        a.border-btns {
            font-size: 16px;
            font-weight: 600;
            border-radius: 6px;
            border: 2px solid rgba(0, 48, 110, 0.1);
            padding: 12px 35px 12px 35px;
            color: #00306E;
            display: inline-block;
            margin-top: 10px;
            line-height: 20px;

            svg {
                width: 18px;
            }

            &:hover {
                background: #3270FC;
                color: #fff;
            }
        }
    }

    .accordion__shape img {
        position: absolute;
    }

    .accordion__shape-1 {
        left: 39%;
        bottom: -14%;
        z-index: -1;
    }

    .accordion__shape-1a {
        left: 7%;
        top: 72%;
        z-index: 1;
    }

    &.accordion__area .accordion-button:not(.collapsed) {
        background: none;
        color: #3270FC;
        font-size: 20px;

        &:after {
            color: #3270FC;
            display: none;
        }
    }

    &.accordion__area .accordion-button:hover {
        background: none;
        color: #3270FC;
        font-size: 20x;

        &:after {
            color: #3270FC;
        }
    }
}


/*****************************************************
    14. Instructor Section CSS Here  
****************************/

.instructor__area {
    .container {
        max-width: 1590px;

        .instructors_lefts {
            text-align: left;
            padding-left: 120px;

            h2 {
                font-size: 44px;
                font-weight: 700;
                color: #0F1216;
                margin: 0 0 10px;
                line-height: 54px;
            }

            h6 {
                font-size: 16px;
                line-height: 20px;
                color: #D2093C;
                font-weight: 600;
                margin: 0;
            }
        }

        .instructor__content-one {
            box-shadow: none !important;
        }
    }

    .instructor__content {
        h4 {
            margin: 0;
        }

        p {
            margin: 0;
        }
    }

    .instructor__pre-title {
        font-size: 16px;
        font-weight: 600;
        color: #2a6df5;
    }

    .instructor__content {
        display: flex;
        flex-direction: column;
        padding-top: 34px;
        padding-bottom: 34px;
    }

    .instructor__content-1 img {
        background-size: cover;
        border: 5px solid #fff;
        border-radius: 50%;
        width: 120px;
        height: 120px;
        box-shadow: 0px 10px 30px rgba(15, 18, 22, 0.1);
    }

    .instructor__content-2 {
        margin-top: 15px;
    }

    .instructor__content-2 h4 a {
        font-size: 20px;
        font-weight: 600;
        color: #0F1216;
    }

    .instructor__content-2 h4 a:hover {
        color: #2a6df5;
        -webkit-transition: all 0.3s ease-out 0s;
        -moz-transition: all 0.3s ease-out 0s;
        -ms-transition: all 0.3s ease-out 0s;
        -o-transition: all 0.3s ease-out 0s;
        transition: all 0.3s ease-out 0s;
    }

    .instructor__content-2 p {
        margin-top: 0;
        font-size: 16px;
        font-weight: 400;
        color: #5B5F64;
        line-height: 20px;
    }

    .instructor__content-3 {
        margin-top: 5px;
    }

    .instructor__content-3 ul li {
        display: inline-block;
        margin: 0 7px;
    }

    .instructor__content-3 ul li:not(:last-child) {
        margin-right: 7px;
    }

    .instructor__content-3 ul li a {
        font-size: 14px;
        font-weight: 500;
        color: #58606c;
        display: inline-block;
        text-align: center;
    }

    .instructor__content-3 ul li a:hover {
        color: #2a6df5;
        -webkit-transition: all 0.3s ease-out 0s;
        -moz-transition: all 0.3s ease-out 0s;
        -ms-transition: all 0.3s ease-out 0s;
        -o-transition: all 0.3s ease-out 0s;
        transition: all 0.3s ease-out 0s;
    }

    .instructor__content:hover {
        border-radius: 10px;
        background-color: white;
        box-shadow: 0px 30px 40px 0px rgba(1, 11, 60, 0.06);
        width: 100%;
        -webkit-transition: all 0.3s ease-out 0s;
        -moz-transition: all 0.3s ease-out 0s;
        -ms-transition: all 0.3s ease-out 0s;
        -o-transition: all 0.3s ease-out 0s;
        transition: all 0.3s ease-out 0s;
    }
}

.instructors___page {
    .instructor__content {
        margin-bottom: 30px;

        .instructor__image {
            position: relative;

            img {
                border-radius: 4px;
                width: 100%;
            }

            .content__hover {
                position: absolute;
                bottom: -50px;
                right: 0px;
                left: 0px;
                padding: 25px;
                opacity: 0;
                transition: all .5s ease;

                p {
                    margin: 0;
                    color: #fff;
                    font-size: 16px;
                    line-height: 20px;
                }

                ul {
                    display: flex;
                    justify-content: start;
                    margin-left: -5px;
                    margin-top: 20px;

                    li {
                        margin: 0 4px;

                        a {
                            border: 2px solid rgba(255, 255, 255, 0.1);
                            border-radius: 50%;
                            width: 40px;
                            height: 40px;
                            line-height: 37px;
                            text-align: center;
                            display: inline-block;
                            color: #fff;

                            span {
                                font-size: 14px;
                            }

                            &:hover {
                                background: #3270FC;
                                border-color: #3270FC;
                            }
                        }
                    }
                }
            }

            &:before {
                content: "";
                background: linear-gradient(180deg, rgba(15, 18, 22, 0) 46.67%, rgba(15, 18, 22, 0.9) 91%);
                position: absolute;
                bottom: 0;
                right: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 4px;
                opacity: 0;
                transition: all .5s ease;
            }
        }

        .bottom-content {
            text-align: center;
            margin-top: 20px;
            transition: all .5s ease;

            h4 {
                margin: 0;
                font-size: 20px;
                font-weight: 700;

                a {
                    color: #0F1216;

                    &:hover {
                        color: $primaryColor;
                    }
                }
            }

            p {
                margin: 0;
                font-size: 16px;
                color: #5B5F64;
            }
        }

        &:hover {
            .instructor__image {
                &:before {
                    opacity: 1;
                }
            }

            .content__hover {
                opacity: 1;
                bottom: 0px;
            }

            .bottom-content {
                opacity: 0;
            }
        }
    }
}


/*****************************************************
    15. Profile Section CSS Here  
****************************/

.profile-page {
    .profile-top {
        background: $whiteColor;

        .follows {
            background: #3270FC;
            border-radius: 6px;
            color: #fff;
            padding: 8px 25px;
            text-align: center;
            display: block;
            max-width: 356px;
            font-weight: 600;
            font-size: 16px;
            margin-top: 10px;

            svg {
                width: 18px;
            }

            &:hover {
                opacity: .9;
            }
        }

        .teacher__course {
            padding-top: 0px;
            margin-top: 50px;
            font-size: 24px;
            font-weight: 700;
            margin-bottom: 40px;
            line-height: 30px;
        }

        .follow {
            border: 2px solid #e8eaf0;
            padding: 7px 24px 7px 24px;
            color: $titleColor;
            font-size: 15px;
            font-weight: 600;
            border-radius: 5px;

            &:hover {
                background: $primaryColor;
                border-color: $primaryColor;
                color: $whiteColor;
            }
        }

        .user-section {
            display: flex;
            align-items: center;
            padding-bottom: 30px;
            border-bottom: 1px solid #e8eaf0;
            margin-bottom: 30px;

            li {
                padding-right: 60px;
                line-height: 1.5;
                font-size: 16px;
                color: #0F1216;
                font-weight: 600;

                em {
                    display: block;
                    font-style: normal;
                    color: #5B5F64;
                    font-weight: 400;
                }

                .back-ratings {
                    i {
                        font-size: 14px;
                        color: #ff9415;
                    }
                }

                &.social {
                    a {
                        color: #898a93;
                        margin: 0 6px 0 0;
                        font-size: 13px;

                        &:hover {
                            color: $primaryColor;
                        }
                    }
                }
            }
        }

        h3 {
            font-size: 26px;
            font-weight: 600;
            color: #0F1216;
        }
    }

    .react-course-filter.related__course {
        padding-top: 20px;
    }

    .count__area2 {
        .count__content {
            display: flex;
            align-items: center;
            position: relative;
            border: 1px solid rgba(0, 48, 110, 0.1);
            border-radius: 6px;
            padding: 30px 25px;

            .icon {
                margin-right: 15px;

                img {
                    position: relative;
                    top: -2px;
                }
            }

            .text {
                flex: 1;

                span {
                    margin: 0 0 5px;
                    font-weight: 400;
                    font-size: 40px;
                    line-height: 50px;
                    color: #0F1216;
                }

                em {
                    margin: 0 0 5px;
                    font-weight: 400;
                    font-size: 40px;
                    line-height: 50px;
                    color: #0F1216;
                    font-style: normal;
                }

                p {
                    margin: 0;
                    font-size: 16px;
                    line-height: 18px;
                    color: #5B5F64;
                }
            }
        }
    }

    .react-breadcrumbs .breadcrumbs-wrap .breadcrumbs-inner .breadcrumbs-text {
        max-width: unset;
    }
}


/*****************************************************
    16. Clients Section CSS Here  
****************************/
.react-clients {
    background: url(../images/testimonial/bg.jpg) no-repeat center top;
    background-size: cover;

    .react__title__section {
        h2 {
            font-size: 44px;
            font-weight: 700;
            color: #0F1216;
            margin: 0 0 10px;
            line-height: 54px;
        }

        h6 {
            font-size: 16px;
            line-height: 20px;
            color: #D2093C;
            font-weight: 600;
            margin: 0;
        }
    }

    .client-slider {
        margin: 0 auto;
        padding: 0;
        list-style: none;
        max-width: 1100px;
        position: relative;

        .single-client {
            position: relative;
            background: $whiteColor;
            padding: 50px 60px 50px;
            border-radius: 10px;
            transition: $transition;
            overflow: hidden;
            border: 2px solid;
            border-color: rgba(2, 3, 52, .05);
            display: flex;
            align-items: center;

            .client-title {
                color: #0F1216;
                font-size: 20px;
                font-weight: 700;
                margin: 0 0 15px;
                display: block;

                em {
                    color: #5B5F64;
                    font-size: 16px;
                    font-weight: 400;
                    display: block;
                    font-style: normal;
                }
            }

            .client-content {
                position: relative;
                padding-right: 40px;

                p {
                    font-size: 20px;
                    color: #5B5F64;
                    line-height: 32px;
                }

                .testimonial__ratings {
                    font-size: 16px;
                    color: #5B5F64;
                    font-weight: 600;

                    em {
                        color: #FFA439;
                    }

                    span {
                        em {
                            color: #0F1216;
                            font-weight: 600;
                            font-style: normal;
                        }
                    }
                }

                .comma {
                    width: auto;
                    position: absolute;
                    right: 25px;
                    // bottom: -31%;
                    z-index: 11;
                }
            }

            .client-author {
                padding: 0px;
                width: 230px;
                display: block;
                margin-right: 70px;

                img {
                    border-radius: 6px;
                }
            }
        }

        .slick-slider {
            .slick-arrow {
                position: absolute;
                left: 20px;
                display: inline-block;
                font-size: 0;
                width: 70px;
                height: 70px;
                line-height: 67px;
                color: $titleColor;
                background: #fff;
                border-radius: 100%;
                transition: $transition;
                border: 4px solid #F2F4F9;
                box-shadow: -6px 20px 30px rgba(15, 18, 22, 0.06);

                &:before {
                    content: "\35";
                    font-family: ElegantIcons;
                    position: absolute;
                    left: 50%;
                    color: $titleColor;
                    transform: translateX(-50%);
                    font-size: 26px;
                    font-weight: 700;
                    line-height: 60px;
                }

                &.slick-next {
                    left: auto;
                    right: -35px;
                }

                &.slick-prev {
                    left: -35px;
                    right: auto;

                    &:before {
                        content: '\34';
                    }
                }

                &:hover {
                    color: $whiteColor;
                    background: #3270FC;
                    border-color: #3270FC;
                    &:before {
                        color: $whiteColor;
                    }
                }
            }
        }
    }

    &.react-clientso {
        background: url(../images/testimonial/bg3.png) no-repeat center top;
        background-size: cover;
    }

    &.home-testimonial {
        background: #fff !important;

        .react__title__section h2 {
            color: #00306e;
        }

        .client-slider .slick-slider .slick-arrow:hover {
            background: #d2093c;
            border-color: #d2093c;
            ;
        }
    }
}


/*****************************************************
    17. Blog Section CSS Here  
****************************/
.react-blog__area {
    &.graybg-home {
        background: #F5F5F5;

        .react__title__section {
            h2 {
                color: #00306e;
            }
        }

        .b_date {
            background: #D2093C;
        }

        .blog__card--title a {
            color: #00306e;

            &:hover {
                color: #D2093C;
            }
        }

    }

    .blog__subtitle {
        font-size: 16px;
        font-weight: 500;
        color: #2a6df5;
    }

    .react__title__section {
        h2 {
            font-size: 44px;
            font-weight: 700;
            color: #0F1216;
            margin: 0 0 10px;
            line-height: 54px;
        }

        h6 {
            font-size: 16px;
            line-height: 20px;
            color: #D2093C;
            font-weight: 600;
            margin: 0;
        }
    }

    .blog__card {
        border-radius: 6px;
        box-shadow: 0px 30px 60px 0px rgba(0, 15, 56, 0.1);
        margin: 0 3px;
    }

    .blog__thumb {
        position: relative;
        overflow: hidden;
    }

    .blog__thumb--image img {
        border-radius: 6px 6px 0 0;
        transition: $transition;
    }

    .blog__thumb--image img:hover {
        -webkit-transform: scale(1.1);
        -moz-transform: scale(1.1);
        -ms-transform: scale(1.1);
        transform: scale(1.1);
    }

    .b_date {
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        padding: 4px 12px 5px 12px;
        color: #fff;
        background-color: #3270FC;
        border-radius: 4px;
        position: absolute;
        z-index: 1;
        right: 6%;
        top: 8%;
        font-style: normal;
    }

    .blog__card--content {
        padding: 28px 36px 32px 37px;
    }

    .blog__card--date {
        font-size: 16px;
        font-weight: 400;
        color: #D2093C;
    }

    .blog__card--title {
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        padding: 5px 0 0 0;
        margin: 0 0 16px;

        a {
            color: #0F1216;
        }
    }

    .blog__card--title:hover a {
        color: #3270FC;
    }

    .blog__card--link {
        font-size: 15px;
        font-weight: 600;
        line-height: 28px;
        color: $titleColor;

        &:hover {
            color: $primaryColor;
        }
    }

    .blog__card--link-icon {
        vertical-align: middle;
        padding-left: 5px;
    }

    .blog__card--icon {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
    }

    .blog__card--icon-1 svg {
        height: 17px;
        line-height: 10px;
        vertical-align: sub;
        color: #5B5F64;
    }

    .blog__card--icon-1 span {
        font-size: 16px;
        font-weight: 400;
        color: #5B5F64;
        padding-left: 2px;
        line-height: 10px;
        vertical-align: middle;
    }

    .blog__card--icon-2 {
        display: flex;
    }

    .blog__card--icon-2-first {
        margin-right: 17px;
    }

    .blog__card--icon-2-first svg {
        height: 17px;
    }

    .blog__card--icon-2-first span {
        vertical-align: middle;
    }

    .blog__card--icon-2-second svg {
        height: 17px;
    }

    .blog__card--icon-2-second span {
        vertical-align: middle;
    }
}


/*****************************************************
    18. Campus Section CSS Here 
****************************/

.campus_sec {
    background: url(../images/campus/cam.png) no-repeat center top;
    background-size: cover;

    .react__title__section {
        img {
            margin-right: 120px;
        }
    }

    .about__image {
        position: relative;

        .shape-1 {
            position: absolute;
            bottom: 90px;
            right: 5px;
        }
    }

    .about__content {
        background: $whiteColor;
        padding: 50px 60px;
        margin-right: 15px;

        ul {
            margin: 0;
            padding: 0;

            li {
                display: flex;
                align-items: center;
                border-top: 1px solid #EDEDED;
                padding: 30px 0;

                &:first-child {
                    border-top: none;
                }

                .icon {
                    margin-right: 15px;

                    img {
                        margin-top: -50px;
                    }
                }

                .text {
                    flex: 1;

                    h4 {
                        margin: 0 0 14px;
                        font-weight: 700;
                        font-size: 20px;
                        line-height: 25px;
                    }

                    p {
                        margin: 0;
                        font-size: 16px;
                        line-height: 22px;
                        color: #4D5765;
                    }
                }

                a {
                    border-radius: 50%;
                    width: 44px;
                    height: 44px;
                    border: 2px solid #EDEDED;
                    text-align: center;
                    line-height: 36px;

                    svg {
                        width: 18px;
                        color: #00306e;
                    }

                    &:hover {
                        background: #00306e;
                        color: $whiteColor;

                        svg {
                            color: $whiteColor;
                        }
                    }
                }
            }
        }
    }

    .more-about {
        font-size: 16px;
        line-height: 20px;
        font-weight: 600;
        border-radius: 6px;
        border: 2px solid rgba(0, 48, 110, 0.10);
        padding: 12px 35px 12px 35px;
        color: #00306E;
        display: inline-block;
        margin-top: 60px;
        line-height: 20px;

        svg {
            width: 20px;
        }

        &:hover {
            background: #D2093C;
            color: $whiteColor;
            border-color: #D2093C;
        }
    }
}


/*****************************************************
    19. Course Section CSS Here  
****************************/

.popular__course__area {
    background: #fff;
    position: relative;

    .course__item {
        box-shadow: 0px 20px 60px 0px rgba(0, 15, 56, 0.1);
        margin: 5%;
        height: 20rem;

        .course__inner {
            padding: 25px 0 17px;

            ul {
                display: flex;
                justify-content: start;
                padding-left: 25px;
                padding-right: 25px;

                li {
                    font-size: 16px;
                    line-height: 22px;
                    color: #00306E;
                    position: relative;
                    padding-left: 16px;
                    margin-right: 30px;

                    &:before {
                        content: "";
                        position: absolute;
                        left: 0;
                        background: $secondaryColor;
                        width: 6px;
                        height: 6px;
                        top: 9px;
                        border-radius: 50%;
                    }

                    &:first-child {
                        color: #D2093C;

                        &:before {
                            background: #D2093C;
                        }
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }

        .react-course-title {
            font-size: 20px;
            line-height: 18px;
            margin: 14px 0 30px;
            font-weight: 600;
            padding-left: 25px;
            padding-right: 25px;

            a {
                color: $titleColor;

                &:hover {
                    color: #D2093C;
                }
            }
        }

        .course__card-icon {
            justify-content: space-between;
            border-top: 1px solid #EDEDED;
            padding-left: 25px;
            padding-right: 25px;
            padding-top: 15px;
            // height: 2rem;

            .course__card-icon--1 {
                svg {
                    color: $secondaryColor;
                    width: 18px;
                }

                span {
                    font-weight: 400;
                    font-size: 16px;
                    color: #4D5765;
                    padding-left: 7px;
                }
            }

            .react__user {
                font-size: 20px;
                color: #D2093C;
                font-weight: 700;
            }
        }
    }

    .view-courses {
        font-size: 16px;
        line-height: 20px;
        font-weight: 600;
        border-radius: 6px;
        border: 2px solid rgba(0, 48, 110, 0.10);
        padding: 12px 35px 12px 35px;
        color: #00306E;
        display: inline-block;
        margin-top: 60px;
        line-height: 20px;

        svg {
            width: 20px;
        }

        &:hover {
            background: #D2093C;
            color: $whiteColor;
            border-color: #D2093C;
        }
    }
}


/*****************************************************
    20. Counter CSS Here 
****************************/

.count__area {
    .count__width {
        background: #D2093C;
        padding-top: 50px;
        padding-bottom: 50px;

        .col-lg-3 {
            border-right: 1px solid rgba(255, 255, 255, 0.2);

            &:last-child {
                border-right: none;
            }
        }

        .count__content {
            text-align: center;
            padding-top: 15px;
            padding-bottom: 15px;

            .count__content--paragraph {
                font-size: 20px;
                line-height: 24px;
                font-weight: 700;
                margin: 0;
                color: $whiteColor;
            }

            .count__content--title-1 {
                font-size: 60px;
                line-height: 75px;
                font-weight: 700;
                margin: 8px 0 5px;
                color: $whiteColor;
            }

            .count__content--paragraph2 {
                font-size: 12px;
                line-height: 15px;
                font-weight: 600;
                margin: 0;
                letter-spacing: 3px;
                color: $whiteColor;
                text-transform: uppercase;
            }
        }
    }
}

/*****************************************************
    21. Upcoming Event Section CSS Here  
****************************/
.react-upcoming__event {
    background: #F5F5F5;
    padding-top: 98px;
    padding-bottom: 106px;

    .event__card {
        background: #fff;
        margin: 0 10px;
        padding: 25px 32px 42px;

        .event__card--date {
            font-size: 16px;
            line-height: 20px;
            color: #4D5765;

            em {
                font-size: 50px;
                line-height: 62px;
                font-weight: 700;
                font-style: normal;
                display: block;
                color: #D2093C;
            }
        }

        .event_time {
            font-size: 16px;
            color: #D2093C;
            line-height: 22px;
            margin: 28px 0 4px;
        }

        .event__card--title {
            font-size: 20px;
            font-weight: 600;
            line-height: 25px;
            margin: 0;

            a {
                color: $titleColor;

                &:hover {
                    color: $primaryColor;
                }
            }
        }

        .event_location {
            font-size: 16px;
            color: #4D5765;
            margin: 24px 0;

            svg {
                width: 18px;
                color: $titleColor;
                position: relative;
                top: -1px;
                margin-right: 2px;
            }
        }

        .event__card--link {
            font-size: 16px;
            line-height: 20px;
            font-weight: 600;
            border-radius: 6px;
            border: 2px solid rgba(0, 48, 110, 0.10);
            padding: 8px 35px 7px 35px;
            color: #00306E;
            display: inline-block;
            line-height: 20px;
            width: 100%;
            text-align: center;

            svg {
                width: 20px;
            }

            &:hover {
                background: #D2093C;
                color: $whiteColor;
                border-color: #D2093C;
            }
        }
    }

    .slick-dots {
        position: unset;
        text-align: center;
        margin-top: 50px;
        li {
            button {
                width: 12px;
                height: 12px;
                background: #C4C4C4;
                display: inline-block;
                border-radius: 50%;
                margin: 0 5px;
                border: none;
            }

            &.slick-active {
                button {
                    background: #D2093C;
                }
            }
        }
    }
}

.back__course__page_grid {
    .shorting__course {
        background: #fff;
        box-shadow: 0px 1px 2px rgba(15, 18, 22, 0.1);
        border-radius: 6px;
        padding: 15px 20px;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 50px;

        .all__icons {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            svg {
                cursor: pointer;
                transform: rotate(90deg);
                position: relative;
                width: 18px;
                color: #3270FC;
                top: -2px;
            }

            svg.feather-list {
                color: #5c5c68;
                width: 28px;
                height: 28px;
            }

            .result-count {
                padding-left: 10px;
                color: #0F1216;
                font-size: 16px;
                font-weight: 400;
            }
        }

        .from-control {
            background: #F2F4F9;
            border: none;
            padding: 12px 15px;
            border-radius: 6px;
            color: #5c5c68;
            font-size: 15px;
            font-weight: 500;
            cursor: pointer;
            border-right: 15px solid #F2F4F9;
            margin-left: 15px;
        }
    }

    .shorting__course2 {
        background: none;
        padding: 0px;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 20px;

        .col-md-6 {
            padding: 0;
        }

        .all__icons {
            .result-count {
                padding-left: 10px;
                color: #5B5F64;
                font-size: 16px;
                font-weight: 400;
            }
        }

        .from-control {
            background: none;
            border: none;
            padding: 12px 15px;
            border-radius: 6px;
            color: #5B5F64;
            font-size: 15px;
            font-weight: 400;
            cursor: pointer;
            margin-left: 15px;
        }
    }

    .shorting__courses3 {
        margin-left: 0px;
        margin-right: 0px;
        padding: 10px 0px;
        border-radius: 6px;
        margin-bottom: 30px;

        .col-md-6 {
            padding: 0;
        }

        .all__icons {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            svg {
                cursor: pointer;
                position: relative;
                top: -2px;
            }

            .grid__icons {
                display: flex;
                align-items: center;
                border: 2px solid rgba(91, 95, 100, 0.1);
                border-radius: 6px;
                padding: 0 0 0 10px;

                .grid__icons_inner {
                    width: 22px;
                    margin-right: 3px;
                }

                span {
                    background: #3270FC;
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    margin: 1px;
                    float: left;
                }
            }

            .list__icons {
                border-left: 2px solid #EFEFF0;
                margin-left: 5px;
                padding: 4px 10px;
                svg {
                    color: $bodyColor;
                }
            }

            .result-count {
                padding-left: 40px;
                color: #5c5c68;
                font-size: 15px;
                font-weight: 600;
            }
        }

        .from-control {
            background: #F2F4F9;
            border: none;
            padding: 10px 15px;
            border-radius: 6px;
            color: #5c5c68;
            font-size: 15px;
            font-weight: 500;
            cursor: pointer;
            border-right: 15px solid #F2F4F9;
        }
    }

    &.back__course__page_grid_left {
        background: #ffff;

        .single-studies .inner-course {
            box-shadow: 0px 1px 2px rgba(15, 18, 22, 0.1);
            border-radius: 6px;
        }

        .back-pagination {
            justify-content: start;

            li {
                a {
                    border: 2px solid rgba(15, 18, 22, 0.1);
                    box-shadow: none;
                    line-height: 34px;

                    &:hover {
                        border-color: #3270FC;
                    }
                }
            }
        }

        .react-list {
            .single-studies {
                .inner-course {
                    display: flex;
                    background: #fff;
                    box-shadow: 0px 30px 30px rgba(15, 18, 22, 0.1);
                    border-radius: 6px;

                    .case-img {
                        flex: 0 0 56%;

                        img {
                            border-radius: 0;
                        }
                    }

                    .case-content {
                        flex: 0 0 44%;
                    }
                }
            }
        }
    }

    &.back__course__page_list_left {
        .shorting__courses3{
            .all__icons {
                .grid__icons span {
                    background: $bodyColor;
                }
                .list__icons svg {
                    color: $primaryColor;
                }
            }
        } 
    }
}

.react-upcoming__event_page {
    background: #F2F4F9;

    .event__card {
        margin-bottom: 25px;
    }

    .shorting__course3 {
        margin-left: 0px;
        margin-right: 0px;
        padding: 10px 0px;
        border-radius: 6px;
        margin-bottom: 20px;

        .col-md-6 {
            padding: 0;
        }

        .all__icons {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .result-count {
                padding-left: 0px;
                color: #5c5c68;
                font-size: 15px;
                font-weight: 600;
            }
        }

        .from-control {
            background: #fff;
            border: none;
            padding: 13px 15px;
            border-radius: 6px;
            color: #5c5c68;
            font-size: 15px;
            font-weight: 500;
            cursor: pointer;
            border-right: 15px solid #fff;
            box-shadow: 0px 1px 2px rgba(15, 18, 22, 0.1);
            width: 100%;
            max-width: 300px;
        }
    }
}

.react-upcoming__event_list {
    background: #fff;

    .event__card {
        margin-bottom: 25px;
        padding: 0;
        background: #fff;
        box-shadow: 0px 1px 2px rgba(15, 18, 22, 0.1);
        border-radius: 6px;

        .event__card--content {
            padding: 25px 30px 42px;
        }

        .event_location {
            margin-bottom: 0;
            margin-top: 16px;
        }

        .event__card--date {
            color: $primaryColor;
            margin-bottom: 8px;
        }
    }

    .shorting__course3 {
        margin-left: 0px;
        margin-right: 0px;
        padding: 10px 0px;
        border-radius: 6px;
        margin-bottom: 20px;

        .col-md-6 {
            padding: 0;
        }

        .all__icons {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .result-count {
                padding-left: 0px;
                color: #5c5c68;
                font-size: 15px;
                font-weight: 600;
            }
        }

        .from-control {
            background: #fff;
            border: none;
            padding: 13px 15px;
            border-radius: 6px;
            color: #5c5c68;
            font-size: 15px;
            font-weight: 500;
            cursor: pointer;
            border-right: 15px solid #fff;
            box-shadow: 0px 1px 2px rgba(15, 18, 22, 0.1);
            width: 100%;
            max-width: 300px;
        }
    }

    ul.related-courses {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            display: flex;
            align-items: center;
            padding-bottom: 1.2em;

            .back-ratings {
                i {
                    font-size: 10px;
                    color: #ff9415;
                }
            }

            .post-images {
                display: block;

                img {
                    display: block;
                    width: 87px;
                    height: 87px;
                    object-fit: cover;
                    border-radius: 6px;
                }
            }

            .titles {
                display: block;
                padding-left: 20px;

                h4 {
                    margin: 0 0 6px;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20px;

                    a {
                        color: #0F1216;
                    }

                    &:hover {
                        a {
                            color: #3270FC;
                        }
                    }
                }

                span {
                    font-weight: 600;
                    color: #0F1216;
                }
            }

            &:last-child {
                padding-bottom: 0;
            }
        }
    }

    .react-sidebar-event {
        border: none;
        box-shadow: none;
    }

    .widget {
        background: #fff;
        box-shadow: 0px 20px 40px rgba(15, 18, 22, 0.08);
        border-radius: 6px;

        .event-input,
        .location-input {
            position: relative;

            svg {
                position: absolute;
                top: 11px;
                right: 23px;
                color: #6F7985;
                width: 18px;
            }
        }
    }

    .blog-form {
        border: none;
        margin-bottom: 10px;

        form {
            .search-form {
                position: relative;

                button {
                    background: none !important;
                    padding: 2px 24px;
                    margin: 0;
                }
            }

            input {
                width: 100%;
                height: 48px;
                padding: 10px 30px 10px 20px;
                background: #F2F4F9;
                border: none;
                outline: none;
                border-radius: 6px;
                margin-bottom: 12px;
                border: 2px solid #F2F4F9;

                &:focus {
                    border: 2px solid $primaryColor;
                    background: none;
                }
            }

            select.from-control {
                background: #F2F4F9;
                border: none;
                padding: 14px 15px;
                border-radius: 6px;
                color: #5c5c68;
                font-size: 15px;
                font-weight: 500;
                cursor: pointer;
                border-right: 24px solid #F2F4F9;
                width: 100%;
                margin-bottom: 12px;
            }

            button {
                background: #3270FC;
                font-size: 16px;
                color: $whiteColor;
                border-radius: 6px;
                padding: 14px 30px;
                display: inline-block;
                font-weight: 600;
                transition: $transition;
                border: none;
                outline: none;
                box-shadow: none;
                cursor: pointer;
                margin-top: 25px;

                svg {
                    width: 18px;
                    color: #6F7985;
                }

                &:hover {
                    color: $whiteColor;
                    background: $secondaryColor;
                }
            }

            ::-webkit-input-placeholder {
                /* Chrome/Opera/Safari */
                color: #7b7d82;
                opacity: 1;
                font-size: 16px;
            }

            ::-moz-placeholder {
                /* Firefox 19+ */
                color: #7b7d82;
                opacity: 1;
                font-size: 16px;
            }

            :-ms-input-placeholder {
                /* IE 10+ */
                color: #7b7d82;
                opacity: 1;
                font-size: 16px;
            }

            :-moz-placeholder {
                /* Firefox 18- */
                color: #7b7d82;
                opacity: 1;
                font-size: 16px;
            }
        }
    }
}

body.courses-grid-page {
    .react-course-filter .single-studies .inner-course .case-content ul li svg {
        width: 16px;
        margin-right: 6px;

        &.feather-clock {
            font-size: 16px;
        }
    }

    .react-course-filter .single-studies .inner-course .case-content ul li {
        font-size: 14px;
        color: #777;
    }

    .react-course-filter .single-studies .inner-course .case-content .case-title {
        margin: 6px 0 15px;
        font-size: 18px;
        line-height: 26px;
        font-weight: 700;

        a {
            color: #00306e;

            &:hover {
                color: #3270fc;
            }
        }
    }

    .react-course-filter .single-studies .inner-course .case-img a {
        left: 22px;
        right: auto;
        background: #d2093c;
    }

    .react-course-filter .single-studies .inner-course .case-content .react-ratings {
        margin-top: 16px;
        padding-top: 12px;

        li.react-book {
            font-size: 14px;
            color: #777777;

            em {
                font-size: 14px;
                font-weight: 700;
                color: #e59818;
                font-style: normal;
            }

            span {
                color: #e59818;
                margin: 0 2px;
            }
        }
    }

    .react-course-filter .single-studies .inner-course .case-content .react-ratings li.price {
        color: #00306e;
        font-size: 14px;
        font-weight: 700;
    }

    .react-course-filter .single-studies .inner-course .case-img a {
        font-size: 12px;
        line-height: 14px;
        font-weight: 500;

        img {
            position: relative;
            top: -2px;
        }
    }
}


/*****************************************************
    22. Blog Page CSS Here  
****************************/
.react-blog-page {
    .single-blog {
        transition: $transition;
        background: #fff;
        box-shadow: 0px 1px 2px rgba(15, 18, 22, 0.14);
        border-radius: 6px;
        margin-bottom: 60px;

        &.no-thum-img {
            box-shadow: 0px 2px 6px rgba(15, 18, 22, 0.14);
        }

        &.blog__last {
            margin-bottom: 0px;
            padding-bottom: 0px;
            border-bottom: none;
        }

        .blog-img {
            position: relative;

            .cate {
                background: $primaryColor;
                color: $whiteColor;
                font-size: 14px;
                font-weight: 600;
                display: inline-block;
                border-radius: 4px;
                padding: 5px 10px 5px;
                line-height: 17px;
                position: absolute;
                right: 24px;
                top: 20px;
            }

            img {
                border-radius: 6px 6px 0 0;
            }
        }

        .blog-content {
            padding: 40px 40px 40px;

            .top-part {
                display: flex;
                align-items: center;
                padding-bottom: 12px;

                li {
                    font-size: 15px;
                    color: #737383;
                    padding-right: 25px;
                    font-weight: 500;

                    svg {
                        width: 18px;
                        height: 18px;
                        position: relative;
                        top: -2px;
                        margin-right: 4px;
                    }

                    &:first-child {
                        img {
                            margin-right: 8px;
                        }
                    }
                }
            }

            .blog-title {
                font-size: 34px;
                line-height: 40px;
                font-weight: 600;
                margin-bottom: 25px;

                a {
                    color: #0F1216;

                    &:hover {
                        color: #3270FC;
                    }
                }
            }

            .blog-desc {
                color: #55575c;
                margin-bottom: 30px;
            }

            .button__sec {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .blog-btn {
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: 600;
                    border-radius: 6px;
                    border: 2px solid rgba(0, 48, 110, 0.10);
                    padding: 12px 35px 12px 35px;
                    color: #0F1216;
                    display: inline-block;
                    line-height: 20px;

                    svg {
                        width: 20px;
                    }

                    &:hover {
                        background: #3270FC;
                        color: $whiteColor;
                        border-color: #3270FC;
                    }
                }

                .share-course {
                    color: #0F1216;
                    font-size: 16px;
                    font-weight: 600;
                    display: block;
                    text-align: center;
                    position: relative;

                    em {
                        width: 45px;
                        color: #3270FC;
                        border: 2px solid rgba(15, 18, 22, 0.1);
                        border-radius: 50%;
                        padding: 1px 13px;
                        display: inline-block;
                        height: 45px;
                        line-height: 37px;
                        margin-left: 8px;

                        svg {
                            width: 18px;
                            position: relative;
                            right: 2px;
                        }
                    }

                    span {
                        position: absolute;
                        background: #000;
                        padding: 7px 18px;
                        border-radius: 4px;
                        width: 133px;
                        left: 40px;
                        top: 0px;
                        opacity: 0;
                        transition: all .5s ease;

                        &:before {
                            content: "";
                            width: 0;
                            height: 0;
                            border-left: 10px solid transparent;
                            border-right: 10px solid transparent;
                            border-top: 10px solid #000;
                            position: absolute;
                            top: 100%;
                            left: 50%;
                            transform: translateX(-50%);
                        }

                        a {
                            padding: 0 7px;
                            color: #fff;
                            opacity: .6;

                            &:hover {
                                opacity: 1;
                            }
                        }
                    }

                    &:hover {
                        em {
                            background: #3270FC;
                            color: #fff;
                        }

                        span {
                            opacity: 1;
                            top: -55px;
                        }
                    }
                }
            }
        }
    }

    blockquote {
        position: relative;
        margin: 40px 0px 60px;
        padding: 50px 40px 50px 40px;
        color: #0F1216;
        box-shadow: -3px 0px 0px #3270FC, 0px 20px 40px rgba(15, 18, 22, 0.1);
        border-radius: 6px;
        font-size: 24px;
        overflow: hidden;
        line-height: 34px;
        font-weight: 400;
        z-index: 1;
        border-left: 1px solid #3270FC;

        &:before {
            content: "";
            font-size: 120px;
            position: absolute;
            background: url(../images/blog/qu.png) no-repeat right bottom;
            bottom: 0px;
            right: 47px;
            width: 107px;
            height: 107px;
            z-index: 0;
        }

        &.block__link_q {
            padding: 30px 50px 35px 95px;

            &:before {
                content: "";
                font-size: 120px;
                position: absolute;
                background: url(../images/blog/link.png) no-repeat right bottom;
                top: 50%;
                left: 40px;
                width: 30px;
                height: 30px;
                z-index: -1;
                transform: translateY(-50%);
            }
        }

        em {
            display: block;
            font-style: normal;
            color: #5B5F64;
            font-size: 16px;
            margin-top: 16px;
            font-weight: 400;
            position: relative;
            padding-left: 22px;

            &:before {
                content: "";
                position: absolute;
                bottom: 16px;
                left: 0px;
                width: 15px;
                height: 2px;
                z-index: -1;
                background: #5B5F64;
            }
        }
    }
}

.blog-post-page {
    .react-sidebar ul.related-courses li .titles span {
        color: #5B5F64;
        font-size: 15px;
        font-weight: 400;

        svg {
            width: 16px;
            margin-right: 2px;
            position: relative;
            top: -2px;
        }
    }

    .back-pagination {
        justify-content: start;
    }

    .back-pagination li a {
        border: 2px solid rgba(15, 18, 22, 0.1);
        box-shadow: none;
        line-height: 33px;
    }

    .react-sidebar .widget.react-categories-course {
        box-shadow: none;

        .widget-title {
            margin-top: -5px;
        }

        .recent-category {
            li {
                a {
                    em {
                        display: block;
                        float: right;
                        clear: right;
                        font-style: normal;
                    }
                }
            }
        }
    }

    .widget-tags {
        .tags {
            li {
                display: inline-block;
                margin: 0 5px 13px 0;

                a {
                    padding: 8px 17px 8px;
                    background: #F2F4F9;
                    font-size: 13px;
                    color: #5c5c68;
                    font-weight: 600;
                    border-radius: 4px;

                    &:hover {
                        background: #3270FC;
                        color: $whiteColor;
                    }
                }
            }
        }
    }
}


/*****************************************************
    23. Blog Details Page CSS
****************************/
.blog-post-single-page {
    .react-course-filter .single-studies .inner-course .case-content {
        padding: 30px 38px 30px 25px;

        em {
            display: block;
            font-size: 16px;
            color: #D2093C;
            font-style: normal;
        }
    }

    .share-course {
        color: #0F1216;
        font-size: 16px;
        font-weight: 600;
        float: right;
        clear: right;
        text-align: right;
        position: relative;

        em {
            width: 45px;
            color: #fff;
            background: #3270FC;
            border-radius: 50%;
            padding: 1px 13px;
            display: inline-block;
            height: 45px;
            line-height: 40px;
            margin-left: 8px;

            svg {
                width: 18px;
                position: relative;
                right: 2px;
            }
        }

        span {
            position: absolute;
            background: #000;
            padding: 7px 18px;
            border-radius: 4px;
            width: 133px;
            left: 75px;
            top: 0px;
            opacity: 0;
            transition: all .5s ease;

            &:before {
                content: "";
                width: 0;
                height: 0;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-top: 10px solid #000;
                position: absolute;
                top: 100%;
                left: 50%;
                transform: translateX(-50%);
            }

            a {
                padding: 0 7px;
                color: #fff;
                opacity: .6;

                &:hover {
                    opacity: 1;
                }
            }
        }

        &:hover {
            em {
                opacity: .9;
            }

            span {
                opacity: 1;
                top: -55px;
            }
        }
    }

    .blog-single-inner {
        .blog-image {
            margin-bottom: 42px;
        }

        .blog-content {
            color: #3e3e51;

            p:first-child {
                margin: -8px 0 0;
                overflow: hidden;
            }

            p {
                margin-bottom: 40px;
                line-height: 28px;
                font-size: 18px;
                font-weight: 400;
                color: #5B5F64;
            }

            h3 {
                color: #0F1216;
                font-weight: 600;
                font-size: 34px;
                line-height: 43px;
            }

            .back-order-list {
                h3 {
                    color: #0F1216;
                    font-weight: 600;
                    font-size: 34px;
                    line-height: 43px;
                }

                ul {
                    li {
                        margin: 20px 0;
                        font-size: 16px;
                        font-weight: 400;
                        color: #0F1216;

                        i {
                            background: rgba(50, 112, 252, 0.08);
                            padding: 6px;
                            border-radius: 50%;
                            margin-right: 12px;
                        }
                    }
                }
            }

            blockquote {
                position: relative;
                margin: 40px 0px 60px;
                padding: 38px 40px 40px 40px;
                color: #0F1216;
                box-shadow: -3px 0px 0px #3270FC, 0px 20px 40px rgba(15, 18, 22, 0.1);
                border-radius: 6px;
                font-size: 24px;
                overflow: hidden;
                line-height: 34px;
                font-weight: 400;
                z-index: 1;
                border-left: 1px solid #3270FC;

                &:before {
                    content: "";
                    font-size: 120px;
                    position: absolute;
                    background: url(../images/blog/qu.png) no-repeat right bottom;
                    bottom: 0px;
                    right: 47px;
                    width: 107px;
                    height: 107px;
                    z-index: 0;
                }

                &.block__link_q {
                    &:before {
                        content: "";
                        font-size: 120px;
                        position: absolute;
                        background: url(../images/blog/link.png) no-repeat right bottom;
                        bottom: -6px;
                        right: 47px;
                        width: 180px;
                        height: 180px;
                        z-index: -1;
                    }
                }

                em {
                    display: block;
                    font-style: normal;
                    color: #5B5F64;
                    font-size: 16px;
                    margin-top: 16px;
                    font-weight: 400;
                    position: relative;
                    padding-left: 22px;

                    &:before {
                        content: "";
                        position: absolute;
                        bottom: 16px;
                        left: 0px;
                        width: 15px;
                        height: 2px;
                        z-index: -1;
                        background: #5B5F64;
                    }
                }
            }

            .blog-tags {
                margin-top: 40px;
                display: block;
                padding: 30px 0 25px;
                border-top: 1px solid #ecedf3;

                ul.mata-tags {
                    li {
                        display: inline-block;
                        margin-right: 5px;

                        a {
                            padding: 3px 20px;
                            background: #F2F4F9;
                            font-size: 13px;
                            color: #4D5765;
                            font-weight: 600;
                            display: block;
                            border-radius: 6px;

                            &:hover {
                                background: #3270FC;
                                color: $whiteColor;
                            }
                        }

                        &:last-child {
                            margin: 0;
                        }

                        &.tags {
                            font-size: 16px;
                            font-weight: 600;
                            color: #000;
                            margin-right: 20px;
                        }
                    }
                }
            }

            .post-author {
                display: flex;
                align-items: center;
                padding: 40px;
                overflow: hidden;
                margin-top: 30px;
                background: #fff;
                box-shadow: 0px 20px 40px rgba(15, 18, 22, 0.1);
                border-radius: 6px;

                .avatar {
                    margin-right: 20px;

                    img {
                        border-radius: 50%;
                        width: 122px;
                    }
                }

                .info {
                    .name {
                        margin: 0;
                        color: #0F1216;
                        transition: $transition;
                        font-size: 18px;
                        font-weight: 700;
                    }

                    .designation {
                        a {
                            font-size: 14px;
                            color: #4D5765;
                            margin-right: 8px;

                            &:hover {
                                color: #3270FC;
                            }
                        }
                    }
                }

                p {
                    font-size: 16px;
                    color: #5B5F64;
                    margin: 9px 0 12px;
                    line-height: 24px;
                }
            }

            .author-comment {
                margin-bottom: 0px;
                margin-top: 0px;

                h4 {
                    margin: 36px 0 20px;
                    font-size: 20px;
                    font-weight: 700;
                    color: #0F1216;
                }

                span {
                    font-size: 15px;
                    color: #7f7d86;

                    i {
                        &:before {
                            font-size: 13px;
                            padding-right: 3px;
                            position: relative;
                            top: -1px;
                        }
                    }
                }

                h6 {
                    margin: 0 0 3px;
                    font-size: 16px;
                    color: #0F1216;
                    font-weight: 600;
                }

                ul {
                    padding: 0;
                    margin: 0;

                    li {
                        margin: 0 0 35px;
                        padding: 30px 57px 32px 35px;
                        border: 1px solid rgba(0, 48, 110, 0.1);
                        border-radius: 6px;

                        .col-sm-2 {
                            text-align: left;
                            padding-right: 6px;
                        }

                        &:nth-child(2) {
                            margin-left: 120px;
                            padding: 35px 52px 32px 35px;

                            .col-sm-2 {
                                padding-right: 6px;
                            }

                            .image-comments {
                                img {
                                    width: 55px;
                                }
                            }
                        }

                        &:nth-child(3) {
                            margin-left: 120px;
                            padding: 35px 52px 38px 35px;

                            .col-sm-2 {
                                padding-right: 6px;
                            }

                            .image-comments {
                                img {
                                    width: 55px;
                                }
                            }
                        }

                        .image-comments {
                            margin-top: 0;

                            img {
                                width: 55px;
                                border-radius: 100%;
                            }
                        }

                        .reply {
                            font-size: 14px;
                            color: #5B5F64;
                            font-weight: 400;

                            a {
                                color: $primaryColor;

                                &:hover {
                                    color: $primaryColor;
                                }
                            }
                        }

                        .dsc-comments {
                            h4 {
                                margin: 0 0 12px;
                            }

                            a {
                                padding: 4px 0;
                                font-size: 14px;
                                color: #0F1216;
                                font-weight: 600;
                                border-radius: 6px;

                                svg {
                                    width: 16px;
                                    position: relative;
                                    top: 0px;
                                    margin-right: 2px;
                                }

                                &:hover {
                                    color: #3270FC;
                                }
                            }

                            p {
                                margin: 12px 0 15px;
                                color: #55575c;
                                font-size: 16px;
                                line-height: 24px;
                            }
                        }
                    }
                }
            }

            .blog-form {
                margin-top: 98px;
                z-index: 9;
                position: relative;

                .back-input {
                    position: relative;

                    img {
                        position: absolute;
                        bottom: 55px;
                        left: 30px;
                    }
                }

                .back-textarea {
                    img {
                        position: absolute;
                        top: 57px;
                        left: 47px;
                        z-index: 1;
                    }
                }

                h3 {
                    font-size: 20px;
                    color: #0F1216;
                    font-weight: 700;
                    margin-bottom: 0px;
                }

                form {
                    p {
                        color: #5B5F64;
                        font-size: 16px;
                        font-weight: 400;
                    }

                    input {
                        width: 100%;
                        height: 57px;
                        padding: 10px 30px 10px 30px;
                        background: #F2F4F9;
                        border: none;
                        outline: none;
                        border-radius: 10px;
                        margin-bottom: 25px;
                        border: 2px solid #F2F4F9;

                        &:focus {
                            border: 2px solid #3270FC;
                            background: none;
                        }
                    }

                    textarea {
                        width: 100%;
                        height: 160px;
                        padding: 22px 30px;
                        background: #F2F4F9;
                        border: none;
                        outline: none;
                        border-radius: 10px;
                        margin-bottom: 25px;
                        position: relative;
                        border: 2px solid #F2F4F9;

                        &:focus {
                            border: 2px solid #3270FC;
                            background: none;
                        }
                    }

                    button {
                        background: #3270FC;
                        font-size: 16px;
                        color: $whiteColor;
                        border-radius: 10px;
                        padding: 10px 15px;
                        display: inline-block;
                        font-weight: 600;
                        transition: $transition;
                        border: none;
                        outline: none;
                        box-shadow: none;
                        cursor: pointer;
                        width: 100%;
                        max-width: 210px;

                        svg {
                            width: 18px;
                            position: relative;
                            top: -1px;
                            margin-left: 2px;
                        }

                        &:hover {
                            color: $whiteColor;
                            opacity: .8;
                        }
                    }

                    .back-check-box {
                        align-items: center;
                        margin-top: -6px;
                        margin-bottom: 30px;
                        color: #55575c;
                        font-size: 16px;

                        a {
                            color: #1a152e;

                            &:hover {
                                color: #74727d;
                            }
                        }

                        input {
                            width: auto;
                            margin: 0 8px 0 0;
                            height: 16px;
                            width: 16px;
                        }
                    }

                    ::-webkit-input-placeholder {
                        /* Chrome/Opera/Safari */
                        color: #7b7d82;
                        opacity: 1;
                        font-size: 16px;
                    }

                    ::-moz-placeholder {
                        /* Firefox 19+ */
                        color: #7b7d82;
                        opacity: 1;
                        font-size: 16px;
                    }

                    :-ms-input-placeholder {
                        /* IE 10+ */
                        color: #7b7d82;
                        opacity: 1;
                        font-size: 16px;
                    }

                    :-moz-placeholder {
                        /* Firefox 18- */
                        color: #7b7d82;
                        opacity: 1;
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .single-nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 80px;
        position: relative;
        border-top: 1px solid #E8ECF0;
        border-bottom: 1px solid #E8ECF0;
        padding: 20px 0 30px;

        .back-next {
            text-align: right;
        }

        .back-prev,
        .back-next {
            a {
                color: #4D5765;
                font-size: 12px;
                font-weight: 600;

                em {
                    display: block;
                    font-style: normal;
                    font-weight: 600;
                    color: #0F1216;
                    font-size: 18px;
                    line-height: 18px;
                }

                &:hover {
                    color: #3270FC;

                    em {
                        color: #3270FC;
                    }
                }
            }
        }

        .back-prev {
            position: relative;
            padding-left: 55px;
            transition: all 500ms ease;

            i:before {
                font-size: 23px;
                margin: 0;
                font-weight: 600;
                position: absolute;
                top: 8px;
                left: 0;
                transition: all 500ms ease;
                border: 2px solid rgba(15, 18, 22, 0.08);
                padding: 6px;
                border-radius: 50%;
            }

            &:hover {
                i:before {
                    left: -6px;
                }
            }
        }

        .back-next {
            position: relative;
            padding-right: 55px;
            transition: all 500ms ease;

            i:before {
                font-size: 23px;
                margin: 0;
                font-weight: 600;
                position: absolute;
                top: 8px;
                right: 0;
                transition: all 500ms ease;
                border: 2px solid rgba(15, 18, 22, 0.08);
                padding: 6px;
                border-radius: 50%;
            }

            &:hover {
                i:before {
                    right: -6px;
                }
            }
        }

        .social-links {
            svg {
                position: relative;
                top: 6px;
            }
        }
    }
}

/*****************************************************
    24. Contact Page CSS Here 
****************************/

.react-contact-page {
    h2.contact-title {
        font-size: 34px;
        color: #0F1216;
        font-weight: 700;
        line-height: 42px;
        text-align: left;
    }

    .react-blog-form {
        padding-left: 70px;
    }

    .blog-form {
        z-index: 9;
        position: relative;
        padding-top: 15px;

        .back-input {
            position: relative;

            img {
                position: absolute;
                bottom: 55px;
                left: 30px;
            }
        }

        .react-textarea {
            img {
                position: absolute;
                top: 57px;
                left: 47px;
                z-index: 1;
            }
        }

        form {
            label {
                color: #1a152e;
                font-size: 14px;
                font-weight: 500;
            }

            input {
                width: 100%;
                height: 57px;
                padding: 10px 30px 10px 30px;
                background: #F5F8FD;
                border: none;
                outline: none;
                border-radius: 6px;
                margin-bottom: 20px;
                border: 2px solid #F5F8FD;

                &:focus {
                    border: 2px solid #3270FC;
                    background: none;
                }
            }

            textarea {
                width: 100%;
                height: 160px;
                padding: 22px 30px;
                background: #F5F8FD;
                border: none;
                outline: none;
                border-radius: 6px;
                margin-bottom: 20px;
                position: relative;
                border: 2px solid #F5F8FD;

                &:focus {
                    border: 2px solid #3270FC;
                    background: none;
                }
            }

            button {
                background: #3270FC;
                font-size: 16px;
                color: $whiteColor;
                border-radius: 6px;
                padding: 14px 38px;
                display: block;
                font-weight: 600;
                transition: $transition;
                border: none;
                outline: none;
                box-shadow: none;
                cursor: pointer;

                svg {
                    width: 18px;
                }

                &:hover {
                    color: $whiteColor;
                    opacity: .9;
                }
            }

            .react-check-box {
                align-items: center;
                margin-top: 10px;
                margin-bottom: 20px;
                color: #55575c;
                font-size: 16px;

                a {
                    color: #1a152e;

                    &:hover {
                        color: #74727d;
                    }
                }

                input {
                    width: auto;
                    margin: 0 8px 0 0;
                    height: 16px;
                    width: 16px;
                }
            }

            ::-webkit-input-placeholder {
                /* Chrome/Opera/Safari */
                color: #7b7d82;
                opacity: 1;
                font-size: 16px;
            }

            ::-moz-placeholder {
                /* Firefox 19+ */
                color: #7b7d82;
                opacity: 1;
                font-size: 16px;
            }

            :-ms-input-placeholder {
                /* IE 10+ */
                color: #7b7d82;
                opacity: 1;
                font-size: 16px;
            }

            :-moz-placeholder {
                /* Firefox 18- */
                color: #7b7d82;
                opacity: 1;
                font-size: 16px;
            }
        }
    }

    .address-sec {
        padding: 35px;
        background: linear-gradient(138.95deg, #F2F4F9 0%, rgba(242, 244, 249, 0) 63.81%);
        border-radius: 10px;

        li {
            display: flex;
            padding-bottom: 35px;

            .icon {
                margin-right: 25px;
            }

            .text {
                font-size: 16px;
                line-height: 24px;
                color: #4D5765;

                em {
                    color: #0F1216;
                    font-weight: 600;
                    font-size: 20px;
                    display: block;
                    font-style: normal;
                    margin: 0 0 8px;
                }

                a {
                    display: block;
                    color: #0F1216;

                    &:hover {
                        color: #3270FC;
                    }
                }
            }
        }
    }

    .react-contacts {
        .back-title-sec h2 {
            font-weight: 600;
            font-size: 40px;
        }

        .react-image-maping {
            position: relative;
            padding-top: 15px;

            .react-tooltip1 {
                left: 50%;
                top: 50%;
                display: block;
            }

            .react-ripple {
                background-color: #f94392;
                border-radius: 50%;
                animation: pi-ripple 0.7s linear infinite;
                position: absolute;
                cursor: pointer;

                .box {
                    position: relative;
                    width: 16px;
                    height: 16px;

                    span {
                        font-size: 16px;
                        color: #1a152e;
                        font-weight: 600;
                    }
                }
            }

            @keyframes pi-ripple {
                0% {
                    box-shadow: 0 0 0 0 rgba(249, 67, 146, 0.05),
                        0 0 0 16px rgba(249, 67, 146, 0.05),
                        0 0 0 20px rgba(249, 67, 146, 0.05),
                        0 0 0 24px rgba(249, 67, 146, 0.05);
                }

                100% {
                    box-shadow: 0 0 0 16px rgba(249, 67, 146, 0.05),
                        0 0 0 20px rgba(249, 67, 146, 0.05),
                        0 0 0 24px rgba(249, 67, 146, 0.05),
                        0 0 0 38px rgba(249, 67, 146, 0);
                }
            }
        }

        .react-ripple:hover span {
            opacity: 1;
            filter: alpha(opacity=100);
            top: -60px;
            left: -18px;
            z-index: 99;
            -webkit-transition: all 0.2s ease;
            -moz-transition: all 0.2s ease;
            -o-transition: all 0.2s ease;
            transition: all 0.2s ease;
        }

        .react-ripple span {
            background: $whiteColor;
            /*-- some basic styling */
            font-weight: normal;
            padding: 8px 0px;
            width: 140px;
            top: -20px;
            /*-- this is the original position of the tooltip when it's hidden */
            left: -18px;
            margin-left: 0;
            /*-- set opacity to 0 otherwise our animations won't work */
            opacity: 0;
            filter: alpha(opacity=0);
            position: absolute;
            text-align: center;
            z-index: 2;
            text-transform: none;
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease;
            transition: all 0.3s ease-in-out;
            border-radius: 4px;
        }

        .react-ripple span:after {
            border-color: $whiteColor rgba(0, 0, 0, 0);
            border-style: solid;
            border-width: 10px 8px 0;
            bottom: -10px;
            content: "";
            display: block;
            left: 18px;
            position: absolute;
            width: 0;
        }
    }
}

/*****************************************************
    25. Courses Section CSS Here  
****************************/
body.course-single {
    .react-breadcrumbs .breadcrumbs-wrap .breadcrumbs-inner .breadcrumbs-text {
        text-align: left;

        .cate {
            background: $primaryColor;
            color: $whiteColor;
            font-size: 14px;
            font-weight: 600;
            display: inline-block;
            border-radius: 4px;
            padding: 2px 10px 5px;
            line-height: 17px;
            margin-bottom: 12px;
        }
    }

    .user-section {
        display: flex;
        align-items: center;
        padding-bottom: 50px;

        li {
            padding-right: 40px;
            font-size: 16px;
            color: #fff;
            font-weight: 400;

            svg {
                width: 18px;
                position: relative;
                top: -2px;
                margin-right: 4px;
            }

            &.user {
                display: flex;
                align-items: center;

                span {
                    padding-right: 12px;

                    img {
                        width: 36px;
                        border-radius: 50%;
                    }
                }
            }
        }
    }
}

.react-courses__single-page {
    .course-single-tab {
        .nav.nav-tabs {
            padding: 0 0 1px 0;
            margin-top: 0px;
            background: #F2F4F9;
            border-radius: 6px;
            margin-bottom: 50px;
            border: none;

            li {
                button {
                    border: none;
                    color: #5B5F64;
                    background: transparent;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 18px;
                    padding: 19px 25px;
                    text-align: center;
                    display: inline-block;
                    margin: 10px 8px;
                    &:hover {
                        background: #fff;
                        box-shadow: 0px 6px 10px rgba(15, 18, 22, 0.1);
                        border-radius: 6px;
                        color: #0F1216;
                    }
                }
                &.react-tabs__tab--selected {
                    button {
                        background: #fff;
                        box-shadow: 0px 6px 10px rgba(15, 18, 22, 0.1);
                        border-radius: 6px;
                        color: #0F1216;
                    }
                }
            }
        }

        #back-tab-content {
            h3 {
                font-size: 26px;
                color: #0F1216;
                font-weight: 600;
                margin: 0 0 20px;
            }

            p {
                font-size: 16px;
                color: #4b4b57;
            }

            .back-tag {
                a {
                    font-size: 16px;
                    font-weight: 500;
                    color: #737383;

                    &:hover {
                        color: $primaryColor;
                    }
                }

                svg {
                    width: 18px;
                    height: 18px;
                    margin-right: 4px;
                }
            }

            .back-objectives {
                background: #f7f7f8;
                overflow: hidden;
                padding: 40px;
                border-radius: 2px;
                margin-top: 50px;
                margin-bottom: 40px;

                h3 {
                    font-size: 20px;
                    color: $titleColor;
                    font-weight: 800;
                    margin: 0 0 20px;
                }

                li {
                    float: left;
                    width: 50%;
                    font-size: 15px;
                    font-weight: 600;
                    color: $titleColor;
                    position: relative;
                    padding-left: 42px;
                    margin: 10px 0 17px;
                    line-height: 1.3;

                    &:first-child {
                        float: none;
                        width: 100%;
                        padding: 0;
                    }

                    i {
                        background: $primaryColor;
                        padding: 6px;
                        color: #ffffff;
                        border-radius: 50%;
                        display: inline-block;
                        position: absolute;
                        left: 0;
                    }
                }
            }

            .single-week {
                background: #f7f7f8;
                padding: 40px 40px 30px 40px;
                border-radius: 6px;
                margin-bottom: 20px;

                .week__top {
                    display: flex;
                    border-bottom: 1px solid rgba(2, 3, 52, 0.078);
                    justify-content: space-between;
                    font-size: 20px;
                    font-weight: 800;
                    padding-bottom: 10px;
                    margin-bottom: 28px;
                    color: $titleColor;

                    li {
                        &:last-child {
                            color: #828287;
                            font-weight: 700;
                        }
                    }
                }

                .course__title {
                    li {
                        border-bottom: 1px solid rgba(2, 3, 52, 0.078);
                        color: #4b4c57;
                        padding: 12px 0 12px;
                        overflow: hidden;

                        svg {
                            width: 20px;
                            height: 20px;
                            margin-right: 7px;
                            position: relative;
                            top: -2px;
                        }

                        em {
                            font-style: normal;
                            float: right;

                            svg {
                                width: 17px;
                                height: 17px;
                                margin-right: 3px;
                                position: relative;
                                top: -2px;
                            }

                            &.questions {
                                background-color: rgba(248, 69, 45, 0.102);
                                color: #f8452d;
                                padding: 6px 10px;
                                line-height: 1;
                                border-radius: 6px;
                                margin-left: 20px;
                                font-weight: 500;
                            }
                        }

                        b {
                            font-weight: 700;
                            color: $titleColor;
                        }

                        &:last-child {
                            border: none;
                        }
                    }
                }
            }

            .skillbar-style2 {
                border: 1px solid rgba(0, 48, 110, 0.1);
                border-radius: 6px;
                padding: 40px;

                .skill_inners {
                    display: flex;
                    align-items: center;
                    margin-bottom: 15px;

                    span.skillbar-title {
                        flex: 0 0 15%;
                        color: #5B5F64;
                        font-size: 14px;
                    }

                    span {
                        color: #5B5F64;
                        
                    }
                }

                .skillbars {
                    position: relative;
                    display: block;
                    background: #E6E9F1;
                    height: 10px;
                    width: 100%;
                    clear: both;
                    margin: 0 20px 0 0;
                    border-radius: 0px;

                    .skillbar-bar {
                        height: 10px;
                        width: 0;
                        background: #3270FC;
                        
                        display: block;
                        margin: 0;
                        position: relative;
                        border-radius: 0px;
                    }
                }
            }

            .five__number {
                border: 1px solid rgba(0, 48, 110, 0.1);
                text-align: center;
                padding: 75px 50px;
                border-radius: 6px;

                em {
                    font-size: 50px;
                    color: #0F1216;
                    font-style: normal;
                    line-height: 1.2;
                    margin-bottom: 12px;
                    font-weight: 700;
                    display: block;
                }

                i {
                    color: #ff9415;
                    font-size: 14px;
                }

                h6 {
                    color: #0F1216;
                }
            }

            .post-author {
                display: flex;
                align-items: center;
                padding: 25px 40px 30px;
                overflow: hidden;
                margin-bottom: 20px;
                border: 1px solid rgba(0, 48, 110, 0.1);
                border-radius: 6px;

                .avatar {
                    margin-right: 20px;

                    img {
                        border-radius: 50%;
                        width: 80px;
                    }
                }

                .info {
                    .back-ratings {
                        i {
                            color: #ff9415;
                            font-size: 14px;
                        }
                    }

                    .name {
                        margin: 0;
                        color: #0F1216;
                        transition: $transition;
                        font-size: 20px;
                        font-weight: 600;

                        .designation {
                            font-size: 16px;
                            color: #5B5F64;
                            margin: 0;
                            font-weight: 400;
                        }
                    }

                }

                p {
                    font-size: 16px;
                    color: #4b4b57;
                    margin: 0;
                    line-height: 24px;
                }

                &:hover {
                    .info {
                        .name {
                            color: $primaryColor;
                        }
                    }
                }
            }

            .blog-form {
                p {
                    margin-top: -15px;
                }

                form {
                    .back-ratings {
                        padding-bottom: 25px;
                        padding-top: 6px;
                        line-height: 1;

                        i {
                            font-size: 14px;
                            color: #b8b9bf;

                            &:nth-child(1),
                            &:nth-child(2) {
                                color: #ff9415;
                            }
                        }
                    }

                    input {
                        width: 100%;
                        height: 57px;
                        padding: 10px 30px 10px 30px;
                        background: #F2F4F9;
                        border: none;
                        outline: none;
                        border-radius: 6px;
                        margin-bottom: 25px;
                        border: 2px solid #F2F4F9;

                        &:focus {
                            border: 2px solid $primaryColor;
                            background: none;
                        }
                    }

                    textarea {
                        width: 100%;
                        height: 160px;
                        padding: 22px 30px;
                        background: #F2F4F9;
                        border: none;
                        outline: none;
                        border-radius: 6px;
                        margin-bottom: 5px;
                        position: relative;
                        border: 2px solid #F2F4F9;

                        &:focus {
                            border: 2px solid $primaryColor;
                            background: none;
                        }
                    }

                    button {
                        background: #3270FC;
                        font-size: 16px;
                        color: $whiteColor;
                        border-radius: 6px;
                        padding: 14px 30px;
                        display: inline-block;
                        font-weight: 600;
                        transition: $transition;
                        border: none;
                        outline: none;
                        box-shadow: none;
                        cursor: pointer;
                        margin-top: 25px;

                        svg {
                            width: 18px;
                        }

                        &:hover {
                            color: $whiteColor;
                            background: $secondaryColor;
                        }
                    }

                    .back-check-box {
                        align-items: center;
                        margin-top: 10px;
                        margin-bottom: 20px;
                        color: #55575c;
                        font-size: 16px;

                        a {
                            color: #1a152e;

                            &:hover {
                                color: #74727d;
                            }
                        }

                        input {
                            width: auto;
                            margin: 0 8px 0 0;
                            height: 16px;
                            width: 16px;
                        }
                    }

                    ::-webkit-input-placeholder {
                        /* Chrome/Opera/Safari */
                        color: #7b7d82;
                        opacity: 1;
                        font-size: 16px;
                    }

                    ::-moz-placeholder {
                        /* Firefox 19+ */
                        color: #7b7d82;
                        opacity: 1;
                        font-size: 16px;
                    }

                    :-ms-input-placeholder {
                        /* IE 10+ */
                        color: #7b7d82;
                        opacity: 1;
                        font-size: 16px;
                    }

                    :-moz-placeholder {
                        /* Firefox 18- */
                        color: #7b7d82;
                        opacity: 1;
                        font-size: 16px;
                    }
                }
            }

            .social-links {
                h4 {
                    font-size: 16px;
                    font-weight: 800;
                    margin: 0 0 26px;
                }

                li {
                    a {
                        border-radius: 6px;
                    }

                    &:first-child {
                        float: none;
                        width: 100%;
                        padding: 0;
                        display: block;
                    }
                }
            }
        }
    }
}

.back-pagination {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;

    li {
        a {
            width: 40px;
            height: 40px;
            background: #FFFFFF;
            box-shadow: 0px 1px 2px rgba(15, 18, 22, 0.1);
            display: inline-block;
            line-height: 40px;
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            border-radius: 50%;
            color: #5B5F64;
            margin: 0 5px;

            &:hover {
                background: #3270FC;
                color: $whiteColor;
            }

            svg {
                width: 18px;
                position: relative;
                transition: $transition;
                top: -1px;
            }
        }
    }
}

/*****************************************************
    26. Sidebar Section CSS Here  
****************************/
.react-sidebar {
    background: #fff;
    box-shadow: 0px 20px 40px rgba(15, 18, 22, 0.08);
    border-radius: 6px;

    &.react-back-course2 {
        box-shadow: none;
    }

    .widget {
        padding: 35px;
        border-bottom: 1px solid #E8ECF0;

        &.back-post {
            .widget-title {
                margin-top: -10px;
            }
        }

        &.back-category {
            padding: 30px 35px 30px 35px;
        }

        &.related__courses {
            background: $whiteColor;
            box-shadow: 0px 30px 50px 0px rgba(1, 11, 60, 0.1);
            margin-top: 22px;
        }

        &.react-categories-course {
            box-shadow: 0px 30px 50px 0px rgba(1, 11, 60, 0.1);
            background: $whiteColor;
            border-radius: 6px;

            ul {
                li {
                    a {
                        font-size: 16px;
                        color: #5B5F64;
                        font-weight: 400;
                        line-height: 32px;
                        display: block;

                        &:hover {
                            color: #3270FC;
                        }
                    }
                }
            }
        }

        &.get-back-course {
            box-shadow: 0px 30px 50px 0px rgba(1, 11, 60, 0.1);
            background: $whiteColor;
            margin-bottom: 10px;
            border-radius: 6px;
            padding: 0;

            .price {
                display: flex;
                align-items: center;
                padding: 25px 40px 25px;
                border-bottom: 1px solid #E8ECF0;

                li {
                    font-size: 30px;
                    font-weight: 700;
                    color: #3270FC;
                    line-height: 1;

                    &:nth-child(2) {
                        font-size: 16px;
                        font-weight: 600;
                        text-decoration: line-through;
                        margin-left: 9px;
                        color: #9DA1A6;
                    }

                    &:nth-child(3) {
                        font-size: 13px;
                        color: #D2093C;
                        background: rgba(210, 9, 60, 0.06);
                        border-radius: 4px;
                        margin-left: 10px;
                        padding: 5px 6px;
                        font-weight: 600;
                    }
                }
            }

            .start-btn {
                border-radius: 6px;
                width: auto;
                text-align: center;
                background: #3270FC;
                color: #fff;
                padding: 11px 25px;
                display: block;
                font-size: 16px;
                font-weight: 600;
                margin-left: 35px;
                margin-right: 35px;
                margin-top: 30px;
                margin-bottom: 25px;

                svg {
                    width: 18px;
                }

                &:hover {
                    opacity: .9;
                }
            }

            .share-course {
                color: #0F1216;
                font-size: 16px;
                font-weight: 600;
                display: block;
                text-align: center;
                padding-bottom: 30px;
                position: relative;

                em {
                    width: 45px;
                    color: #3270FC;
                    border: 2px solid rgba(15, 18, 22, 0.1);
                    border-radius: 50%;
                    padding: 1px 13px;
                    display: inline-block;
                    height: 45px;
                    line-height: 37px;
                    margin-left: 8px;

                    svg {
                        width: 18px;
                        position: relative;
                        right: 2px;
                    }
                }

                span {
                    position: absolute;
                    background: #000;
                    padding: 7px 18px;
                    border-radius: 4px;
                    width: 133px;
                    left: 190px;
                    top: 0px;
                    opacity: 0;
                    transition: all .5s ease;

                    &:before {
                        content: "";
                        width: 0;
                        height: 0;
                        border-left: 10px solid transparent;
                        border-right: 10px solid transparent;
                        border-top: 10px solid #000;
                        position: absolute;
                        top: 100%;
                        left: 50%;
                        transform: translateX(-50%);
                    }

                    a {
                        padding: 0 7px;
                        color: #fff;
                        opacity: .6;

                        &:hover {
                            opacity: 1;
                        }
                    }
                }

                &:hover {
                    em {
                        background: #3270FC;
                        color: #fff;
                    }

                    span {
                        opacity: 1;
                        top: -55px;
                    }
                }
            }

            .price__course {
                padding-left: 40px;
                padding-right: 40px;
                padding-top: 20px;

                li {
                    padding: 10px 0;
                    box-shadow: 0px 1px 0px #E8ECF0;
                    color: #0F1216;

                    i {
                        position: relative;
                        color: #0F1216;
                        display: inline-block;
                        margin-right: 10px;
                        font-size: 14px;
                    }

                    b {
                        font-size: 16px;
                        font-weight: 400;
                        color: #5B5F64;
                        float: right;
                        clear: right;
                    }

                    b.prs {
                        font-size: 30px;
                        color: #3270FC;
                        font-weight: 700;
                        line-height: 26px;
                    }

                    &:last-child {
                        border: none;
                    }
                }
            }
        }

        &.back-rating {
            ul.recent-category input[type="checkbox"]+label::before {
                border-radius: 50%;
                margin-right: 10px;
                top: 0;
            }

            em {
                font-style: normal;
                display: inline-block;
                margin-left: 6px;
            }

            i {
                color: #FFA439;
                font-size: 14px;
            }
        }

        &.back-price {
            ul.recent-category input[type="checkbox"]+label::before {
                border-radius: 50%;
                margin-right: 10px;
                top: 0;
            }
        }

        &.react-date-sec {
            box-shadow: 0px 30px 50px 0px rgba(1, 11, 60, 0.1);
            background: #ffffff;
            margin-bottom: 10px;
            border-radius: 6px;

            ul {
                li {
                    font-size: 16px;
                    color: #0F1216;
                    font-weight: 600;
                    margin-bottom: 20px;

                    b {
                        color: #5B5F64;
                        font-weight: 400;
                        font-size: 16px;
                        display: block;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    ul.recent-category {
        margin: -8px 0 0;
        padding: 0;
        list-style: none;

        li {
            margin: 8px 0;
        }

        input[type=checkbox]+label {
            cursor: pointer;
        }

        input[type=checkbox] {
            display: none;
        }

        label {
            color: #5B5F64;
            font-size: 16px;
            font-weight: 400;
        }

        input[type=checkbox]+label:before {
            content: "\2714";
            border: 0.1em solid #d9d8d8;
            border-radius: 4px;
            display: inline-block;
            width: 23px;
            height: 23px;
            line-height: 11px;
            margin-right: 12px;
            vertical-align: middle;
            color: transparent;
            transition: .2s;
            background: #ffffff;
            padding: 5px 6px;
            font-size: 13px;
            position: relative;
            top: -2px;
        }

        input[type=checkbox]+label:active:before {
            transform: scale(0);
        }

        input[type=checkbox]:checked+label:before {
            background-color: #3270FC;
            border-color: #3270FC;
            color: #fff;
        }

        input[type=checkbox]:disabled+label:before {
            transform: scale(1);
            border-color: #eee;
        }
    }

    ul.related-courses {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            display: flex;
            align-items: center;
            padding-bottom: 1.2em;

            .back-ratings {
                i {
                    font-size: 10px;
                    color: #ff9415;
                }
            }

            .post-images {
                display: block;

                img {
                    display: block;
                    width: 87px;
                    height: 87px;
                    object-fit: cover;
                    border-radius: 6px;
                }
            }

            .titles {
                display: block;
                padding-left: 20px;

                h4 {
                    margin: 0 0 6px;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20px;

                    a {
                        color: #0F1216;
                    }

                    &:hover {
                        a {
                            color: #3270FC;
                        }
                    }
                }

                span {
                    font-weight: 600;
                    color: #0F1216;
                }
            }

            &:last-child {
                padding-bottom: 0;
            }
        }
    }

    ul.recent-posts {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            display: flex;
            align-items: center;
            padding-bottom: 1.2em;

            .back-ratings {
                i {
                    font-size: 10px;
                    color: #ff9415;
                }
            }

            .post-images {
                display: block;

                img {
                    display: block;
                    width: 87px;
                    height: 87px;
                    object-fit: cover;
                    border-radius: 6px;
                }
            }

            .titles {
                display: block;
                padding-left: 20px;

                h4 {
                    margin: 0 0 6px;
                    font-weight: 800;
                    font-size: 16px;
                    line-height: 20px;

                    a {
                        color: $titleColor;
                    }

                    &:hover {
                        a {
                            color: $primaryColor;
                        }
                    }
                }

                span {
                    font-weight: 600;
                    color: #737383;
                }
            }

            &:last-child {
                padding-bottom: 0;
            }
        }
    }

    h3.widget-title {
        margin: 0;
        font-size: 20px;
        padding-bottom: 1.1em;
        color: #0F1216;
        font-weight: 600;
        line-height: 1.2;
    }

    form {
        position: relative;

        input {
            width: 100%;
            padding: 12px 28px;
            background: #eef1f5;
            border: none;
            outline: none;
            border-radius: 6px;
            border: none;
            padding-right: 80px;
        }

        button {
            background: transparent;
            color: $whiteColor;
            display: inline-block;
            padding: 4px 23px;
            border-radius: 0 3px 3px 0;
            font-size: 16px;
            border: none;
            outline: none;
            cursor: pointer;
            margin-top: 0;
            position: absolute;
            right: 0;
            top: 0;
            transition: all .5s ease;

            svg {
                color: #333;
                position: relative;
                top: 6px;

                &:hover {
                    color: $primaryColor;
                }
            }

            &:hover {
                opacity: .9;
            }
        }
    }
}

.events-details {
    .videos {
        padding-bottom: 30px;
    }

    h3 {
        font-size: 26px;
        font-weight: 600;
        color: #0F1216;
    }

    p {
        font-size: 18px;
        line-height: 28px;
        color: #5B5F64;
    }

    ul.mata-tags {
        border-top: 1px solid #E8ECF0;
        padding-top: 30px;
        padding-bottom: 30px;
        margin-top: 40px;

        li {
            display: inline-block;
            margin-right: 5px;

            a {
                padding: 3px 20px;
                background: #F2F4F9;
                font-size: 13px;
                color: #4D5765;
                font-weight: 600;
                display: block;
                border-radius: 4px;

                &:hover {
                    background: #3270FC;
                    color: $whiteColor;
                }
            }

            &:last-child {
                margin: 0;
            }

            &.tags {
                font-size: 16px;
                font-weight: 600;
                color: #000;
                margin-right: 20px;
            }
        }
    }

    .others-instructors {
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
        padding-top: 60px;

        li {
            text-align: center;
            margin-right: 32px;

            span {
                display: block;
                font-size: 20px;
                font-weight: 700;
                color: #0F1216;
                line-height: 25px;

                em {
                    display: block;
                    color: #5B5F64;
                    font-weight: 400;
                    font-style: normal;
                }
            }

            &:first-child {
                flex: 0 0 100%;
                text-align: left;
                margin-bottom: 16px;

                h3 {
                    margin: 0;
                }
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

body.event-page {
    .react-upcoming__event .event__card .event__card--link:hover {
        background: $primaryColor;
        border-color: $primaryColor;
    }

    .react-upcoming__event .event__card .event__card--date em,
    .react-upcoming__event .event__card .event_time {
        color: $primaryColor;
    }
}


/*****************************************************
    27. Student Satisfaction Section CSS Here  
****************************/
.student_satisfaction-section {
    background: url(../images/testimonial/bg.jpg) no-repeat center top;
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 100px;

    .event__card {
        .event__card--content {
            background: #fff;
            margin: 0 10px;
            padding: 40px 44px 42px;
            border-radius: 10px;
            position: relative;

            .testimonial__ratings {
                font-size: 14px;
                color: #797E84;

                em {
                    color: #FFA439;
                }
            }

            .parag {
                color: #5B5F64;
                line-height: 30px;
                margin: 6px 0 0;
                font-size: 18px;
            }
        }
    }

    .poly {
        position: absolute;
        bottom: -11px;
        width: 24px !important;
        z-index: 11111;
        left: 40px;
        transform: rotate(-53deg);
        height: 24px;
        background: #fff;
    }

    .author-sec {
        display: flex;
        align-items: center;
        padding: 30px 28px;

        .icon {
            margin-right: 15px;
        }

        .text {
            flex: 1;

            h4 {
                margin: 0 0 5px;
                font-weight: 700;
                font-size: 18px;
                line-height: 23px;
                color: #0F1216;
            }

            p {
                margin: 0;
                font-size: 14px;
                line-height: 17px;
                color: #5B5F64;
            }
        }

        a {
            border-radius: 50%;
            width: 44px;
            height: 44px;
            border: 2px solid #EDEDED;
            text-align: center;
            line-height: 36px;

            svg {
                width: 18px;
                color: #00306E;
            }

            &:hover {
                background: $primaryColor;
                color: $whiteColor;

                svg {
                    color: $whiteColor;
                }
            }
        }
    }

    .owl-carousel {
        .owl-controls {
            position: unset;
            transform: unset;
            width: auto;
        }

        .owl-nav {
            position: absolute;
            display: block;
            top: 29%;
            right: unset;
            width: 100%;
            opacity: 1;
            transition: $transition;

            [class*="owl-"] {
                position: absolute;
                left: 20px;
                display: inline-block;
                font-size: 0;
                width: 50px;
                height: 50px;
                line-height: 48px;
                color: $titleColor;
                background: #fff;
                border-radius: 100%;
                transition: $transition;
                box-shadow: -6px 20px 30px rgba(15, 18, 22, 0.06);

                &:before {
                    content: "\35";
                    font-family: ElegantIcons;
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    font-size: 26px;
                    font-weight: 700;
                }

                &.owl-next {
                    left: auto;
                    right: -35px;
                }

                &.owl-prev {
                    left: -35px;
                    right: auto;

                    &:before {
                        content: '\34';
                    }
                }

                &:hover {
                    color: $whiteColor;
                    background: #3270FC;
                    border-color: #3270FC;
                }
            }
        }
    }

    .count__area2 {
        padding-top: 90px;

        .container {
            border-top: 1px solid rgba(15, 18, 22, 0.08);
            border-bottom: 1px solid rgba(15, 18, 22, 0.08);
            max-width: 1280px;

            ul {
                li {
                    border-right: 1px solid rgba(15, 18, 22, 0.08);
                    padding-top: 25px;
                    padding-bottom: 25px;
                    padding-left: 70px;

                    &:last-child {
                        border-right: none;
                    }

                    &:first-child {
                        padding-left: 0;
                    }
                }
            }
        }

        .count__content {
            display: flex;
            align-items: center;
            position: relative;

            .icon {
                margin-right: 15px;

                img {
                    position: relative;
                    top: -2px;
                }
            }

            .text {
                flex: 1;

                span {
                    margin: 0 0 5px;
                    font-weight: 600;
                    font-size: 50px;
                    line-height: 60px;
                    color: #0F1216;
                }

                em {
                    margin: 0 0 5px;
                    font-weight: 600;
                    font-size: 50px;
                    line-height: 60px;
                    color: #0F1216;
                    font-style: normal;
                }

                p {
                    margin: 0;
                    font-size: 15px;
                    line-height: 18px;
                    color: #5B5F64;
                    text-transform: uppercase;
                }
            }

            &:last-child {
                border-right: 0;
            }
        }
    }
}


/*****************************************************
    28. High Quality CSS Here 
****************************/

.high_quality-section {
    .react-tab-gird {
        position: relative;

        .shape__1 {
            position: absolute;
            top: 40%;
            left: 4.5%;
            -webkit-animation: hero-leaf-2 3s linear infinite alternate;
            -moz-animation: hero-leaf-2 3s linear infinite alternate;
            -o-animation: hero-leaf-2 3s linear infinite alternate;
            animation: hero-leaf-2 3s linear infinite alternate;
        }

        .shape__2 {
            position: absolute;
            top: 54%;
            left: 7.5%;
            z-index: -1;
        }

        .shape__3 {
            position: absolute;
            top: -15%;
            right: 2%;
            z-index: -1;
            -webkit-animation: hero-leaf-2 3s linear infinite alternate;
            -moz-animation: hero-leaf-2 3s linear infinite alternate;
            -o-animation: hero-leaf-2 3s linear infinite alternate;
            animation: hero-leaf-2 3s linear infinite alternate;
        }

        .nav-tabs {
            display: flex;
            border: none;
            justify-content: center;
            margin: -24px 0;

            .react-tabs__tab {
                margin: 12px 12px;
                button {
                    display: flex;
                    background: $whiteColor;
                    align-items: center;
                    color: #0F1216;
                    padding: 16px 32px 15px 25px;
                    transition: $transition;
                    font-size: 18px;
                    line-height: 1;
                    font-weight: 600;
                    box-shadow: none;
                    cursor: pointer;
                    position: relative;
                    vertical-align: middle;
                    transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
                    box-shadow: 0px 30px 50px rgba(0, 14, 32, 0.1);
                    border-radius: 10px;
                    border: none;

                    em {
                        font-style: normal;

                        &.icon {
                            margin-right: 14px;
                        }
                    }

                    &:hover,
                    &.active {
                        opacity: .9;
                        color: #0F1216;
                    }
                }
                &.react-tabs__tab--selected {
                    button {
                        background: rgba(0,0,0,0.15);
                    }
                }
            }
        }

        .tab-pane {
            border: 6px solid #FFFFFF;
            box-shadow: 0px 30px 50px rgba(15, 18, 22, 0.14);
            border-radius: 10px;
            max-width: 985px;
            margin: 30px auto 40px;
            line-height: 0;
        }
    }
}

/*****************************************************
    29. Footer CSS Here 
****************************/
.react-footer {
    position: relative;
    background-color: #0F1216;
    color: #fff;

    .footer-top-cta {
        background: url(../images/cta.jpg) no-repeat center top;
        background-size: cover;
        border-radius: 10px;
        padding: 50px 55px;
        position: absolute;
        width: 100%;
        top: -17%;
        max-width: 1300px;
        z-index: 1;

        .row {
            align-items: center;
        }

        h4 {
            font-size: 16px;
            color: #fff;
            margin: 0 0 8px;
            line-height: 20px;
            font-weight: 600;
        }

        h3 {
            font-size: 44px;
            color: #fff;
            margin: 0;
            line-height: 50px;
            font-weight: 700;
        }

        a {
            font-size: 16px;
            font-weight: 600;
            border-radius: 6px;
            border: 2px solid rgba(255, 255, 255, 0.2);
            padding: 15px 35px 12px 35px;
            color: #fff;
            display: inline-block;
            margin-top: 0px;
            line-height: 20px;

            svg {
                width: 18px;
            }

            &:hover {
                background: #fff;
                color: #0F1216;
            }
        }
    }

    .footer-top {
        padding: 15px 0 93px;

        .footer-menu {
            
            li {
                margin-bottom: 2px;

                a {
                    color: #B7B7B9;
                    position: relative;
                    display: inline-block;
                    transition: $transition;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 30px;
                    

                    &:after {
                        content: "";
                        display: block;
                        position: relative;
                        z-index: 1;
                        top: auto;
                        bottom: 0px;
                        left: 0;
                        -webkit-transform: none;
                        -ms-transform: none;
                        transform: none;
                        width: 0;
                        height: 1px;
                        -webkit-transition: all .3s ease;
                        -ms-transition: all .3s ease;
                        transition: all .3s ease;
                        background-color: #3270FC;
                    }

                    &:hover {
                        color: #3270FC;
                        

                        margin-left: 5px;

                        &:after {
                            width: 100%;
                        }
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .footer-desc p {
            margin-bottom: 0;
        }

        .footer3__form {
            margin: 40px 0 30px;

            form {
                position: relative;
            }

            p {
                font-size: 16px;
                line-height: 24px;
            }
        }

        .footer3__form input {
            padding: 15px 80px 16px 30px;
            border: none;
            outline: none;
            background: #ffffff;
            border-radius: 4px;
            width: 100%;
        }

        .footer3__form input::placeholder {
            font-size: 15px;
            font-weight: 400;
            color: #4b4b57;
        }

        .footer3__form input:focus {
            -webkit-box-shadow: 0px 20px 30px 0px rgba(8, 0, 42, 0.14);
            -moz-box-shadow: 0px 20px 30px 0px rgba(8, 0, 42, 0.14);
            box-shadow: 0px 20px 30px 0px rgba(8, 0, 42, 0.14);
            z-index: 1;
        }

        .footer3__form-1 {
            position: absolute;
            width: 60px;
            bottom: 0;
            border-radius: 0 4px 4px 0;
            background-color: #3270FC;
            color: #ffffff;
            top: 0px;
            right: 0px;
            border: none;

            &:hover {
                opacity: .9;
            }
        }

        .footer3__form-1 i {
            position: absolute;
            font-size: 22px;
            top: 19px;
            left: 17px;
        }

        .footer-subtitle {
            color: #fff;
            margin-top: 25px;
            line-height: 24px;
        }

        .footer-widget-1 {
            margin-top: -10px;
        }

        .footer-widget-3 {
            margin-left: 50px;
            padding-top: 1rem;
        }

        .footer-widget {
            &.footer-widget-2 {
                padding-left: 100px;
                padding-top: 1rem;
            }

            .footer-title {
                margin-bottom: 30px;
                position: relative;
                color: #fff;
                font-size: 22px;
                font-weight: 700;
                line-height: 24px;
            }

            .footer-address {
                margin-bottom: 30px;

                li {
                    width: 100%;
                    font-size: 15px;
                    margin-bottom: 10px;
                    display: inline-flex;
                    color: #B7B7B9;
                    font-weight: 600;

                    a {
                        color: #B7B7B9;
                        margin-left: 10px;
                        line-height: 24px;
                        font-size: 18px;

                        &:hover {
                            color: #3270FC;
                        }
                    }

                    svg {
                        position: relative;
                        top: 0;
                        color: #fff;
                        width: 21px;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }

                    &.react-address {
                        svg {
                            margin-right: 10px;
                            width: 22px;
                            position: relative;
                            top: 1px;
                        }
                    }
                }
            }
        }
    }

    // &.react-footer-two {
    //     .footer-top .footer3__form-1 {
    //         background: #3270FC;

    //         &:hover {
    //             opacity: .9;
    //         }
    //     }

    //     .footer-top .footer-menu li a:hover,
    //     .footer-top .footer-widget .footer-address li a:hover,
    //     .copyright .react-copy-left a {
    //         color: #3270FC;
    //     }

    //     .footer-top .footer-menu li a::after {
    //         background: #3270FC;
    //     }

    //     .social-links li a:hover {
    //         background: #3270FC;
    //         border-color: #3270FC;
    //     }
    // }

    .copyright {
        text-align: center;

        .container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-top: 1px solid rgba(255, 255, 255, 0.1);
            padding-top: 20px;
            padding-bottom: 20px;
        }

        .follow {
            font-size: 16px;
            line-height: 20px;
            color: #B7B7B9;
            font-weight: 700;
            margin-right: 20px;
        }

        .react-copy-left {
            font-size: 14px;
            margin-bottom: 0;
            color: #A6A6A8;
            font-weight: 600;
            line-height: 22px;

            a {
                color: #3270FC;
                transition: all .7s ease;

                &:hover {
                    color: $primaryColor;
                }
            }
        }

        .react-copy-right {
            ul {
                margin: 0;
                padding: 0;
                display: flex;
                align-items: center;

                li {
                    padding-left: 6px;

                    a {
                        color: #fff;
                        font-size: 13px;
                        font-weight: 500;

                        &:hover {
                            color: #fff;
                        }
                    }
                }
            }
        }
    }

    &.home-main {

        .footer-top .footer-menu li a:hover,
        .footer-top .footer-widget .footer-address li a:hover,
        .copyright .react-copy-left a {
            color: #D2093C
        }

        .footer-top .footer-menu li a:after {
            background: #D2093C
        }

        .social-links li a:hover {
            background: #d2093c;
            border-color: #d2093c;
        }
    }
}

/*****************************************************
    30. 404 CSS Here 
****************************/
.page-error {
  position: relative;
  display: table;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background: #ffffff;
}
.page-error .error-image {
  margin-bottom: 20px;
}
.page-error .container {
  position: fixed;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}
.page-error .page-content {
  max-width: 550px;
  margin: 0 auto;
}
.page-error .content-area {
  position: relative;
  z-index: 101;
  margin: 0 auto;
  text-align: center;
}
.page-error .content-area h1.page-title {
  color: #ffffff;
  font-size: 100px;
  margin-bottom: 30px;
  margin-top: 0px;
}
.page-error .content-area h2 {
  color: #4d5765;
  font-size: 20px;
  font-weight: 400 !important;
  margin-bottom: 30px;
  line-height: 30px;
}
.page-error .content-area h2 span {
  display: block;
  font-size: 48px;
  line-height: 60px;
  margin-bottom: 20px;
  color: #00306E;
  font-weight: 600;
}
.page-error .react-sideabr {
  max-width: 400px;
  margin: 0 auto;
  padding-bottom: 20px;
}
.page-error .react-sideabr {
  padding: 10px 0 35px;
}
.page-error .flaticon-right-arrow:before {
  padding-left: 5px;
}
.page-error .reacbutton {
  display: inline-flex;
  font-size: 20px;
  padding: 10px 35px 16px;
  border-radius: 4px !important;
  border: 1px solid #3270fc;
  color: #fff;
  background: #3270fc;
}
.page-error .reacbutton svg {
  width: 18px;
  position: relative;
  top: 8px;
  margin-left: 8px;
}
.page-error .reacbutton:focus {
  color: #ffffff;
}
.page-error .reacbutton:hover {
  background: transparent;
  border: 1px solid #3270fc;
  color: #0f1216 !important;
}
.page-error.coming-soon {
  height: 100vh;
}
.page-error.coming-soon .content-area h3 {
  font-size: 30px;
  margin-top: 40px;
  margin-bottom: 60px;
  display: block;
}
.page-error.coming-soon .content-area h3 span {
  font-size: 80px;
  text-shadow: unset;
  text-transform: uppercase;
  line-height: 90px;
  padding-bottom: 20px;
  letter-spacing: 5px;
  display: block;
  font-weight: 900 !important;
}
.page-error.coming-soon .countdown-inner {
  width: 600px;
  margin: 0 auto;
  max-width: 100%;
}
.page-error.coming-soon .countdown-inner .time_circles {
  position: relative;
  justify-content: center;
  align-items: center;
}
.page-error.coming-soon .countdown-inner .time_circles canvas {
  opacity: 0;
  display: none;
}
.page-error.coming-soon .countdown-inner .time_circles div {
  color: #ffffff;
  font-size: 36px;
  font-weight: 600;
  padding-top: 25px;
  width: 120px !important;
  height: 120px !important;
  display: grid;
  align-items: center;
  margin: 0 auto;
  border-radius: 100%;
  border: 5px solid #ffffff;
  background: #d2093c;
  box-shadow: 0 0 52px rgba(0, 0, 0, 0.3);
  display: inline-block;
  margin: 0 12px;
}
.page-error.coming-soon .countdown-inner .time_circles div:first-child {
  padding-left: 0;
}
.page-error.coming-soon .countdown-inner .time_circles div:last-child {
  margin-right: 0;
}
.page-error.coming-soon .countdown-inner .time_circles div span,
.page-error.coming-soon .countdown-inner .time_circles div h4 {
  color: #ffffff;
}
.page-error.coming-soon .countdown-inner .time_circles div span {
  line-height: 1;
  font-size: 55px;
  display: block;
}
.page-error.coming-soon .countdown-inner .time_circles div h4 {
  margin: 0;
  font-weight: 400 !important;
  text-transform: uppercase;
}
.page-error.coming-soon .follow-us-sbuscribe p {
  color: #ffffff;
  font-size: 30px;
  padding-top: 50px;
  font-weight: 500;
  margin-bottom: 5px;
}
.page-error.coming-soon .follow-us-sbuscribe ul {
  list-style: none;
  margin: 0;
}
.page-error.coming-soon .follow-us-sbuscribe ul li {
  display: inline-block;
  margin-right: 20px;
}
.page-error.coming-soon .follow-us-sbuscribe ul li:last-child {
  margin-right: 0;
}
.page-error.coming-soon .follow-us-sbuscribe ul li a {
  width: 45px;
  height: 45px;
  line-height: 35px;
  border-radius: 50%;
  padding: 0;
}
.page-error.coming-soon .follow-us-sbuscribe ul li i {
  line-height: 45px;
  font-size: 22px;
}

.react-footer.home-four .footer-top .footer3__form-1 {
    background-color: $primaryColor;
}

.react-footer-two {
    .footer-top .footer3__form-1 {
        background: $primaryColor;

        &:hover {
            opacity: .9;
        }
    }

    .footer-top .footer-menu li a:hover,
    .footer-top .footer-widget .footer-address li a:hover,
    .copyright .react-copy-left a {
        color: #3270FC;
    }

    .footer-top .footer-menu li a::after {
        background: #3270FC;
    }

    .social-links li a:hover {
        background: $primaryColor;
        border-color: $primaryColor;
    }
}

.social-links {
    li {
        display: inline-block;
        margin-right: 6px;

        a {
            border: 2px solid rgba(255, 255, 255, 0.10);
            color: $whiteColor;
            background: transparent;
            display: block;
            width: 44px;
            height: 44px;
            line-height: 43px;
            text-align: center;
            transition: all 0.7s ease 0s;
            font-size: 13px;
            border-radius: 50%;

            &:hover {
                color: $whiteColor;
                background: #3270FC;
                border-color: #3270FC;
            }
        }

        &:last-child {
            margin-right: 0;
        }
    }
}



/*****************************************************
    31. Preloader CSS Here 
****************************/
#react__preloader {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    display: -webkit- flex;
    display: -ms- flex;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100vh;
    background: $whiteColor;
    position: fixed;
    z-index: 999999;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.react__loader_logo {
    position: absolute;

    img {
        width: 40px;
    }
}

#react__circle_loader {
    width: 100px;
    height: 100px;
    border-style: solid;
    border-width: 5px;
    border-top-color: #465fe9;
    border-right-color: #465fe9;
    border-bottom-color: #d5eaff;
    border-left-color: #d5eaff;
    border-radius: 50%;
    -webkit-animation: spinLoader 2s linear infinite;
    animation: spinLoader 2s linear infinite;
}

@-webkit-keyframes spinLoader {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spinLoader {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/*****************************************************
    32. ScrollUp CSS Here 
****************************/
#backscrollUp {
    position: fixed;
    bottom: 40px;
    right: 15px;
    transition: all .5s ease;
    z-index: 99999;
    cursor: pointer;
    background: #3270FC;
    border-radius: 3px;

    &.home {
        background: #d2093c;
    }

    span {
        display: block;
        width: 40px;
        height: 40px;
        line-height: 40px;
        border-radius: 3px;
        transition: all .5s ease;
        font-size: 22px;
        color: $whiteColor;
        text-align: center;
        display: none;
        transition: all .5s ease;

        &:before {
            transition: all .5s ease;
            display: block;
            margin: 0;
            font-size: 22px;
        }

        &:hover {
            opacity: .9;
        }
    }

    &.react__up___scroll {
        span {
            display: block;
        }
    }
}

/*****************************************************
    33. Login CSS Here 
****************************/
.react-login-page {
    background: url(../images/login/1.jpg) no-repeat center top;
    background-size: cover;

    &.react-signup-page {
        background: url(../images/login/1.jpg) no-repeat center top;
        background-size: cover;
    }

    .login-left-content {
        h1 {
            font-size: 50px;
            font-weight: 700;
            color: #0F1216;
            margin: 0 0 18px;
        }

        p {
            margin: 0 0 50px;
            font-size: 17px;
            color: #55545b;

            a {
                font-weight: 600;
                color: $primaryColor;

                &:hover {
                    color: #55545b;
                }
            }
        }
    }

    .login-right-form {
        background: $whiteColor;
        padding: 50px;
        max-width: 470px;
        margin: 0 auto;
        border-radius: 10px;
        box-shadow: 0px 40px 60px rgba(15, 18, 22, 0.08);

        form {
            .login-top {
                text-align: center;
                padding-bottom: 35px;

                h3 {
                    margin: 0 0 8px;
                    color: #0F1216;
                    font-size: 26px;
                    font-weight: 700;
                }

                p {
                    margin: 0;
                    font-size: 16px;
                    color: #4D5765;
                }
            }

            .social-links {
                text-align: center;

                li {
                    a {
                        border-radius: 50%;
                        background: #3270FC;
                        color: $whiteColor;
                        width: 44px;
                        height: 44px;
                        line-height: 44px;
                        border: none;

                        &:hover {
                            opacity: .9;
                        }
                    }

                    &:last-child {
                        a {
                            border: 2px solid #E8ECF0;
                            font-size: 16px;
                            background: none;
                            color: #9EA9B6;
                            position: relative;
                            top: 3px;

                            &:hover {
                                background: #3270FC;
                                border-color: #3270FC;
                                color: #fff;
                            }
                        }
                    }
                }
            }

            span.back-or {
                display: block;
                font-size: 14px;
                color: #7a797f;
                text-align: center;
                font-weight: 500;
                margin: 20px 0 16px;
            }

            p {
                label {
                    display: block;
                    color: #0F1216;
                    font-size: 14px;
                    font-weight: 600;
                    margin: 0 0 8px;
                }
            }

            input {
                border: none;
                background: #F2F4F9;
                width: 100%;
                padding: 12px 20px;
                border-radius: 6px;
            }

            [type="submit"] {
                background: #3270FC;
                color: $whiteColor;
                font-size: 16px;
                font-weight: 600;
                border-radius: 5px;
                display: inline-block;
                padding: 12px 38px;
                transition: all 0.5s ease 0s;
                border: none;
                width: 100%;

                &:hover {
                    opacity: .9;
                }

                svg {
                    width: 18px;
                }
            }

            .signup {
                font-weight: 500;
                font-size: 14px;
                color: #7a797f;
                font-style: normal;
                text-align: center;
                display: block;
                padding-top: 20px;

                a {
                    color: $primaryColor;

                    &:hover {
                        color: #7a797f;
                    }
                }
            }

            .back-check-box {
                display: flex;
                justify-content: space-between;
                font-size: 14px;
                color: #7a797f;
                margin-top: -16px;
                margin-bottom: 35px;

                input {
                    width: auto;
                    margin-right: 5px;
                }

                p {
                    margin: 0;
                    flex: 1;
                    text-align: right;
                }

                em {
                    font-weight: 600;
                    font-style: normal;
                    color: $titleColor;
                }
            }
        }
    }

    .container {
        max-width: 1050px;
    }

    .row.align-center {
        align-items: center;
    }
}

.mfp-wrap .mfp-container .mfp-close {
    cursor: pointer;
    background: $primaryColor;
    border: 0;
    outline: 0;
    padding: 0;
    display: block;
    z-index: 1046;
    box-shadow: none;
    text-align: center;
    width: 44px;
    height: 44px;
    border-radius: 50px;
    position: absolute;
    top: -60px;
    right: 0;
    color: #fff;
    opacity: 1;
}

#videostory {
    max-width: 75%;
    margin: 0 auto;
}

/*Modal Video Section CSS*/
.modal-video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.5);
    z-index: 1000000;
    cursor: pointer;
    opacity: 1;
    transition: opacity .3s ease-out;
    .modal-video-body {
        max-width: 960px;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        padding: 0 10px;
        display: table;
        box-sizing: border-box;
        .modal-video-inner {
            display: table-cell;
            vertical-align: middle;
            width: 100%;
            height: 100%;
            .modal-video-movie-wrap {
                width: 100%;
                height: 0;
                position: relative;
                padding-bottom: 56.25%;
                background-color: #333;
                animation-timing-function: ease-out;
                animation-duration: .3s;
                iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
                .modal-video-close-btn {
                    position: absolute;
                    z-index: 2;
                    top: -45px;
                    right: 0;
                    display: inline-block;
                    width: 35px;
                    height: 35px;
                    overflow: hidden;
                    border: none;
                    background: transparent;
                    cursor: pointer;
                    &:before,
                    &:after {
                        content: "";
                        position: absolute;
                        height: 2px;
                        width: 100%;
                        top: 50%;
                        left: 0;
                        background: #fff;
                        border-radius: 5px;
                        margin-top: -6px;
                    }
                    &:before {
                        transform: rotate(45deg);
                    }
                    &:after {
                        transform: rotate(-45deg);
                    }
                }
            } 
        }
    }
}
.stars {
    display: inline-block; 
  }
  
  .icon_star {
    font-size: 24px;  
       color: lightgray;  
    margin-right: 4px;
  
    &.filled {
      color: #FE9415; 
    }
    
    &.empty {
      color: lightgray; 
    }
      
  }



